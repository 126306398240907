<template>
    <footer v-if="loaded && !hideFooter">
        <ul>
            <li v-if="isCustomDomain">
                <small>Powered by <a href="https://pirsch.io" target="_blank">Pirsch Analytics</a></small>.
            </li>
            <li v-if="!hideCopyright">
                <small>
                    © {{year}}
                    <template v-if="copyright">{{copyright}}</template>
                    <a href="https://emvi.com" target="_blank" v-else>Emvi Software GmbH</a>.
                    All rights reserved.
                </small>
            </li>
        </ul>
        <ul>
            <li>
                <a :href="termsURL" target="_blank" rel="noreferrer">{{t("link.terms")}}</a>
            </li>
            <li>
                <a :href="privacyURL" target="_blank" rel="noreferrer">{{t("link.privacy")}}</a>
            </li>
            <li>
                <a :href="legalURL" target="_blank" rel="noreferrer">{{t("link.legal")}}</a>
            </li>
        </ul>
    </footer>
</template>

<script lang="ts">
    import {computed, defineComponent} from "vue";
    import dayjs from "dayjs";
    import {isCustomDomain} from "@/util/domain";
    import {storeToRefs} from "pinia";
    import {ThemeSettings, useThemeStore} from "@/store/ThemeStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        setup() {
            const {theme, loaded} = storeToRefs(useThemeStore());
            const hideFooter = computed(() => {
                const themeSettings = theme.value ?? {};
                return themeSettings[ThemeSettings.hideFooter] === "true";
            });
            const hideCopyright = computed(() => {
                const themeSettings = theme.value ?? {};
                return themeSettings[ThemeSettings.hideCopyright] === "true";
            });

            return {
                ...useI18n(),
                copyright: import.meta.env.VITE_APP_COPYRIGHT ?? "Emvi Software GmbH",
                termsURL: import.meta.env.VITE_APP_TERMS,
                privacyURL: import.meta.env.VITE_APP_PRIVACY,
                legalURL: import.meta.env.VITE_APP_LEGAL,
                isCustomDomain: isCustomDomain(),
                year: dayjs().year(),
                loaded,
                hideFooter,
                hideCopyright
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "link.terms": "Terms and Conditions",
            "link.privacy": "Privacy Policy",
            "link.legal": "Legal Notice"
        },
        "de": {
            "link.terms": "Nutzungsbedingungen",
            "link.privacy": "Datenschutzerklärung",
            "link.legal": "Impressum"
        },
        "es": {
            "link.terms": "Términos y Condiciones",
            "link.privacy": "Política de Privacidad",
            "link.legal": "Aviso Legal"
        },
        "fr": {
            "link.terms": "Conditions Générales",
            "link.privacy": "Politique de Confidentialité",
            "link.legal": "Mentions Légales"
        },
        "nl": {
            "link.terms": "Algemene voorwaarden",
            "link.privacy": "Privacybeleid",
            "link.legal": "Juridische kennisgeving"
        },
        "it": {
            "link.terms": "Termini e Condizioni",
            "link.privacy": "Informativa sulla Privacy",
            "link.legal": "Nota Legale"
        },
        "pt": {
            "link.terms": "Termos e Condições",
            "link.privacy": "Política de Privacidade",
            "link.legal": "Aviso Legal"
        },
        "ja": {
            "link.terms": "利用規約",
            "link.privacy": "プライバシーポリシー",
            "link.legal": "法的通知"
        }
    }
</i18n>
