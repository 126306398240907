<template>
    <ul class="dropdown" ref="scrollArea">
        <Loading :loading="loading" type="loader" />
        <template v-if="options.length">
            <li v-for="(option, index) in options"
                :key="option"
                :class="{selected: active === index}"
                v-on:click="select(option)">
                {{option.label}}
            </li>
        </template>
        <li class="disabled" v-else-if="!loading">
            {{t("no_data")}}
        </li>
    </ul>
</template>

<script lang="ts">
    import {computed, defineComponent, nextTick, onMounted, onUnmounted, ref, watch} from "vue";
    import {StatisticsRepo} from "@/repositories/StatisticsRepo";
    import {APIError} from "@/repositories/APIError";
    import {whereAlpha2} from "iso-3166-1";
    import ISO6391 from "iso-639-1";
    import Loading from "@/components/bits/Loading.vue";
    import {updateCache} from "@/components/panels/cache";
    import {scrollIntoView} from "@/util/scroll";
    import {useToastStore} from "@/store/ToastStore";
    import {storeToRefs} from "pinia";
    import {useFilterStore} from "@/store/FilterStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Loading
        },
        props: {
            field: {type: String, required: true},
            value: String,
            active: {type: Number, default: 0},
            focus: {type: Boolean, required: true}
        },
        emits: ["active", "select"],
        setup(props, {emit}) {
            const {filter} = storeToRefs(useFilterStore());
            const {error} = useToastStore();
            const loading = ref(true);
            const options = ref<{ value: string, label: string }[]>([]);
            const filteredOptions = computed(() => {
                const query = (props.value || "").toLowerCase();
                const o = [];

                for (let i = 0; i < options.value.length; i++) {
                    if (options.value[i].label.toLowerCase().includes(query)) {
                        o.push(options.value[i]);
                    }
                }

                return o;
            });
            let loaded = false;
            const scrollArea = ref(document.createElement("ul"));

            onMounted(async () => {
                document.addEventListener("keydown", keyboardEvents);
            });

            onUnmounted(() => {
                document.removeEventListener("keydown", keyboardEvents);
            });

            watch(() => props.active, active => {
                const n = filteredOptions.value.length;

                if (active < 0) {
                    emit("active", n-1);
                } else if (active > n-1) {
                    emit("active", 0);
                }

                nextTick(() => {
                    const entry = scrollArea.value.querySelector("[class~='selected']");

                    if (entry) {
                        scrollIntoView(scrollArea.value, entry, 6);
                    }
                });
            });

            watch(filteredOptions, () => {
                emit("active", 0);
            });

            watch(filter, (f, old) => {
                if (updateCache(old, f)) {
                    if (props.focus) {
                        loadOptions();
                    } else {
                        loaded = false;
                    }
                }
            })

            watch(() => props.focus, focus => {
                if (focus && !loaded) {
                    loadOptions();
                }
            });

            async function loadOptions() {
                try {
                    loaded = true;
                    const field = props.field === "path" || props.field === "entry_path" || props.field === "exit_path" ? "path" : props.field;
                    const cached = localStorage.getItem(`${field}_cache`);

                    if (cached) {
                        setOptions(JSON.parse(cached));
                        return;
                    }

                    switch (field) {
                        case "path":
                        case "entry_path":
                        case "exit_path":
                            setOptions(await StatisticsRepo.pageOptions(filter.value) as string[], "path");
                            break;
                        case "referrer":
                            setOptions(await StatisticsRepo.referrerOptions(filter.value) as string[]);
                            break;
                        case "referrer_name":
                            setOptions(await StatisticsRepo.referrerNameOptions(filter.value) as string[]);
                            break;
                        case "utm_source":
                            setOptions(await StatisticsRepo.utmSourceOptions(filter.value) as string[]);
                            break;
                        case "utm_medium":
                            setOptions(await StatisticsRepo.utmMediumOptions(filter.value) as string[]);
                            break;
                        case "utm_campaign":
                            setOptions(await StatisticsRepo.utmCampaignOptions(filter.value) as string[]);
                            break;
                        case "utm_content":
                            setOptions(await StatisticsRepo.utmContentOptions(filter.value) as string[]);
                            break;
                        case "utm_term":
                            setOptions(await StatisticsRepo.utmTermOptions(filter.value) as string[]);
                            break;
                        case "event":
                            setOptions(await StatisticsRepo.eventOptions(filter.value) as string[]);
                            break;
                        case "country":
                            setOptions(await StatisticsRepo.countryOptions(filter.value) as string[]);
                            break;
                        case "region":
                            setOptions(await StatisticsRepo.regionOptions(filter.value) as string[]);
                            break;
                        case "city":
                            setOptions(await StatisticsRepo.cityOptions(filter.value) as string[]);
                            break;
                        case "language":
                            setOptions(await StatisticsRepo.languageOptions(filter.value) as string[]);
                            break;
                        case "os":
                            setOptions(["Android", "iOS", "Linux", "Mac", "Windows"]);
                            break;
                        case "browser":
                            setOptions(["Chrome", "Edge", "Firefox", "IE", "Opera", "Safari"]);
                            break;
                        case "platform":
                            setOptions(["Mobile", "Desktop"]);
                            break;
                        case "screen_class":
                            setOptions(["UHD 5K", "UHD 4K", "WQHD", "Full HD", "HD", "XL", "L", "M", "S"]);
                            break;
                        case "tag":
                            setOptions(await StatisticsRepo.tagOptions(filter.value) as string[]);
                            break;
                        default:
                            if (field.startsWith("meta_")) {
                                setOptions(await StatisticsRepo.metadataOptions(filter.value) as string[]);
                            } else if (field.startsWith("tag_")) {
                                setOptions(await StatisticsRepo.tagValueOptions(filter.value) as string[]);
                            }
                    }
                } catch (e) {
                    error(e as APIError);
                }
            }

            function setOptions(values: string[], cache?: string) {
                const o = [];

                for (let i = 0; i < values.length; i++) {
                    let label = values[i];

                    if (props.field === "country") {
                        label = label.replace(/\0/g, ""); // remove null bytes for "empty" strings
                    }

                    if (label !== "") {
                        if (props.field === "country") {
                            const country = whereAlpha2(label.toUpperCase());

                            if (country) {
                                label = country.country;
                            }
                        } else if (props.field === "language") {
                            label = ISO6391.getName(label) || label;
                        }

                        o.push({
                            value: values[i],
                            label
                        });
                    }
                }

                options.value = o;
                localStorage.setItem(`${cache || props.field}_cache`, JSON.stringify(values));
                loading.value = false;
            }

            function keyboardEvents(e: KeyboardEvent) {
                if (e.key === "Enter" && filteredOptions.value.length) {
                    select(filteredOptions.value[props.active]);
                }
            }

            function select(option: { value: string, label: string }) {
                emit("select", option.label, option.value !== option.label ? option.value : undefined);
            }

            return {
                ...useI18n(),
                options: filteredOptions,
                loading,
                scrollArea,
                select
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "no_data": "No Data"
        },
        "de": {
            "no_data": "Keine Daten"
        },
        "es": {
            "no_data": "Sin datos"
        },
        "fr": {
            "no_data": "Pas de données"
        },
        "nl": {
            "no_data": "Geen gegevens"
        },
        "it": {
            "no_data": "Nessun dato"
        },
        "pt": {
            "no_data": "Sem dados"
        },
        "ja": {
            "no_data": "データなし"
        }
    }
</i18n>
