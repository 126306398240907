<template>
    <section class="add">
        <h1>{{t("title")}}</h1>
        <i18n-t keypath="text" tag="p" style="margin-bottom: 12px;">
            <a href="https://docs.pirsch.io" target="_blank">{{t("link.docs")}}</a>
            <router-link to="/settings/integration">{{t("link.settings")}}</router-link>
        </i18n-t>
        <HiddenArea :title="t('website.title')">
            <template v-slot:description>{{t("website.text_0")}}</template>
            <template v-slot:default>
                <p>{{t("website.text_1")}}</p>
                <i18n-t keypath="website.text_2" tag="p">
                    <strong>&lt;head&gt;</strong>
                </i18n-t>
                <pre style="max-width: calc(100vw - 32px);">
                    <code v-html="snippetString" />
                </pre>
                <div class="button-row">
                    <button v-on:click="copySnippet(snippetString)">{{t("copy_to_clipboard")}}</button>
                    <button class="secondary" v-on:click="$router.push('/settings/integration')">{{t("customize_script")}}</button>
                </div>
            </template>
        </HiddenArea>
        <HiddenArea :title="t('backend.title')">
            <template v-slot:description>{{t("backend.text_0")}}</template>
            <template v-slot:default>
                <i18n-t keypath="backend.text_1" tag="p">
                    <a href="https://docs.pirsch.io/api-sdks/api" target="_blank">{{t("backend.text_1.link_0")}}</a>
                    <router-link to="/settings/integration">{{t("backend.text_1.link_1")}}</router-link>
                </i18n-t>
                <i18n-t keypath="backend.text_2" tag="p">
                    <a href="https://github.com/pirsch-analytics/demo" target="_blank">{{t("backend.text_2.link")}}</a>
                </i18n-t>
                <pre style="max-width: calc(100vw - 32px);">
                    <code v-html="apiString" />
                </pre>
                <div class="button-row">
                    <button v-on:click="copySnippet(apiString)">{{t("copy_to_clipboard")}}</button>
                    <a href="https://docs.pirsch.io/get-started/backend-integration" class="button secondary">{{t("read_docs")}}</a>
                </div>
            </template>
        </HiddenArea>
        <button v-on:click="$router.push('/')" style="margin-top: 12px;">{{t("view")}}</button>
    </section>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import {useSnippet} from "@/components/settings/snippet";
    import HiddenArea from "@/components/bits/HiddenArea.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {HiddenArea},
        props: {
            domain: {type: Object, required: true}
        },
        setup() {
            const {getSnippetString, copySnippet} = useSnippet();
            const snippetString = computed(() => getSnippetString());
            const apiString = ref(`fetch("https://api.pirsch.io/api/v1/hit", {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer pa_4lSpFA7aO1HMUkc..."
    },
    body: JSON.stringify({
        "url": "https://example.com",
        "ip": "123.45.67.890",
        "accept_language": "en-US",
        "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64..."
    })
});`);

            return {
                ...useI18n(),
                snippetString,
                apiString,
                copySnippet
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Choose Your Integration",
            "text": "Your dashboard has been created. Choose an integration below or checkout the {0} to find one that suites your needs. The script can be accessed via {1} at any point.",
            "link.docs": "documentation",
            "link.settings": "Settings > Integration",
            "view": "View Dashboard",
            "customize_script": "Customize Script",
            "read_docs": "Read the Documentation",
            "copy_to_clipboard": "Copy to Clipboard",
            "website.title": "Website Integration",
            "website.text_0": "Track page views, outbound links, file downloads, custom events, and more from your website.",
            "website.text_1": "Track page views from your website. Includes automatic outbound link tracking, file download tracking, and events using JavaScript, CSS classes, and HTML attributes.",
            "website.text_2": "Copy the following code snippet into the {0} section of your website.",
            "backend.title": "Server-side API",
            "backend.text_0": "Track page views and events from your server without the need for JavaScript on your website.",
            "backend.text_1": "The server-side integration utilizes the {0}. {1} on the integration settings page to start using it.",
            "backend.text_1.link_0": "API",
            "backend.text_1.link_1": "Create a Client",
            "backend.text_2": "Here is a simple example in JavaScript create a page view. More examples can be found on {0}.",
            "backend.text_2.link": "GitHub"
        },
        "de": {
            "title": "Wähle deine Integration",
            "text": "Dein Dashboard wurde erstellt. Wähle unten eine Integration aus oder sieh dir die {0} an, um eine passende zu finden. Das Skript kann jederzeit über {1} aufgerufen werden.",
            "link.docs": "Dokumentation",
            "link.settings": "Einstellungen > Integration",
            "view": "Dashboard anzeigen",
            "customize_script": "Skript anpassen",
            "read_docs": "Dokumentation lesen",
            "copy_to_clipboard": "In die Zwischenablage kopieren",
            "website.title": "Webseiten-Integration",
            "website.text_0": "Verfolge Seitenaufrufe, ausgehende Links, Dateidownloads, benutzerdefinierte Events und mehr von deiner Webseite.",
            "website.text_1": "Verfolge Seitenaufrufe von deiner Webseite. Beinhaltet automatisches Tracking von ausgehenden Links, Dateidownloads und Events mit JavaScript, CSS-Klassen und HTML-Attributen.",
            "website.text_2": "Kopiere den folgenden Codeausschnitt in den {0}-Bereich deiner Webseite.",
            "backend.title": "Serverseitige API",
            "backend.text_0": "Verfolge Seitenaufrufe und Events von deinem Server, ohne JavaScript auf deiner Webseite zu benötigen.",
            "backend.text_1": "Die serverseitige Integration nutzt die {0}. {1} auf der Integrationsseite, um sie zu verwenden.",
            "backend.text_1.link_0": "API",
            "backend.text_1.link_1": "Erstelle einen Client",
            "backend.text_2": "Hier ist ein einfaches Beispiel in JavaScript, um eine Seitenansicht zu erstellen. Weitere Beispiele findest du auf {0}.",
            "backend.text_2.link": "GitHub"
        },
        "es": {
            "title": "Elige tu integración",
            "text": "Tu panel de control ha sido creado. Elige una integración a continuación o consulta la {0} para encontrar una que se adapte a tus necesidades. El script se puede acceder en cualquier momento a través de {1}.",
            "link.docs": "documentación",
            "link.settings": "Configuración > Integración",
            "view": "Ver panel de control",
            "customize_script": "Personalizar script",
            "read_docs": "Leer la documentación",
            "copy_to_clipboard": "Copiar al portapapeles",
            "website.title": "Integración de sitios web",
            "website.text_0": "Rastrea vistas de página, enlaces salientes, descargas de archivos, eventos personalizados y más desde tu sitio web.",
            "website.text_1": "Rastrea vistas de página desde tu sitio web. Incluye rastreo automático de enlaces salientes, rastreo de descargas de archivos y eventos usando JavaScript, clases CSS y atributos HTML.",
            "website.text_2": "Copia el siguiente fragmento de código en la sección {0} de tu sitio web.",
            "backend.title": "API del lado del servidor",
            "backend.text_0": "Rastrea vistas de página y eventos desde tu servidor sin necesidad de JavaScript en tu sitio web.",
            "backend.text_1": "La integración del lado del servidor utiliza la {0}. {1} en la página de configuración de integración para comenzar a usarla.",
            "backend.text_1.link_0": "API",
            "backend.text_1.link_1": "Crear un cliente",
            "backend.text_2": "Aquí hay un ejemplo simple en JavaScript para crear una vista de página. Se pueden encontrar más ejemplos en {0}.",
            "backend.text_2.link": "GitHub"
        },
        "fr": {
            "title": "Choisissez votre intégration",
            "text": "Votre tableau de bord a été créé. Choisissez une intégration ci-dessous ou consultez la {0} pour en trouver une qui convient à vos besoins. Le script est accessible à tout moment via {1}.",
            "link.docs": "documentation",
            "link.settings": "Paramètres > Intégration",
            "view": "Voir le tableau de bord",
            "customize_script": "Personnaliser le script",
            "read_docs": "Lire la documentation",
            "copy_to_clipboard": "Copier dans le presse-papiers",
            "website.title": "Intégration de site web",
            "website.text_0": "Suivez les vues de page, les liens sortants, les téléchargements de fichiers, les événements personnalisés et plus encore depuis votre site web.",
            "website.text_1": "Suivez les vues de page de votre site web. Inclut le suivi automatique des liens sortants, le suivi des téléchargements de fichiers et des événements en utilisant JavaScript, des classes CSS et des attributs HTML.",
            "website.text_2": "Copiez le code suivant dans la section {0} de votre site web.",
            "backend.title": "API côté serveur",
            "backend.text_0": "Suivez les vues de page et les événements depuis votre serveur sans avoir besoin de JavaScript sur votre site web.",
            "backend.text_1": "L'intégration côté serveur utilise la {0}. {1} sur la page des paramètres d'intégration pour commencer à l'utiliser.",
            "backend.text_1.link_0": "API",
            "backend.text_1.link_1": "Créer un client",
            "backend.text_2": "Voici un exemple simple en JavaScript pour créer une vue de page. Plus d'exemples peuvent être trouvés sur {0}.",
            "backend.text_2.link": "GitHub"
        },
        "nl": {
            "title": "Kies je integratie",
            "text": "Je dashboard is aangemaakt. Kies hieronder een integratie of bekijk de {0} om er een te vinden die aan je behoeften voldoet. Het script kan op elk moment worden geopend via {1}.",
            "link.docs": "documentatie",
            "link.settings": "Instellingen > Integratie",
            "view": "Dashboard bekijken",
            "customize_script": "Script aanpassen",
            "read_docs": "Lees de documentatie",
            "copy_to_clipboard": "Kopiëren naar klembord",
            "website.title": "Website-integratie",
            "website.text_0": "Volg paginabezoeken, uitgaande links, bestandsdownloads, aangepaste evenementen en meer vanaf je website.",
            "website.text_1": "Volg paginabezoeken vanaf je website. Bevat automatische tracking van uitgaande links, bestandsdownloads en evenementen met behulp van JavaScript, CSS-classes en HTML-attributen.",
            "website.text_2": "Kopieer de volgende code in het {0}-gedeelte van je website.",
            "backend.title": "Server-side API",
            "backend.text_0": "Volg paginabezoeken en evenementen vanaf je server zonder dat je JavaScript op je website nodig hebt.",
            "backend.text_1": "De server-side integratie maakt gebruik van de {0}. {1} op de integratie-instellingen pagina om het te gaan gebruiken.",
            "backend.text_1.link_0": "API",
            "backend.text_1.link_1": "Maak een client",
            "backend.text_2": "Hier is een eenvoudig voorbeeld in JavaScript om een paginabezoek te creëren. Meer voorbeelden zijn te vinden op {0}.",
            "backend.text_2.link": "GitHub"
        },
        "it": {
            "title": "Scegli la tua integrazione",
            "text": "La tua dashboard è stata creata. Scegli un'integrazione qui sotto o consulta la {0} per trovarne una adatta alle tue esigenze. Lo script può essere accessibile in qualsiasi momento tramite {1}.",
            "link.docs": "documentazione",
            "link.settings": "Impostazioni > Integrazione",
            "view": "Visualizza dashboard",
            "customize_script": "Personalizza script",
            "read_docs": "Leggi la documentazione",
            "copy_to_clipboard": "Copia negli appunti",
            "website.title": "Integrazione del sito web",
            "website.text_0": "Traccia le visualizzazioni di pagina, i link in uscita, i download di file, gli eventi personalizzati e altro dal tuo sito web.",
            "website.text_1": "Traccia le visualizzazioni di pagina dal tuo sito web. Include il tracciamento automatico dei link in uscita, il tracciamento dei download di file e gli eventi utilizzando JavaScript, classi CSS e attributi HTML.",
            "website.text_2": "Copia il seguente snippet di codice nella sezione {0} del tuo sito web.",
            "backend.title": "API server-side",
            "backend.text_0": "Traccia le visualizzazioni di pagina e gli eventi dal tuo server senza bisogno di JavaScript sul tuo sito web.",
            "backend.text_1": "L'integrazione server-side utilizza l'{0}. {1} nella pagina delle impostazioni di integrazione per iniziare a utilizzarla.",
            "backend.text_1.link_0": "API",
            "backend.text_1.link_1": "Crea un client",
            "backend.text_2": "Ecco un semplice esempio in JavaScript per creare una visualizzazione di pagina. Altri esempi possono essere trovati su {0}.",
            "backend.text_2.link": "GitHub"
        },
        "pt": {
            "title": "Escolha sua integração",
            "text": "Seu painel foi criado. Escolha uma integração abaixo ou confira a {0} para encontrar uma que atenda às suas necessidades. O script pode ser acessado a qualquer momento via {1}.",
            "link.docs": "documentação",
            "link.settings": "Configurações > Integração",
            "view": "Ver painel",
            "customize_script": "Personalizar script",
            "read_docs": "Leia a documentação",
            "copy_to_clipboard": "Copiar para a área de transferência",
            "website.title": "Integração do site",
            "website.text_0": "Acompanhe visualizações de páginas, links de saída, downloads de arquivos, eventos personalizados e muito mais no seu site.",
            "website.text_1": "Acompanhe visualizações de páginas do seu site. Inclui rastreamento automático de links de saída, rastreamento de downloads de arquivos e eventos usando JavaScript, classes CSS e atributos HTML.",
            "website.text_2": "Copie o seguinte trecho de código na seção {0} do seu site.",
            "backend.title": "API do lado do servidor",
            "backend.text_0": "Acompanhe visualizações de páginas e eventos do seu servidor sem a necessidade de JavaScript no seu site.",
            "backend.text_1": "A integração do lado do servidor utiliza a {0}. {1} na página de configurações de integração para começar a usá-la.",
            "backend.text_1.link_0": "API",
            "backend.text_1.link_1": "Criar um cliente",
            "backend.text_2": "Aqui está um exemplo simples em JavaScript para criar uma visualização de página. Mais exemplos podem ser encontrados em {0}.",
            "backend.text_2.link": "GitHub"
        },
        "ja": {
            "title": "統合を選択",
            "text": "ダッシュボードが作成されました。以下の統合を選択するか、ニーズに合ったものを見つけるために{0}を確認してください。スクリプトはいつでも{1}からアクセスできます。",
            "link.docs": "ドキュメント",
            "link.settings": "設定 > 統合",
            "view": "ダッシュボードを見る",
            "customize_script": "スクリプトをカスタマイズ",
            "read_docs": "ドキュメントを読む",
            "copy_to_clipboard": "クリップボードにコピー",
            "website.title": "ウェブサイト統合",
            "website.text_0": "あなたのウェブサイトからページビュー、外部リンク、ファイルダウンロード、カスタムイベントなどを追跡します。",
            "website.text_1": "あなたのウェブサイトからページビューを追跡します。JavaScript、CSSクラス、およびHTML属性を使用して、外部リンクの自動追跡、ファイルダウンロードの追跡、およびイベントを含みます。",
            "website.text_2": "次のコードスニペットをウェブサイトの{0}セクションにコピーします。",
            "backend.title": "サーバーサイドAPI",
            "backend.text_0": "あなたのウェブサイトにJavaScriptを必要とせずに、サーバーからページビューとイベントを追跡します。",
            "backend.text_1": "サーバーサイドの統合は{0}を利用します。これを使用するには、統合設定ページで{1}。",
            "backend.text_1.link_0": "API",
            "backend.text_1.link_1": "クライアントを作成",
            "backend.text_2": "ここにページビューを作成するためのJavaScriptの簡単な例があります。詳細な例は{0}にあります。",
            "backend.text_2.link": "GitHub"
        }
    }
</i18n>
