import {dateParam, timeParam} from "@/util/format";
import {APIError} from "@/repositories/APIError";
import {ActiveVisitorsData} from "@/model/ActiveVisitorsData";
import {Growth} from "@/model/Growth";
import {getAccessCode} from "@/util/url";
import {Keyword} from "@/model/Keyword";
import {VisitorStats} from "@/model/VisitorStats";
import {PageStats} from "@/model/PageStats";
import {VisitorHourStats} from "@/model/VisitorHourStats";
import {LanguageStats} from "@/model/LanguageStats";
import {ReferrerStats} from "@/model/ReferrerStats";
import {OSStats} from "@/model/OSStats";
import {BrowserStats} from "@/model/BrowserStats";
import {CountryStats} from "@/model/CountryStats";
import {PlatformStats} from "@/model/PlatformStats";
import {ScreenClassStats} from "@/model/ScreenClassStats";
import {UTMSourceStats} from "@/model/UTMSourceStats";
import {UTMMediumStats} from "@/model/UTMMediumStats";
import {UTMCampaignStats} from "@/model/UTMCampaignStats";
import {UTMContentStats} from "@/model/UTMContentStats";
import {UTMTermStats} from "@/model/UTMTermStats";
import {TimeSpentStats} from "@/model/TimeSpentStats";
import {Filter} from "@/model/Filter";
import {EntryStats} from "@/model/EntryStats";
import {ExitStats} from "@/model/ExitStats";
import {OSVersionStats} from "@/model/OSVersionStats";
import {BrowserVersionStats} from "@/model/BrowserVersionStats";
import {ConversionGoalStats} from "@/model/ConversionGoalStats";
import {EventStats} from "@/model/EventStats";
import {CityStats} from "@/model/CityStats";
import {TotalVisitorStats} from "@/model/TotalVisitorStats";
import {Repository} from "@/repositories/Repository";
import {getTimezone} from "@/util/date";
import {TotalVisitorsPageViewsStats} from "@/model/TotalVisitorsPageViewsStats";
import {TagStats} from "@/model/TagStats";
import {VisitorMinuteStats} from "@/model/VisitorMinuteStats";
import {Session} from "@/model/Session";
import {SessionStep} from "@/model/SessionStep";
import {RegionStats} from "@/model/RegionStats";
import {defaultFunnelData, FunnelData} from "@/model/FunnelData";

export const StatisticsRepo = new class extends Repository {
    async totalVisitors(filter: Filter, cancellationName: string): Promise<TotalVisitorStats | APIError> {
        return this.performGet<TotalVisitorStats>("/statistics/total", this.getFilterParams(filter), {
            visitors: 0,
            views: 0,
            sessions: 0,
            bounces: 0,
            bounce_rate: 0,
            cr: 0,
            custom_metric_avg: 0,
            custom_metric_total: 0
        }, false, cancellationName);
    }

    async overview(filter: Filter): Promise<TotalVisitorsPageViewsStats | APIError> {
        return this.performGet<TotalVisitorsPageViewsStats>("/statistics/overview", this.getFilterParams(filter), {
            visitors: 0,
            views: 0,
            visitors_growth: 0,
            views_growth: 0,
            visitors_time_series: [],
            member: 0
        }, true);
    }

    async visitors(filter: Filter, cancellationName: string): Promise<VisitorStats[] | APIError> {
        return this.performGet<VisitorStats[]>("/statistics/visitor", this.getFilterParams(filter), [], false, cancellationName);
    }

    async pages(filter: Filter): Promise<PageStats[] | APIError> {
        return this.performGet<PageStats[]>("/statistics/page", this.getFilterParams(filter), [], true);
    }

    async eventPages(filter: Filter): Promise<PageStats[] | APIError> {
        return this.performGet<PageStats[]>("/statistics/event/page", this.getFilterParams(filter), [], true);
    }

    async entryPages(filter: Filter): Promise<EntryStats[] | APIError> {
        return this.performGet<EntryStats[]>("/statistics/page/entry", this.getFilterParams(filter), [], true);
    }

    async exitPages(filter: Filter): Promise<ExitStats[] | APIError> {
        return this.performGet<ExitStats[]>("/statistics/page/exit", this.getFilterParams(filter), [], true);
    }

    async growth(filter: Filter): Promise<Growth | APIError> {
        return this.performGet<Growth>("/statistics/growth", this.getFilterParams(filter), {
            visitors_growth: 0,
            views_growth: 0,
            sessions_growth: 0,
            bounces_growth: 0,
            time_spent_growth: 0,
            cr_growth: 0,
            custom_metric_avg_growth: 0,
            custom_metric_total_growth: 0
        });
    }

    async activeVisitors(id: string): Promise<ActiveVisitorsData | APIError> {
        return this.performGet<ActiveVisitorsData>("/statistics/active", {
            id,
            access: getAccessCode()
        }, {
            stats: [],
            countries: [],
            visitors: 0
        });
    }

    async visitorsByHour(filter: Filter, cancellationName: string): Promise<VisitorHourStats[] | APIError> {
        return this.performGet<VisitorHourStats[]>("/statistics/hours", this.getFilterParams(filter), [], false, cancellationName);
    }

    async visitorsByMinute(filter: Filter, cancellationName: string): Promise<VisitorMinuteStats[] | APIError> {
        return this.performGet<VisitorMinuteStats[]>("/statistics/minutes", this.getFilterParams(filter), [], false, cancellationName);
    }

    async languages(filter: Filter): Promise<LanguageStats[] | APIError> {
        return this.performGet<LanguageStats[]>("/statistics/language", this.getFilterParams(filter), [], true);
    }

    async referrer(filter: Filter): Promise<ReferrerStats[] | APIError> {
        return this.performGet<ReferrerStats[]>("/statistics/referrer", this.getFilterParams(filter), [], true);
    }

    async os(filter: Filter): Promise<OSStats[] | APIError> {
        return this.performGet<OSStats[]>("/statistics/os", this.getFilterParams(filter), []);
    }

    async osVersions(filter: Filter): Promise<OSVersionStats[] | APIError> {
        return this.performGet<OSVersionStats[]>("/statistics/os/version", this.getFilterParams(filter), []);
    }

    async browser(filter: Filter): Promise<BrowserStats[] | APIError> {
        return this.performGet<BrowserStats[]>("/statistics/browser", this.getFilterParams(filter), []);
    }

    async browserVersions(filter: Filter): Promise<BrowserVersionStats[] | APIError> {
        return this.performGet<BrowserVersionStats[]>("/statistics/browser/version", this.getFilterParams(filter), []);
    }

    async country(filter: Filter): Promise<CountryStats[] | APIError> {
        return this.performGet<CountryStats[]>("/statistics/country", this.getFilterParams(filter), [], true);
    }

    async region(filter: Filter): Promise<RegionStats[] | APIError> {
        return this.performGet<RegionStats[]>("/statistics/region", this.getFilterParams(filter), [], true);
    }

    async city(filter: Filter): Promise<CityStats[] | APIError> {
        return this.performGet<CityStats[]>("/statistics/city", this.getFilterParams(filter), [], true);
    }

    async platform(filter: Filter): Promise<PlatformStats | APIError> {
        return this.performGet<PlatformStats>("/statistics/platform", this.getFilterParams(filter), {
            platform_desktop: 0,
            platform_mobile: 0,
            platform_unknown: 0,
            relative_platform_desktop: 0,
            relative_platform_mobile: 0,
            relative_platform_unknown: 0
        });
    }

    async screen(filter: Filter): Promise<ScreenClassStats[] | APIError> {
        return this.performGet<ScreenClassStats[]>("/statistics/screen", this.getFilterParams(filter), []);
    }

    async utmSource(filter: Filter): Promise<UTMSourceStats[] | APIError> {
        return this.performGet<UTMSourceStats[]>("/statistics/utm/source", this.getFilterParams(filter), [], true);
    }

    async utmMedium(filter: Filter): Promise<UTMMediumStats[] | APIError> {
        return this.performGet<UTMMediumStats[]>("/statistics/utm/medium", this.getFilterParams(filter), [], true);
    }

    async utmCampaign(filter: Filter): Promise<UTMCampaignStats[] | APIError> {
        return this.performGet<UTMCampaignStats[]>("/statistics/utm/campaign", this.getFilterParams(filter), [], true);
    }

    async utmContent(filter: Filter): Promise<UTMContentStats[] | APIError> {
        return this.performGet<UTMContentStats[]>("/statistics/utm/content", this.getFilterParams(filter), [], true);
    }

    async utmTerm(filter: Filter): Promise<UTMTermStats[] | APIError> {
        return this.performGet<UTMTermStats[]>("/statistics/utm/term", this.getFilterParams(filter), [], true);
    }

    async averageSessionDuration(filter: Filter, cancellationName: string): Promise<TimeSpentStats[] | APIError> {
        return this.performGet<TimeSpentStats[]>("/statistics/duration/session", this.getFilterParams(filter), [], false, cancellationName);
    }

    async averageTimeOnPge(filter: Filter, cancellationName: string): Promise<TimeSpentStats[] | APIError> {
        return this.performGet<TimeSpentStats[]>("/statistics/duration/page", this.getFilterParams(filter), [], false, cancellationName);
    }

    async keywords(filter: Filter): Promise<Keyword[] | APIError> {
        return this.performGet<Keyword[]>("/statistics/keywords", this.getFilterParams(filter), [], true);
    }

    async conversionGoals(filter: Filter): Promise<ConversionGoalStats[] | APIError> {
        return this.performGet<ConversionGoalStats[]>("/statistics/goals", this.getFilterParams(filter), [], true);
    }

    async events(filter: Filter): Promise<EventStats[] | APIError> {
        return this.performGet<EventStats[]>("/statistics/events", this.getFilterParams(filter), [], true);
    }

    async eventMetadata(filter: Filter): Promise<EventStats[] | APIError> {
        return this.performGet<EventStats[]>("/statistics/event/meta", this.getFilterParams(filter), [], true);
    }

    async tagKeys(filter: Filter): Promise<TagStats[] | APIError> {
        return this.performGet<TagStats[]>("/statistics/tags", this.getFilterParams(filter), [], true);
    }

    async tags(filter: Filter): Promise<TagStats[] | APIError> {
        return this.performGet<TagStats[]>("/statistics/tag/details", this.getFilterParams(filter), [], true);
    }

    async sessions(filter: Filter): Promise<Session[] | APIError> {
        return this.performGet<Session[]>("/statistics/session/list", this.getFilterParams(filter), []);
    }

    async session(filter: Filter): Promise<SessionStep[] | APIError> {
        return this.performGet<SessionStep[]>("/statistics/session/details", this.getFilterParams(filter), []);
    }

    async funnel(funnel_id: string, filter: Filter): Promise<FunnelData | APIError> {
        return this.performGet<FunnelData>("/statistics/funnel", {funnel_id, ...this.getFilterParams(filter)}, defaultFunnelData(), true);
    }

    async deleteData(id: string, kind: string, from: Date, to: Date): Promise<APIError | null> {
        return this.performDelete("/statistics", {id, kind, from: dateParam(from), to: dateParam(to)});
    }

    async pageOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/page", this.getFilterParams(filter), []);
    }

    async referrerOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/referrer", this.getFilterParams(filter), []);
    }

    async referrerNameOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/referrer/name", this.getFilterParams(filter), []);
    }

    async utmSourceOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/utm/source", this.getFilterParams(filter), []);
    }

    async utmMediumOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/utm/medium", this.getFilterParams(filter), []);
    }

    async utmCampaignOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/utm/campaign", this.getFilterParams(filter), []);
    }

    async utmContentOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/utm/content", this.getFilterParams(filter), []);
    }

    async utmTermOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/utm/term", this.getFilterParams(filter), []);
    }

    async eventOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/event", this.getFilterParams(filter), []);
    }

    async countryOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/country", this.getFilterParams(filter), []);
    }

    async regionOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/region", this.getFilterParams(filter), []);
    }

    async cityOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/city", this.getFilterParams(filter), []);
    }

    async languageOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/language", this.getFilterParams(filter), []);
    }

    async metadataOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/metadata", this.getFilterParams(filter), []);
    }

    async tagOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/tag", this.getFilterParams(filter), []);
    }

    async tagValueOptions(filter: Filter): Promise<string[] | APIError> {
        return this.performGet<string[]>("/statistics/options/tag/value", this.getFilterParams(filter), []);
    }

    private getFilterParams(filter: Filter) {
        return {
            access: getAccessCode(),
            id: filter.client_id,
            from: filter.timeRange ? dateParam(filter.timeRange.from) : undefined,
            to: filter.timeRange ? dateParam(filter.timeRange.to) : undefined,
            from_time: filter.timeRange?.fromTime ? timeParam(filter.timeRange.fromTime) : undefined,
            to_time: filter.timeRange?.toTime ? timeParam(filter.timeRange.toTime) : undefined,
            tz: getTimezone(),
            start: filter.timeRange && filter.timeRange.start ? filter.timeRange.start : undefined,
            scale: filter.scale,
            offset: filter.offset,
            limit: filter.limit,
            include_avg_time_on_page: filter.include_avg_time_on_page,
            search: filter.search,
            sort: filter.sort,
            direction: filter.direction,
            visitor_id: filter.visitor_id,
            session_id: filter.session_id,
            ...this.getFilterFields(filter)
        };
    }

    private getFilterFields(filter: Filter) {
        const params = new Map;

        if (filter.fields) {
            for (let i = 0; i < filter.fields.length; i++) {
                let key = filter.fields[i].field;
                let value = filter.fields[i].value;

                if (key === "event_name") {
                    key = "event";
                }

                if (key === "pattern") {
                    const parts = value.split("|");

                    if (parts.length > 1) {
                        value = parts[1];
                    } else {
                        value = parts[0];
                    }
                }

                if (!params.has(key)) {
                    params.set(key, []);
                }

                params.get(key).push(value);
            }
        }

        return Object.fromEntries(params);
    }
}
