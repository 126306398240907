<template>
    <div class="list">
        <ListTabs :tabs="tabs" v-on:enlarge="enlarge" v-on:open="openTab" />
        <Page :visible="open === 0" v-show="open === 0" />
        <EntryPage :visible="open === 1" v-show="open === 1" />
        <ExitPage :visible="open === 2" v-show="open === 2" />
        <EventPage :visible="open === 3" v-show="open === 3" />
        <PageDetails :open="details === 0" v-on:close="details = -1" />
        <EntryPageDetails :open="details === 1" v-on:close="details = -1" />
        <ExitPageDetails :open="details === 2" v-on:close="details = -1" />
        <EventPageDetails :open="details === 3" v-on:close="details = -1" />
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, ref, watch} from "vue";
    import ListTabs from "@/components/panels/ListTabs.vue";
    import Page from "@/components/statistics/Page.vue";
    import EntryPage from "@/components/statistics/EntryPage.vue";
    import ExitPage from "@/components/statistics/ExitPage.vue";
    import PageDetails from "@/components/statistics/PageDetails.vue";
    import EntryPageDetails from "@/components/statistics/EntryPageDetails.vue";
    import ExitPageDetails from "@/components/statistics/ExitPageDetails.vue";
    import EventPage from "@/components/statistics/EventPage.vue";
    import EventPageDetails from "@/components/statistics/EventPageDetails.vue";
    import {useFilterStore} from "@/store/FilterStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            ListTabs,
            Page,
            PageDetails,
            EntryPage,
            EntryPageDetails,
            ExitPage,
            ExitPageDetails,
            EventPage,
            EventPageDetails
        },
        setup() {
            const {t} = useI18n();
            const open = ref(0);
            const details = ref(-1);
            const {hasField} = useFilterStore();
            const eventFilter = computed(() => hasField("event"));
            const tabs = computed(() => {
                const tabs = [
                    {
                        title: t("tabs.pages"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.entry_pages"),
                        showEnlarge: true
                    },
                    {
                        title: t("tabs.exit_pages"),
                        showEnlarge: true
                    }
                ];

                if (eventFilter.value) {
                    tabs.push({
                        title: t("tabs.event_pages"),
                        showEnlarge: true
                    });
                }

                return tabs;
            });

            watch(eventFilter, () => {
                if (!eventFilter.value && open.value > 2) {
                    open.value = 0;
                }
            });

            function enlarge(index: number) {
                details.value = index;
            }

            function openTab(index: number) {
                open.value = index;
            }

            return {
                tabs,
                open,
                details,
                enlarge,
                openTab
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "tabs.pages": "Pages",
            "tabs.entry_pages": "Entry Pages",
            "tabs.exit_pages": "Exit Pages",
            "tabs.event_pages": "Event Pages"
        },
        "de": {
            "tabs.pages": "Seiten",
            "tabs.entry_pages": "Einstiegsseiten",
            "tabs.exit_pages": "Ausstiegsseiten",
            "tabs.event_pages": "Eventseiten"
        },
        "es": {
            "tabs.pages": "Páginas",
            "tabs.entry_pages": "Páginas de Entrada",
            "tabs.exit_pages": "Páginas de Salida",
            "tabs.event_pages": "Páginas de Eventos"
        },
        "fr": {
            "tabs.pages": "Pages",
            "tabs.entry_pages": "Pages d'Entrée",
            "tabs.exit_pages": "Pages de Sortie",
            "tabs.event_pages": "Pages d'Événements"
        },
        "nl": {
            "tabs.pages": "Pagina's",
            "tabs.entry_pages": "Ingangspagina's",
            "tabs.exit_pages": "Uitgangspagina's",
            "tabs.event_pages": "Evenementpagina's"
        },
        "it": {
            "tabs.pages": "Pagine",
            "tabs.entry_pages": "Pagine di Ingresso",
            "tabs.exit_pages": "Pagine di Uscita",
            "tabs.event_pages": "Pagine di Eventi"
        },
        "pt": {
            "tabs.pages": "Páginas",
            "tabs.entry_pages": "Páginas de Entrada",
            "tabs.exit_pages": "Páginas de Saída",
            "tabs.event_pages": "Páginas de Eventos"
        },
        "ja": {
            "tabs.pages": "ページ",
            "tabs.entry_pages": "エントリーページ",
            "tabs.exit_pages": "退出ページ",
            "tabs.event_pages": "イベントページ"
        }
    }
</i18n>
