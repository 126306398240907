<template>
    <header class="no-select">
        <div class="header-menu">
            <AppLogo />
            <template v-if="loggedIn || $route.name === 'Dashboard' || $route.name === 'Funnel' || $route.name === 'Sessions'">
                <DomainSelection />
                <ActiveVisitors />
            </template>
        </div>
        <div class="header-menu">
            <template v-if="loggedIn && (isDashboard || isCustomDomain)">
                <HeaderEntry icon="add" :tooltip="t('add')" v-if="!user.read_only && !mobile">
                    <router-link to="/add">{{t("link.add_website")}}</router-link>
                    <a href="#" v-on:click.stop.prevent="addFunnel">{{t("link.add_funnel")}}</a>
                    <a href="#" v-on:click.stop.prevent="addOrganization">{{t("link.add_organization")}}</a>
                </HeaderEntry>
                <HeaderEntry icon="library"
                    :tooltip="t('resources')"
                    v-if="showResources && showResourcesMenu && (!mobile || $route.path.startsWith('/settings'))">
                    <a :href="docsURL" target="_blank">{{t("link.docs")}}</a>
                    <a :href="docsURL+'/changelog'" target="_blank">{{t("link.changelog")}}</a>
                    <a :href="websiteURL+'/utm-generator'" target="_blank">{{t("link.utm")}}</a>
                    <a :href="websiteURL+'/static/files/Data Processing Agreement - Pirsch Analytics.pdf'" target="_blank">{{t("link.dpa_en")}}</a>
                    <a :href="websiteURL+'/static/files/Vertrag zur Auftragsverarbeitung - Pirsch Analytics.pdf'" target="_blank">{{t("link.dpa_de")}}</a>
                    <hr />
                    <a :href="websiteURL+'/blog'" target="_blank">{{t("link.blog")}}</a>
                    <a :href="websiteURL+'/glossary'" target="_blank">{{t("link.glossary")}}</a>
                    <a href="https://github.com/pirsch-analytics/pirsch" target="_blank">{{t("link.github")}}</a>
                    <a href="https://twitter.com/PirschAnalytics" target="_blank">{{t("link.twitter")}}</a>
                    <a href="https://www.indiehackers.com/product/pirsch-analytics" target="_blank">{{t("link.ih")}}</a>
                    <a href="https://www.producthunt.com/products/pirsch-analytics" target="_blank">{{t("link.ph")}}</a>
                    <hr />
                    <a href="https://forum.pirsch.io" target="_blank">{{t("link.forum")}}</a>
                    <a href="mailto:support@pirsch.io">{{t("link.email")}}</a>
                </HeaderEntry>
            </template>
            <User />
        </div>
    </header>
</template>

<script lang="ts">
    import {computed, defineComponent} from "vue";
    import HeaderEntry from "@/components/bits/HeaderEntry.vue";
    import User from "@/components/bits/User.vue";
    import DomainSelection from "@/components/domain/DomainSelection.vue";
    import ActiveVisitors from "@/components/statistics/ActiveVisitors.vue";
    import {isCustomDomain, isDashboard} from "@/util/domain";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useMobile} from "@/components/mobile";
    import AppLogo from "@/components/bits/AppLogo.vue";
    import {ThemeSettings, useThemeStore} from "@/store/ThemeStore";
    import {useI18n} from "vue-i18n";
    import {useRouter} from "vue-router";

    export default defineComponent({
        components: {
            AppLogo,
            HeaderEntry,
            User,
            DomainSelection,
            ActiveVisitors
        },
        setup() {
            const router = useRouter();
            const {theme} = storeToRefs(useThemeStore());
            const showResourcesMenu = computed(() => {
                const themeSettings = theme.value ?? {};
                return !themeSettings[ThemeSettings.hideResources] || themeSettings[ThemeSettings.hideResources] === "false";
            });
            let open = 0;

            function addFunnel() {
                router.push({path: "/funnels", query: {open}});
                open++;
            }

            function addOrganization() {
                router.push({path: "/organizations", query: {open}});
                open++;
            }

            return {
                ...useI18n(),
                websiteURL: import.meta.env.VITE_APP_WEBSITE_HOST,
                docsURL: import.meta.env.VITE_APP_DOCS_URL,
                showResources: import.meta.env.VITE_APP_MENU_RESOURCES,
                isDashboard: isDashboard(),
                isCustomDomain: isCustomDomain(),
                ...useMobile(),
                ...storeToRefs(useUserStore()),
                ...storeToRefs(useDomainStore()),
                showResourcesMenu,
                addFunnel,
                addOrganization
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "add": "Add Website, Funnel, or Organization",
            "resources": "Resources",
            "link.add_website": "Add Website",
            "link.add_funnel": "Add Funnel",
            "link.add_organization": "Add Organization",
            "link.docs": "Documentation",
            "link.changelog": "Changelog",
            "link.utm": "UTM Generator",
            "link.dpa_en": "DPA (English)",
            "link.dpa_de": "DPA (German)",
            "link.blog": "Blog",
            "link.glossary": "Glossary",
            "link.github": "GitHub",
            "link.twitter": "Twitter",
            "link.ih": "Indie Hackers",
            "link.ph": "ProductHunt",
            "link.forum": "Forum",
            "link.email": "Email Support"
        },
        "de": {
            "add": "Füge Webseite, Funnel oder Organisation hinzu",
            "resources": "Ressourcen",
            "link.add_website": "Webseite hinzufügen",
            "link.add_funnel": "Funnel hinzufügen",
            "link.add_organization": "Organisation hinzufügen",
            "link.docs": "Dokumentation",
            "link.changelog": "Changelog",
            "link.utm": "UTM-Generator",
            "link.dpa_en": "AV-Vertrag (Englisch)",
            "link.dpa_de": "AV-Vertrag (Deutsch)",
            "link.blog": "Blog",
            "link.glossary": "Glossar",
            "link.github": "GitHub",
            "link.twitter": "Twitter",
            "link.ih": "Indie Hackers",
            "link.ph": "ProductHunt",
            "link.forum": "Forum",
            "link.email": "E-Mail-Support"
        },
        "es": {
            "add": "Añadir sitio web, embudo u organización",
            "resources": "Recursos",
            "link.add_website": "Añadir sitio web",
            "link.add_funnel": "Añadir embudo",
            "link.add_organization": "Añadir organización",
            "link.docs": "Documentación",
            "link.changelog": "Registro de cambios",
            "link.utm": "Generador de UTM",
            "link.dpa_en": "DPA (Inglés)",
            "link.dpa_de": "DPA (Alemán)",
            "link.blog": "Blog",
            "link.glossary": "Glosario",
            "link.github": "GitHub",
            "link.twitter": "Twitter",
            "link.ih": "Indie Hackers",
            "link.ph": "ProductHunt",
            "link.forum": "Foro",
            "link.email": "Soporte por correo electrónico"
        },
        "fr": {
            "add": "Ajouter un site web, un entonnoir ou une organisation",
            "resources": "Ressources",
            "link.add_website": "Ajouter un site web",
            "link.add_funnel": "Ajouter un entonnoir",
            "link.add_organization": "Ajouter une organisation",
            "link.docs": "Documentation",
            "link.changelog": "Journal des modifications",
            "link.utm": "Générateur UTM",
            "link.dpa_en": "DPA (Anglais)",
            "link.dpa_de": "DPA (Allemand)",
            "link.blog": "Blog",
            "link.glossary": "Glossaire",
            "link.github": "GitHub",
            "link.twitter": "Twitter",
            "link.ih": "Indie Hackers",
            "link.ph": "ProductHunt",
            "link.forum": "Forum",
            "link.email": "Support par e-mail"
        },
        "nl": {
            "add": "Voeg een website, trechter of organisatie toe",
            "resources": "Middelen",
            "link.add_website": "Website toevoegen",
            "link.add_funnel": "Trechter toevoegen",
            "link.add_organization": "Organisatie toevoegen",
            "link.docs": "Documentatie",
            "link.changelog": "Wijzigingslogboek",
            "link.utm": "UTM-generator",
            "link.dpa_en": "DPA (Engels)",
            "link.dpa_de": "DPA (Duits)",
            "link.blog": "Blog",
            "link.glossary": "Woordenlijst",
            "link.github": "GitHub",
            "link.twitter": "Twitter",
            "link.ih": "Indie Hackers",
            "link.ph": "ProductHunt",
            "link.forum": "Forum",
            "link.email": "E-mailondersteuning"
        },
        "it": {
            "add": "Aggiungi sito web, imbuto o organizzazione",
            "resources": "Risorse",
            "link.add_website": "Aggiungi sito web",
            "link.add_funnel": "Aggiungi imbuto",
            "link.add_organization": "Aggiungi organizzazione",
            "link.docs": "Documentazione",
            "link.changelog": "Registro delle modifiche",
            "link.utm": "Generatore UTM",
            "link.dpa_en": "DPA (Inglese)",
            "link.dpa_de": "DPA (Tedesco)",
            "link.blog": "Blog",
            "link.glossary": "Glossario",
            "link.github": "GitHub",
            "link.twitter": "Twitter",
            "link.ih": "Indie Hackers",
            "link.ph": "ProductHunt",
            "link.forum": "Forum",
            "link.email": "Supporto via email"
        },
        "pt": {
            "add": "Adicionar site, funil ou organização",
            "resources": "Recursos",
            "link.add_website": "Adicionar sítio Web",
            "link.add_funnel": "Adicionar funil",
            "link.add_organization": "Adicionar organização",
            "link.docs": "Documentação",
            "link.changelog": "Registro de alterações",
            "link.utm": "Gerador de UTM",
            "link.dpa_en": "DPA (Inglês)",
            "link.dpa_de": "DPA (Alemão)",
            "link.blog": "Blog",
            "link.glossary": "Glossário",
            "link.github": "GitHub",
            "link.twitter": "Twitter",
            "link.ih": "Indie Hackers",
            "link.ph": "ProductHunt",
            "link.forum": "Fórum",
            "link.email": "Suporte por e-mail"
        },
        "ja": {
            "add": "ウェブサイト、ファネル、または組織を追加",
            "resources": "リソース",
            "link.add_website": "ウェブサイトを追加",
            "link.add_funnel": "ファンネル追加",
            "link.add_organization": "組織の追加",
            "link.docs": "ドキュメント",
            "link.changelog": "変更履歴",
            "link.utm": "UTMジェネレーター",
            "link.dpa_en": "DPA（英語）",
            "link.dpa_de": "DPA（ドイツ語）",
            "link.blog": "ブログ",
            "link.glossary": "用語集",
            "link.github": "GitHub",
            "link.twitter": "Twitter",
            "link.ih": "Indie Hackers",
            "link.ph": "ProductHunt",
            "link.forum": "フォーラム",
            "link.email": "メールサポート"
        }
    }
</i18n>
