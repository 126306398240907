import {ThemeSettings} from "@/store/ThemeStore";
import {KeyValue} from "@/model/KeyValue";

export function getBackgroundColor(height: number, darkMode: boolean, color: string, previousPeriod: boolean, inverted: boolean, theme?: KeyValue) {
    const c = document.createElement("canvas");
    const ctx = c.getContext("2d");
    let gradient: CanvasGradient;

    if (ctx) {
        let bg: string;
        let usesTheme: boolean;
        const themeSettings = theme ?? {};

        switch (color) {
            case "yellow":
                bg = selectColor(themeSettings[ThemeSettings.lightColorYellow], "rgba(255,206,61,", themeSettings[ThemeSettings.darkColorYellow], "rgba(255,206,61,", darkMode);
                usesTheme = darkMode ? !!themeSettings[ThemeSettings.darkColorYellow] : !!themeSettings[ThemeSettings.lightColorYellow];
                break;
            case "red":
                bg = selectColor(themeSettings[ThemeSettings.lightColorRed], "rgba(232,123,123,", themeSettings[ThemeSettings.darkColorRed], "rgba(232,123,123,", darkMode);
                usesTheme = darkMode ? !!themeSettings[ThemeSettings.darkColorRed] : !!themeSettings[ThemeSettings.lightColorRed];
                break;
            default:
                bg = selectColor(themeSettings[ThemeSettings.lightColorGreen], "rgba(110,206,157,", themeSettings[ThemeSettings.darkColorGreen], "rgba(110,206,157,", darkMode);
                usesTheme = darkMode ? !!themeSettings[ThemeSettings.darkColorGreen] : !!themeSettings[ThemeSettings.lightColorGreen];
                break;
        }

        gradient = inverted ? ctx.createLinearGradient(0, height, 0, 0) : ctx.createLinearGradient(0, 0, 0, height);

        if (previousPeriod) {
            gradient.addColorStop(0, "#70707034");
            gradient.addColorStop(1, "#70707000");
        } else if (usesTheme) {
            gradient.addColorStop(0, bg + "34");
            gradient.addColorStop(1, bg + "00");
        } else {
            gradient.addColorStop(0, bg + ".2)");
            gradient.addColorStop(1, bg + "0)");
        }

        return gradient;
    }

    return "rgba(0,0,0,0)";
}

export function getBorderColor(darkMode: boolean, color: string, previousPeriod: boolean, theme?: KeyValue) {
    const themeSettings = theme ?? {};

    switch (color) {
        case "yellow":
            return selectColor(previousPeriod ? themeSettings[ThemeSettings.lightColorSecondary] : themeSettings[ThemeSettings.lightColorYellow],
                previousPeriod ? "rgba(112,112,112,1)" : "rgba(255,206,61,1)",
                previousPeriod ? themeSettings[ThemeSettings.lightColorSecondary] : themeSettings[ThemeSettings.darkColorYellow],
                previousPeriod ? "rgba(112,112,112,1)" : "rgba(255,206,61,1)", darkMode);
        case "red":
            return selectColor(previousPeriod ? themeSettings[ThemeSettings.lightColorSecondary] : themeSettings[ThemeSettings.lightColorRed],
                previousPeriod ? "rgba(112,112,112,1)" : "rgba(232,123,123,1)",
                previousPeriod ? themeSettings[ThemeSettings.lightColorSecondary] : themeSettings[ThemeSettings.darkColorRed],
                previousPeriod ? "rgba(112,112,112,1)" : "rgba(232,123,123,1)", darkMode);
        default:
            return selectColor(previousPeriod ? themeSettings[ThemeSettings.lightColorSecondary] : themeSettings[ThemeSettings.lightColorGreen],
                previousPeriod ? "rgba(112,112,112,1)" : "rgba(110,206,157,1)",
                previousPeriod ? themeSettings[ThemeSettings.lightColorSecondary] : themeSettings[ThemeSettings.darkColorGreen],
                previousPeriod ? "rgba(112,112,112,1)" : "rgba(110,206,157,1)", darkMode);
    }
}

export function getTextColor(darkMode: boolean, theme?: KeyValue) {
    const themeSettings = theme ?? {};
    return selectColor(themeSettings[ThemeSettings.lightColorPrimary], "rgba(160,145,145,1)", themeSettings[ThemeSettings.darkColorPrimary], "rgba(173,173,173,1)", darkMode);
}

export function getLineColor(darkMode: boolean, theme?: KeyValue) {
    const themeSettings = theme ?? {};
    return selectColor(themeSettings[ThemeSettings.lightColorSecondary + "34"], "rgba(112,112,112,.2)", themeSettings[ThemeSettings.darkColorSecondary + "34"], "rgba(173,173,173,.2)", darkMode);
}

export function selectColor(light: string, fallbackLight: string, dark: string, fallbackDark: string, darkMode: boolean) {
    if (darkMode) {
        if (dark && dark !== "") {
            return dark;
        }

        return fallbackDark;
    }

    if (light && light !== "") {
        return light;
    }

    return fallbackLight;
}
