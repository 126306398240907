import {defineStore} from "pinia";
import {ref, watch} from "vue";
import {ThemeRepo} from "@/repositories/ThemeRepo";
import {Theme} from "@/model/Theme";
import {KeyValue} from "@/model/KeyValue";
import {Domain} from "@/model/Domain";
import {isDashboard} from "@/util/domain";
import {isDarkMode, prefersDarkMode} from "@/util/style";
import {getQueryParam} from "@/util/url";

// Changes here also need to be made in dashboard/main.go.
export enum ThemeSettings {
    // meta
    logoLight = "logo_light",
    logoDark = "logo_dark",
    favicon = "favicon",
    title = "title",
    description = "description",
    hideResources = "hide_resources",
    hideLogin = "hide_login",
    hideFooter = "hide_footer",
    hideCopyright = "hide_copyright",
    hideDarkModeToggle = "hide_dark_mode_toggle",
    hideLanguageSwitch = "hide_language_switch",
    mode = "mode",

    // style
    lightColorPrimary = "--theme-light-color-primary",
    darkColorPrimary = "--theme-dark-color-primary",
    lightColorSecondary = "--theme-light-color-secondary",
    darkColorSecondary = "--theme-dark-color-secondary",
    lightColorBackgroundPrimary = "--theme-light-color-background-primary",
    darkColorBackgroundPrimary = "--theme-dark-color-background-primary",
    lightColorBackgroundSecondary = "--theme-light-color-background-secondary",
    darkColorBackgroundSecondary = "--theme-dark-color-background-secondary",
    lightColorButton = "--theme-light-color-button",
    darkColorButton = "--theme-dark-color-button",
    lightColorBackgroundButton = "--theme-light-color-background-button",
    darkColorBackgroundButton = "--theme-dark-color-background-button",
    lightColorAccent = "--theme-light-color-accent",
    darkColorAccent = "--theme-dark-color-accent",
    lightColorGreen = "--theme-light-color-green",
    darkColorGreen = "--theme-dark-color-green",
    lightColorYellow = "--theme-light-color-yellow",
    darkColorYellow = "--theme-dark-color-yellow",
    lightColorOrange = "--theme-light-color-orange",
    darkColorOrange = "--theme-dark-color-orange",
    lightColorRed = "--theme-light-color-red",
    darkColorRed = "--theme-dark-color-red",
    lightBoxShadowPrimary = "--theme-light-box-shadow-primary",
    lightBoxShadowSecondary = "--theme-light-box-shadow-secondary",
    darkBoxShadowPrimary = "--theme-dark-box-shadow-primary",
    darkBoxShadowSecondary = "--theme-dark-box-shadow-secondary",
    borderRadiusPrimary = "--theme-border-radius-primary"
}

const defaultSettings: KeyValue = {};
defaultSettings[ThemeSettings.lightColorPrimary] = "#000000";
defaultSettings[ThemeSettings.lightColorSecondary] = "#707070";
defaultSettings[ThemeSettings.lightColorBackgroundPrimary] = "#f8f5ed";
defaultSettings[ThemeSettings.lightColorBackgroundSecondary] = "#ffffff";
defaultSettings[ThemeSettings.lightColorButton] = "#000000";
defaultSettings[ThemeSettings.lightColorBackgroundButton] = "#6ece9d";
defaultSettings[ThemeSettings.lightColorAccent] = "#6ece9d";
defaultSettings[ThemeSettings.lightColorGreen] = "#6ece9d";
defaultSettings[ThemeSettings.lightColorYellow] = "#ffda6e";
defaultSettings[ThemeSettings.lightColorOrange] = "#f7a66b";
defaultSettings[ThemeSettings.lightColorRed] = "#e87b7b";
defaultSettings[ThemeSettings.darkColorPrimary] = "#ffffff";
defaultSettings[ThemeSettings.darkColorSecondary] = "#adadad";
defaultSettings[ThemeSettings.darkColorBackgroundPrimary] = "#0a0a0a";
defaultSettings[ThemeSettings.darkColorBackgroundSecondary] = "#141414";
defaultSettings[ThemeSettings.darkColorButton] = "#000000";
defaultSettings[ThemeSettings.darkColorBackgroundButton] = "#6ece9d";
defaultSettings[ThemeSettings.darkColorAccent] = "#6ece9d";
defaultSettings[ThemeSettings.darkColorGreen] = "#6ece9d";
defaultSettings[ThemeSettings.darkColorYellow] = "#ffda6e";
defaultSettings[ThemeSettings.darkColorOrange] = "#f7a66b";
defaultSettings[ThemeSettings.darkColorRed] = "#e87b7b";
defaultSettings[ThemeSettings.lightBoxShadowPrimary] = "0px 4px 16px rgba(0,0,0,.1)";
defaultSettings[ThemeSettings.lightBoxShadowSecondary] = "None";
defaultSettings[ThemeSettings.darkBoxShadowPrimary] = "0px 4px 16px rgba(0,0,0,.8)";
defaultSettings[ThemeSettings.darkBoxShadowSecondary] = "None";
export const themeDefaults = defaultSettings;

const themeMetaElements = document.querySelectorAll("[data-theme-hide]");

export const useThemeStore = defineStore("theme", () => {
    const themes = ref<Theme[]>([]);
    const theme = ref<KeyValue>();
    const darkMode = ref(isDarkMode());
    const loaded = ref(false);

    watch(theme, () => {
        updateTheme(theme.value ?? {});
    })

    async function load() {
        themes.value = await ThemeRepo.list() as Theme[];
    }

    function setTheme(domain?: Domain, settings?: KeyValue) {
        theme.value = (domain ? domain.theme_settings : settings) ?? {};

        if (!isDashboard() && localStorage.getItem("color_mode") === null) {
            setDarkMode(theme.value[ThemeSettings.mode] === "" ? "" : theme.value[ThemeSettings.mode] === "light" ? "false" : "true");
        }

        loaded.value = true;
    }

    function updateTheme(theme: KeyValue) {
        let style = "";

        for (const variable in ThemeSettings) {
            const v: string = ThemeSettings[variable as keyof typeof ThemeSettings];

            if (theme[v] && variable !== ThemeSettings.logoLight && variable !== ThemeSettings.logoDark &&
                variable !== ThemeSettings.favicon && variable !== ThemeSettings.title &&
                variable !== ThemeSettings.description && variable !== ThemeSettings.hideResources &&
                variable !== ThemeSettings.hideLogin && variable !== ThemeSettings.hideFooter &&
                variable !== ThemeSettings.hideCopyright && variable !== ThemeSettings.hideDarkModeToggle &&
                variable !== ThemeSettings.hideLanguageSwitch && variable !== ThemeSettings.mode) {
                style += `${v}: ${theme[v]};`;
            }
        }

        const html = document.getElementsByTagName("html")[0];
        html.setAttribute("style", style);
        const favicon = theme["favicon"] ? `${import.meta.env.VITE_APP_BACKEND_HOST}/theme/files/${theme["favicon"]}` : "";
        document.querySelectorAll("link[data-theme-favicon]").forEach(icon => {
            if (favicon) {
                icon.setAttribute("href", favicon);
            } else {
                icon.setAttribute("href", icon.getAttribute("data-theme-favicon") ?? "");
            }
        });
        document.querySelectorAll("meta[data-theme-meta-title]").forEach(meta => {
            if (theme[ThemeSettings.title]) {
                meta.setAttribute("content", theme[ThemeSettings.title]);
            } else {
                meta.setAttribute("content", meta.getAttribute("data-theme-meta-title") ?? "");
            }
        });
        document.querySelectorAll("meta[data-theme-meta-description]").forEach(meta => {
            if (theme[ThemeSettings.description]) {
                meta.setAttribute("content", theme[ThemeSettings.description]);
            } else {
                meta.setAttribute("content", meta.getAttribute("data-theme-meta-description") ?? "");
            }
        });
        document.querySelectorAll("meta[data-theme-color]").forEach(meta => {
            if (theme[ThemeSettings.darkColorBackgroundPrimary]) {
                meta.setAttribute("content", theme[ThemeSettings.darkColorBackgroundPrimary]);
            } else {
                meta.setAttribute("content", meta.getAttribute("data-theme-color") ?? "");
            }
        });
        const hideElements = document.querySelectorAll("[data-theme-hide]");

        if (Object.keys(theme).length) {
            hideElements.forEach(element => element.remove());
        } else {
            const head = document.getElementsByTagName("head");
            themeMetaElements.forEach(element => head[0].appendChild(element));
        }
    }

    function setDarkMode(useDarkMode: string): void {
        const html = document.getElementsByTagName("html");

        if (html.length === 1) {
            const darkModeParam = getQueryParam("mode");

            if (darkModeParam !== "") {
                useDarkMode = darkModeParam.toLowerCase() === "dark" ? "true" : "false";
            } else if (useDarkMode == "") {
                useDarkMode = prefersDarkMode().toString();
            }

            if (useDarkMode === "true") {
                html[0].classList.remove("theme-light");
                html[0].classList.add("theme-dark");
            } else {
                html[0].classList.remove("theme-dark");
                html[0].classList.add("theme-light");
            }

            darkMode.value = useDarkMode === "true";
        }

        const themeColor = document.querySelector("meta[name='theme-color']");

        if (themeColor) {
            themeColor.setAttribute("content", useDarkMode === "true" ? "#0a0a0a" : "#f8f5ed");
        }
    }

    return {
        themes,
        theme,
        darkMode,
        loaded,
        load,
        setTheme,
        setDarkMode
    };
});
