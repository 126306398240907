import {APIError} from "@/repositories/APIError";
import {ImportJob} from "@/model/ImportJob";
import {GAProfile} from "@/model/GAProfile";
import {Repository} from "@/repositories/Repository";

export const ImportRepo = new class extends Repository {
    async get(domain_id: string): Promise<ImportJob | null | APIError> {
        return this.performGet<ImportJob | null>("/google/import/job", {domain_id}, null);
    }

    async cancelImport(domain_id: string): Promise<APIError | null> {
        return this.performDelete("/google/import/job", {domain_id});
    }

    async profiles(domain_id: string): Promise<GAProfile[] | APIError> {
        return this.performGet<GAProfile[]>("/google/import/profile", {domain_id}, []);
    }

    async import(domain_id: string, property: string, from: string, to: string): Promise<ImportJob | APIError> {
        return this.performPost<ImportJob>("/google/import", {domain_id, property, from, to});
    }

    async fromFathom(domain_id: string, file: File): Promise<APIError | null> {
        const form = new FormData();
        form.append("file", file);
        form.append("domain", domain_id);
        return this.performPost<null>("/import/fathom", form);
    }

    async fromPlausible(domain_id: string, file: File): Promise<APIError | null> {
        const form = new FormData();
        form.append("file", file);
        form.append("domain", domain_id);
        return this.performPost<null>("/import/plausible", form);
    }
}
