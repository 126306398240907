import {BaseEntity} from "@/model/BaseEntity";

export interface EmailReport extends BaseEntity {
    domain_id: string
    email: string
    interval: number
    link_to?: string
    next_report: Date
}

export const emailReportIntervalOptions = [
    {label: "Daily", value: "0"},
    {label: "Weekly", value: "1"},
    {label: "Every two weeks", value: "2"},
    {label: "Every three weeks", value: "3"},
    {label: "Monthly", value: "4"}
];

export const emailReportLinkToOptions = [
    {label: "Private Dashboard (Login Required)", value: ""},
    {label: "Public Dashboard", value: "public"},
    {label: "Custom Domain", value: "custom"}
];
