<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text_0")}}
                <br />
                <a href="https://docs.pirsch.io/get-started/fathom-import" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div v-if="!started">
            <p>{{t("text_1")}}</p>
            <form v-on:submit.prevent="startImport" style="margin-top: 16px;">
                <FormFile :label="t('form.file')" name="file" :hint="t('form.file.hint')" v-model="file" :err="validationError('file')" />
                <FormSubmit :value="t('form.submit')" />
            </form>
        </div>
        <div v-else>
            <p>{{t("text_2")}} <strong>{{t("text_3")}}</strong></p>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, ref} from "vue";
    import {ImportRepo} from "@/repositories/ImportRepo";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import FormFile from "@/components/form/FormFile.vue";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormFile,
            FormSubmit
        },
        setup() {
            const {t} = useI18n();
            const {success, error} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const {setError, resetError, validationError} = useError();
            const loading = ref(false);
            const file = ref(null);
            const started = ref(false);

            async function startImport() {
                try {
                    const f = file.value;

                    if (f) {
                        loading.value = true;
                        resetError();
                        await ImportRepo.fromFathom(domain.value.id, f);
                        loading.value = false;
                        success(t("toast.success"));
                        file.value = null;
                        started.value = true;
                    } else {
                        setError({
                            validation: {
                                file: t("error.file")
                            },
                            error: []
                        });
                    }
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                    error(e as APIError);
                }
            }

            return {
                t,
                validationError,
                file,
                started,
                startImport
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Fathom Analytics Import",
            "text_0": "Import your statistics from Fathom Analytics.",
            "link": "Read the Docs",
            "text_1": "Select the .zip file with your exported statistics as CSV. Once started, the statistics will be imported in the background. Please allow a few minutes for it to complete. You'll receive an email when it's finished.",
            "form.file": "File",
            "form.file.hint": "Max. 100 MB",
            "form.submit": "Start Import",
            "text_2": "Your import has started. It will run in the background. You'll receive an email once it has finished.",
            "text_3": "Please do not start more than one import at a time!",
            "toast.success": "The import has been started.",
            "error.file": "Please select a file."
        },
        "de": {
            "title": "Fathom Analytics Import",
            "text_0": "Importiere deine Statistiken von Fathom Analytics.",
            "link": "Dokumentation lesen",
            "text_1": "Wähle die .zip-Datei mit deinen exportierten Statistiken als CSV aus. Sobald der Import gestartet ist, werden die Statistiken im Hintergrund importiert. Bitte erlaube ein paar Minuten, bis der Vorgang abgeschlossen ist. Du erhältst eine E-Mail, wenn der Import abgeschlossen ist.",
            "form.file": "Datei",
            "form.file.hint": "Max. 100 MB",
            "form.submit": "Import starten",
            "text_2": "Dein Import wurde gestartet. Er wird im Hintergrund ausgeführt. Du erhältst eine E-Mail, sobald er abgeschlossen ist.",
            "text_3": "Bitte starte nicht mehr als einen Import gleichzeitig!",
            "toast.success": "Der Import wurde gestartet.",
            "error.file": "Bitte wähle eine Datei aus."
        },
        "es": {
            "title": "Importación de Fathom Analytics",
            "text_0": "Importa tus estadísticas de Fathom Analytics.",
            "link": "Leer la documentación",
            "text_1": "Selecciona el archivo .zip con tus estadísticas exportadas como CSV. Una vez iniciado, las estadísticas se importarán en segundo plano. Por favor, permite unos minutos para que se complete. Recibirás un correo electrónico cuando haya terminado.",
            "form.file": "Archivo",
            "form.file.hint": "Máx. 100 MB",
            "form.submit": "Iniciar importación",
            "text_2": "Tu importación ha comenzado. Se ejecutará en segundo plano. Recibirás un correo electrónico una vez que haya terminado.",
            "text_3": "¡Por favor, no inicies más de una importación a la vez!",
            "toast.success": "La importación ha comenzado.",
            "error.file": "Por favor, selecciona un archivo."
        },
        "fr": {
            "title": "Importation Fathom Analytics",
            "text_0": "Importez vos statistiques depuis Fathom Analytics.",
            "link": "Lire la documentation",
            "text_1": "Sélectionnez le fichier .zip contenant vos statistiques exportées en tant que CSV. Une fois démarré, les statistiques seront importées en arrière-plan. Veuillez prévoir quelques minutes pour que cela se termine. Vous recevrez un email une fois que ce sera terminé.",
            "form.file": "Fichier",
            "form.file.hint": "Max. 100 Mo",
            "form.submit": "Démarrer l'importation",
            "text_2": "Votre importation a commencé. Elle sera exécutée en arrière-plan. Vous recevrez un email une fois que ce sera terminé.",
            "text_3": "Veuillez ne pas démarrer plus d'une importation à la fois !",
            "toast.success": "L'importation a été lancée.",
            "error.file": "Veuillez sélectionner un fichier."
        },
        "nl": {
            "title": "Fathom Analytics importeren",
            "text_0": "Importeer je statistieken van Fathom Analytics.",
            "link": "Lees de documentatie",
            "text_1": "Selecteer het .zip-bestand met je geëxporteerde statistieken als CSV. Zodra het importeren is gestart, worden de statistieken op de achtergrond geïmporteerd. Dit kan enkele minuten duren. Je ontvangt een e-mail zodra het is voltooid.",
            "form.file": "Bestand",
            "form.file.hint": "Max. 100 MB",
            "form.submit": "Import starten",
            "text_2": "Je import is gestart. Het wordt op de achtergrond uitgevoerd. Je ontvangt een e-mail zodra het is voltooid.",
            "text_3": "Start niet meer dan één import tegelijk!",
            "toast.success": "De import is gestart.",
            "error.file": "Selecteer een bestand."
        },
        "it": {
            "title": "Importazione da Fathom Analytics",
            "text_0": "Importa le tue statistiche da Fathom Analytics.",
            "link": "Leggi la documentazione",
            "text_1": "Seleziona il file .zip con le tue statistiche esportate come CSV. Una volta avviato, le statistiche verranno importate in background. Attendi qualche minuto per completare l'operazione. Riceverai un'email al termine.",
            "form.file": "File",
            "form.file.hint": "Max. 100 MB",
            "form.submit": "Avvia importazione",
            "text_2": "L'importazione è iniziata. Verrà eseguita in background. Riceverai un'email al termine.",
            "text_3": "Per favore, non avviare più di un'importazione alla volta!",
            "toast.success": "L'importazione è iniziata.",
            "error.file": "Seleziona un file."
        },
        "pt": {
            "title": "Importação de Fathom Analytics",
            "text_0": "Importe suas estatísticas do Fathom Analytics.",
            "link": "Leia a documentação",
            "text_1": "Selecione o arquivo .zip com suas estatísticas exportadas como CSV. Uma vez iniciado, as estatísticas serão importadas em segundo plano. Por favor, aguarde alguns minutos para que seja concluído. Você receberá um e-mail quando terminar.",
            "form.file": "Arquivo",
            "form.file.hint": "Máx. 100 MB",
            "form.submit": "Iniciar importação",
            "text_2": "Sua importação foi iniciada. Ela será executada em segundo plano. Você receberá um e-mail quando terminar.",
            "text_3": "Por favor, não inicie mais de uma importação por vez!",
            "toast.success": "A importação foi iniciada.",
            "error.file": "Por favor, selecione um arquivo."
        },
        "ja": {
            "title": "Fathom Analytics インポート",
            "text_0": "Fathom Analytics から統計情報をインポートします。",
            "link": "ドキュメントを読む",
            "text_1": "CSVとしてエクスポートされた統計情報を含む.zipファイルを選択します。開始すると、統計情報はバックグラウンドでインポートされます。完了まで数分かかる場合があります。完了するとメールが届きます。",
            "form.file": "ファイル",
            "form.file.hint": "最大 100 MB",
            "form.submit": "インポートを開始",
            "text_2": "インポートが開始されました。バックグラウンドで実行されます。完了するとメールが届きます。",
            "text_3": "同時に複数のインポートを開始しないでください！",
            "toast.success": "インポートが開始されました。",
            "error.file": "ファイルを選択してください。"
        }
    }
</i18n>
