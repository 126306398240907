import {APIError} from "@/repositories/APIError";
import {Domain} from "@/model/Domain";
import {IdentificationCode} from "@/model/IdentificationCode";
import {AccessLink} from "@/model/AccessLink";
import {getAccessCode} from "@/util/url";
import {DomainAlternative} from "@/model/DomainAlternative";
import {Repository} from "@/repositories/Repository";
import {DashboardSettings} from "@/model/DashboardSettings";
import {KeyValue} from "@/model/KeyValue";
import {ThemeSettings} from "@/store/ThemeStore";

export const DomainRepo = new class extends Repository {
    async list(subdomain: string, domain: string): Promise<Domain[] | APIError> {
        return this.performGet<Domain[]>("/domain", {access: getAccessCode(), subdomain, domain}, []);
    }

    async create(hostname: string, subdomain: string, timezone: string, organization_id: string, theme_id: string): Promise<Domain | APIError> {
        return this.performPost<Domain>("/domain", {hostname, subdomain, timezone, organization_id, theme_id});
    }

    async delete(id: string): Promise<APIError | null> {
        return this.performDelete("/domain", {id});
    }

    async resetIdentificationCode(id: string): Promise<IdentificationCode | APIError> {
        return this.performPut<IdentificationCode>("/domain", {id});
    }

    async updateSubdomain(domain_id: string, subdomain: string): Promise<APIError | null> {
        return this.performPost<null>("/domain/subdomain", {domain_id, subdomain});
    }

    async updateHostname(domain_id: string, hostname: string): Promise<APIError | null> {
        return this.performPost<null>("/domain/hostname", {domain_id, hostname});
    }

    async updateDisplayName(domain_id: string, display_name: string): Promise<APIError | null> {
        return this.performPost<null>("/domain/name", {domain_id, display_name});
    }

    async togglePublic(domain_id: string): Promise<APIError | null> {
        return this.performPost<null>("/domain/public", {domain_id});
    }

    async toggleDisableScripts(domain_id: string): Promise<APIError | null> {
        return this.performPost<null>("/domain/scripts", {domain_id});
    }

    async toggleGroupByTitle(domain_id: string): Promise<APIError | null> {
        return this.performPost<null>("/domain/title", {domain_id});
    }

    async updateActiveVisitors(domain_id: string, time: number): Promise<APIError | null> {
        return this.performPost<null>("/domain/active", {domain_id, time});
    }

    async createAccessLink(domain_id: string, description: string): Promise<AccessLink | APIError> {
        return this.performPost<AccessLink>("/domain/link", {domain_id, description});
    }

    async updateAccessLink(id: string, description: string): Promise<APIError | null> {
        return this.performPut<null>("/domain/link", {id, description});
    }

    async deleteAccessLink(id: string): Promise<APIError | null> {
        return this.performDelete("/domain/link", {id});
    }

    async listAccessLinks(domain_id: string): Promise<AccessLink[] | APIError> {
        return this.performGet<AccessLink[]>("/domain/link", {domain_id}, []);
    }

    async unlinkGoogleAccount(domain_id: string): Promise<APIError | null> {
        return this.performDelete("/google", {domain_id});
    }

    async listGSCDomains(domain_id: string): Promise<string[] | APIError> {
        return this.performGet<string[]>("/gsc", {domain_id}, []);
    }

    async setGSCDomain(domain_id: string, domain: string): Promise<APIError | null> {
        return this.performPost<null>("/gsc", {domain_id, domain});
    }

    async setTimezone(domain_id: string, timezone: string): Promise<APIError | null> {
        return this.performPost<null>("/domain/timezone", {domain_id, timezone});
    }

    async listDomainAlternatives(domain_id: string): Promise<DomainAlternative[] | APIError> {
        return this.performGet<DomainAlternative[]>("/domain/alternative", {domain_id}, []);
    }

    async createDomainAlternative(domain_id: string, hostname: string): Promise<DomainAlternative | APIError> {
        return this.performPost<DomainAlternative>("/domain/alternative", {domain_id, hostname});
    }

    async updateDomainAlternative(id: string, hostname: string): Promise<APIError | null> {
        return this.performPut<null>("/domain/alternative", {id, hostname});
    }

    async deleteDomainAlternative(id: string): Promise<APIError | null> {
        return this.performDelete("/domain/alternative", {id});
    }

    async updateSettings(domain_id: string, settings: KeyValue): Promise<DashboardSettings | APIError> {
        return this.performPost<DashboardSettings>("/domain/settings", {domain_id, settings});
    }

    async setBaseTheme(domain_id: string, theme_id: string): Promise<KeyValue | APIError> {
        return this.performPut<KeyValue>("/domain/theme", {domain_id, theme_id});
    }

    async updateTheme(domain_id: string, settings: KeyValue, logoLight?: File, logoDark?: File, favicon?: File): Promise<Domain | APIError> {
        const form = new FormData();

        if (logoLight) {
            form.append(ThemeSettings.logoLight, logoLight);
        }

        if (logoDark) {
            form.append(ThemeSettings.logoDark, logoDark);
        }

        if (favicon) {
            form.append(ThemeSettings.favicon, favicon);
        }

        form.append("data", JSON.stringify({domain_id, settings}));
        return this.performPost<Domain>("/domain/theme", form);
    }

    async updateCustomDomain(domain_id: string, hostname: string): Promise<APIError | null> {
        return this.performPost("/domain/custom", {domain_id, hostname});
    }

    async toggleTrafficSpikeNotifications(domain_id: string): Promise<APIError | null> {
        return this.performPut("/domain/traffic/spike", {domain_id});
    }

    async setTrafficSpikeNotificationThreshold(domain_id: string, threshold: number): Promise<APIError | null> {
        return this.performPost("/domain/traffic/spike", {domain_id, threshold});
    }

    async toggleTrafficWarningNotifications(domain_id: string): Promise<APIError | null> {
        return this.performPut("/domain/traffic/warning", {domain_id});
    }

    async setTrafficWarningNotificationThreshold(domain_id: string, threshold: number): Promise<APIError | null> {
        return this.performPost("/domain/traffic/warning", {domain_id, threshold});
    }
}
