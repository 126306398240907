<template>
    <Modal :title="t('title')" :err="err" v-on:close="$emit('close')">
        <p>{{t("text")}}</p>
        <div class="input-with-icon">
            <FormInput :label="t('form.email')" name="email" type="email" :autofocus="true" v-model="email" v-on:keydown.enter="addEmail" />
            <i class="icon icon-add clickable" :title="t('form.add')" v-on:click="addEmail"></i>
        </div>
        <div style="min-height: 12px;">
            <div class="item" v-for="e in emails" :key="e">
                <p>
                    <strong>{{e}}</strong>
                </p>
                <i class="icon icon-close clickable" v-on:click="removeEmail(e)"></i>
            </div>
        </div>
        <form v-on:submit.prevent="save">
            <FormSelect :label="t('form.interval')"
                name="interval"
                v-model="interval"
                :options="intervalOptions" />
            <FormSelect :label="t('form.link_to')"
                name="link_to"
                :hint="t('form.link_to.hint')"
                v-model="linkTo"
                :options="reportOptions" />
            <FormSubmit :value="t('form.submit')" :disabled="emails.length === 0" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, ref} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {ReportRepo} from "@/repositories/ReportRepo";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {emailReportIntervalOptions, emailReportLinkToOptions} from "@/model/EmailReport";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            Modal,
            FormSubmit,
            FormInput,
            FormSelect
        },
        emits: ["action", "close"],
        setup(_, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(false);
            const email = ref("");
            const emails = ref<string[]>([]);
            const interval = ref("1");
            const linkTo = ref("");
            const intervalOptions = computed(() => {
                const options = [];

                for (let i = 0; i < emailReportIntervalOptions.length; i++) {
                    options.push({
                        label: t("options.interval."+emailReportIntervalOptions[i].label),
                        value: emailReportIntervalOptions[i].value
                    });
                }

                return options;
            });
            const reportOptions = computed(() => {
                const options = [];

                for (let i = 0; i < emailReportLinkToOptions.length; i++) {
                    options.push({
                        label: t("options.report."+emailReportLinkToOptions[i].label),
                        value: emailReportLinkToOptions[i].value
                    });
                }

                return options;
            });

            function addEmail() {
                email.value = email.value.trim().toLowerCase();

                if (email.value) {
                    for (let i = 0; i < emails.value.length; i++) {
                        if (emails.value[i] === email.value) {
                            email.value = "";
                            return;
                        }
                    }

                    emails.value.push(email.value);
                    email.value = "";
                }
            }

            function removeEmail(email: string) {
                for (let i = 0; i < emails.value.length; i++) {
                    if (emails.value[i] === email) {
                        emails.value.splice(i, 1);
                        break;
                    }
                }
            }

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const reports = await ReportRepo.add(domain.value.id, emails.value, parseInt(interval.value), linkTo.value);
                    success(t("toast.success"));
                    email.value = "";
                    emails.value = [];
                    interval.value = "1";
                    linkTo.value = "";
                    loading.value = false;
                    emit("action", reports);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            return {
                t,
                intervalOptions,
                reportOptions,
                err,
                loading,
                email,
                emails,
                interval,
                linkTo,
                addEmail,
                removeEmail,
                save
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Add Reports",
            "text": "Send weekly or monthly reports for selected email addresses. Click '+' or press ENTER after each one to add them to the list of addresses.",
            "form.email": "Email Address",
            "form.add": "Add Email",
            "form.interval": "Interval",
            "form.link_to": "Link To",
            "form.link_to.hint": "Public dashboards and custom domains must be configured. Otherwise, the report will fall back to the private dashboard.",
            "form.submit": "Add Reports",
            "options.interval.Daily": "Daily",
            "options.interval.Weekly": "Weekly",
            "options.interval.Every two weeks": "Every two weeks",
            "options.interval.Every three weeks": "Every three weeks",
            "options.interval.Monthly": "Monthly",
            "options.report.Private Dashboard (Login Required)": "Private Dashboard (Login Required)",
            "options.report.Public Dashboard": "Public Dashboard",
            "options.report.Custom Domain": "Custom Domain",
            "toast.success": "The reports have been added."
        },
        "de": {
            "title": "Berichte hinzufügen",
            "text": "Sende wöchentliche oder monatliche Berichte an ausgewählte E-Mail-Adressen. Klicke auf '+' oder drücke ENTER nach jeder, um sie der Liste der Adressen hinzuzufügen.",
            "form.email": "E-Mail-Adresse",
            "form.add": "E-Mail hinzufügen",
            "form.interval": "Intervall",
            "form.link_to": "Verknüpfen mit",
            "form.link_to.hint": "Öffentliche Dashboards und benutzerdefinierte Domains müssen konfiguriert sein. Andernfalls wird der Bericht auf das private Dashboard zurückgreifen.",
            "form.submit": "Berichte hinzufügen",
            "options.interval.Daily": "Täglich",
            "options.interval.Weekly": "Wöchentlich",
            "options.interval.Every two weeks": "Alle zwei Wochen",
            "options.interval.Every three weeks": "Alle drei Wochen",
            "options.interval.Monthly": "Monatlich",
            "options.report.Private Dashboard (Login Required)": "Privates Dashboard (Anmeldung erforderlich)",
            "options.report.Public Dashboard": "Öffentliches Dashboard",
            "options.report.Custom Domain": "Benutzerdefinierte Domain",
            "toast.success": "Die Berichte wurden hinzugefügt."
        },
        "es": {
            "title": "Agregar informes",
            "text": "Envía informes semanales o mensuales a las direcciones de correo electrónico seleccionadas. Haz clic en '+' o presiona ENTER después de cada una para agregarlas a la lista de direcciones.",
            "form.email": "Dirección de correo electrónico",
            "form.add": "Agregar correo electrónico",
            "form.interval": "Intervalo",
            "form.link_to": "Enlazar a",
            "form.link_to.hint": "Los paneles públicos y los dominios personalizados deben estar configurados. De lo contrario, el informe recurrirá al panel privado.",
            "form.submit": "Agregar informes",
            "options.interval.Daily": "Diariamente",
            "options.interval.Weekly": "Semanalmente",
            "options.interval.Every two weeks": "Cada dos semanas",
            "options.interval.Every three weeks": "Cada tres semanas",
            "options.interval.Monthly": "Mensualmente",
            "options.report.Private Dashboard (Login Required)": "Panel privado (Inicio de sesión requerido)",
            "options.report.Public Dashboard": "Panel público",
            "options.report.Custom Domain": "Dominio personalizado",
            "toast.success": "Los informes se han agregado."
        },
        "fr": {
            "title": "Ajouter des rapports",
            "text": "Envoyez des rapports hebdomadaires ou mensuels aux adresses e-mail sélectionnées. Cliquez sur '+' ou appuyez sur ENTER après chaque pour les ajouter à la liste des adresses.",
            "form.email": "Adresse e-mail",
            "form.add": "Ajouter un e-mail",
            "form.interval": "Intervalle",
            "form.link_to": "Lien vers",
            "form.link_to.hint": "Les tableaux de bord publics et les domaines personnalisés doivent être configurés. Sinon, le rapport reviendra au tableau de bord privé.",
            "form.submit": "Ajouter des rapports",
            "options.interval.Daily": "Quotidien",
            "options.interval.Weekly": "Hebdomadaire",
            "options.interval.Every two weeks": "Toutes les deux semaines",
            "options.interval.Every three weeks": "Toutes les trois semaines",
            "options.interval.Monthly": "Mensuel",
            "options.report.Private Dashboard (Login Required)": "Tableau de bord privé (Connexion requise)",
            "options.report.Public Dashboard": "Tableau de bord public",
            "options.report.Custom Domain": "Domaine personnalisé",
            "toast.success": "Les rapports ont été ajoutés."
        },
        "nl": {
            "title": "Rapporten toevoegen",
            "text": "Stuur wekelijkse of maandelijkse rapporten naar geselecteerde e-mailadressen. Klik op '+' of druk op ENTER na elk om ze toe te voegen aan de lijst met adressen.",
            "form.email": "E-mailadres",
            "form.add": "E-mail toevoegen",
            "form.interval": "Interval",
            "form.link_to": "Koppelen aan",
            "form.link_to.hint": "Openbare dashboards en aangepaste domeinen moeten worden geconfigureerd. Anders zal het rapport terugvallen op het privé-dashboard.",
            "form.submit": "Rapporten toevoegen",
            "options.interval.Daily": "Dagelijks",
            "options.interval.Weekly": "Wekelijks",
            "options.interval.Every two weeks": "Elke twee weken",
            "options.interval.Every three weeks": "Elke drie weken",
            "options.interval.Monthly": "Maandelijks",
            "options.report.Private Dashboard (Login Required)": "Privé-dashboard (inloggen vereist)",
            "options.report.Public Dashboard": "Openbaar dashboard",
            "options.report.Custom Domain": "Aangepast domein",
            "toast.success": "De rapporten zijn toegevoegd."
        },
        "it": {
            "title": "Aggiungi rapporti",
            "text": "Invia rapporti settimanali o mensili agli indirizzi email selezionati. Fai clic su '+' o premi INVIO dopo ciascuno per aggiungerli all'elenco degli indirizzi.",
            "form.email": "Indirizzo email",
            "form.add": "Aggiungi email",
            "form.interval": "Intervallo",
            "form.link_to": "Collega a",
            "form.link_to.hint": "I dashboard pubblici e i domini personalizzati devono essere configurati. In caso contrario, il rapporto tornerà al dashboard privato.",
            "form.submit": "Aggiungi rapporti",
            "options.interval.Daily": "Quotidiano",
            "options.interval.Weekly": "Settimanale",
            "options.interval.Every two weeks": "Ogni due settimane",
            "options.interval.Every three weeks": "Ogni tre settimane",
            "options.interval.Monthly": "Mensile",
            "options.report.Private Dashboard (Login Required)": "Dashboard privato (accesso richiesto)",
            "options.report.Public Dashboard": "Dashboard pubblico",
            "options.report.Custom Domain": "Dominio personalizzato",
            "toast.success": "I rapporti sono stati aggiunti."
        },
        "pt": {
            "title": "Adicionar relatórios",
            "text": "Envie relatórios semanais ou mensais para endereços de e-mail selecionados. Clique em '+' ou pressione ENTER após cada um para adicioná-los à lista de endereços.",
            "form.email": "Endereço de e-mail",
            "form.add": "Adicionar e-mail",
            "form.interval": "Intervalo",
            "form.link_to": "Vincular a",
            "form.link_to.hint": "Painéis públicos e domínios personalizados devem ser configurados. Caso contrário, o relatório voltará ao painel privado.",
            "form.submit": "Adicionar relatórios",
            "options.interval.Daily": "Diariamente",
            "options.interval.Weekly": "Semanalmente",
            "options.interval.Every two weeks": "A cada duas semanas",
            "options.interval.Every three weeks": "A cada três semanas",
            "options.interval.Monthly": "Mensalmente",
            "options.report.Private Dashboard (Login Required)": "Painel privado (login obrigatório)",
            "options.report.Public Dashboard": "Painel público",
            "options.report.Custom Domain": "Domínio personalizado",
            "toast.success": "Os relatórios foram adicionados."
        },
        "ja": {
            "title": "レポートを追加",
            "text": "選択したメールアドレスに毎週または毎月のレポートを送信します。各メールアドレスの後に「+」をクリックするか、ENTERキーを押して、アドレスリストに追加します。",
            "form.email": "メールアドレス",
            "form.add": "メールを追加",
            "form.interval": "インターバル",
            "form.link_to": "リンク先",
            "form.link_to.hint": "公開ダッシュボードとカスタムドメインを設定する必要があります。それ以外の場合、レポートはプライベートダッシュボードにフォールバックします。",
            "form.submit": "レポートを追加",
            "options.interval.Daily": "毎日",
            "options.interval.Weekly": "毎週",
            "options.interval.Every two weeks": "2週間ごと",
            "options.interval.Every three weeks": "3週間ごと",
            "options.interval.Monthly": "毎月",
            "options.report.Private Dashboard (Login Required)": "プライベートダッシュボード（ログイン必須）",
            "options.report.Public Dashboard": "公開ダッシュボード",
            "options.report.Custom Domain": "カスタムドメイン",
            "toast.success": "レポートが追加されました。"
        }
    }
</i18n>
