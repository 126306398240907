import {defineStore, storeToRefs} from "pinia";
import {computed, Ref, ref, watch} from "vue";
import {ActiveVisitorsData} from "@/model/ActiveVisitorsData";
import {StatisticsRepo} from "@/repositories/StatisticsRepo";
import {Growth} from "@/model/Growth";
import {Keyword} from "@/model/Keyword";
import {VisitorHourStats} from "@/model/VisitorHourStats";
import {VisitorStats} from "@/model/VisitorStats";
import {LanguageStats} from "@/model/LanguageStats";
import {ReferrerStats} from "@/model/ReferrerStats";
import {BrowserStats} from "@/model/BrowserStats";
import {OSStats} from "@/model/OSStats";
import {PageStats} from "@/model/PageStats";
import {PlatformStats} from "@/model/PlatformStats";
import {CountryStats} from "@/model/CountryStats";
import {ScreenClassStats} from "@/model/ScreenClassStats";
import {TimeSpentStats} from "@/model/TimeSpentStats";
import {UTMSourceStats} from "@/model/UTMSourceStats";
import {UTMCampaignStats} from "@/model/UTMCampaignStats";
import {UTMMediumStats} from "@/model/UTMMediumStats";
import {Filter} from "@/model/Filter";
import {UTMTermStats} from "@/model/UTMTermStats";
import {UTMContentStats} from "@/model/UTMContentStats";
import {EntryStats} from "@/model/EntryStats";
import {ExitStats} from "@/model/ExitStats";
import {OSVersionStats} from "@/model/OSVersionStats";
import {BrowserVersionStats} from "@/model/BrowserVersionStats";
import {ConversionGoalStats} from "@/model/ConversionGoalStats";
import {EventStats} from "@/model/EventStats";
import {CityStats} from "@/model/CityStats";
import {TotalVisitorStats} from "@/model/TotalVisitorStats";
import {hasField, isDailyFilter, isHourlyFilter, useFilterStore} from "@/store/FilterStore";
import {useDomainStore} from "@/store/DomainStore";
import {getFavicon} from "@/util/favicon";
import {whereAlpha2} from "iso-3166-1";
import {hasFlag} from "country-flag-icons";
import ISO6391 from "iso-639-1";
import {TagStats} from "@/model/TagStats";
import {getPreviousPeriod} from "@/util/date";
import {useRouter} from "vue-router";
import {VisitorMinuteStats} from "@/model/VisitorMinuteStats";
import {Session} from "@/model/Session";
import {RegionStats} from "@/model/RegionStats";
import {Funnel} from "@/model/Funnel";
import {FunnelRepo} from "@/repositories/FunnelRepo";

export const entries_per_page = 100;

const osIcons = new Map;
osIcons.set("Mac", "/img/os/macos.svg");
osIcons.set("iOS", "/img/os/ios.svg");
osIcons.set("Windows", "/img/os/windows.svg");
osIcons.set("Linux", "/img/os/linux.svg");
osIcons.set("Android", "/img/os/android.svg");
osIcons.set("Chrome OS", "/img/browser/chrome.svg");

const browserIcons = new Map;
browserIcons.set("Chrome", "/img/browser/chrome.svg");
browserIcons.set("Firefox", "/img/browser/firefox.svg");
browserIcons.set("Safari", "/img/browser/safari.svg");
browserIcons.set("Edge", "/img/browser/edge.svg");
browserIcons.set("Opera", "/img/browser/opera.svg");
browserIcons.set("IE", "/img/browser/ie.svg");
browserIcons.set("Android WebView", "/img/os/android.svg");
browserIcons.set("Brave", "/img/browser/brave.svg");
browserIcons.set("Arc", "/img/browser/arc.svg");
browserIcons.set("Samsung Internet", "/img/browser/samsung.svg");
browserIcons.set("Whale", "/img/browser/whale.svg");

const screenSizes = new Map;
screenSizes.set("XS", "XS (< 415px)");
screenSizes.set("S", "S (< 600px)");
screenSizes.set("M", "M (< 800px)");
screenSizes.set("L", "L (< 1024px)");
screenSizes.set("XL", "XL (< 1280px)");
screenSizes.set("XXL", "HD (< 1920px)"); // before migration
screenSizes.set("HD", "HD (< 1920px)");
screenSizes.set("Full HD", "Full HD (< 2560px)");
screenSizes.set("WQHD", "WQHD (< 3840px)");
screenSizes.set("UHD 4K", "UHD 4K (< 5120px)");
screenSizes.set("UHD 5K", "UHD 5K (> 5119px)");

export const useStatisticsStore = defineStore("statistics", () => {
    const router = useRouter();
    const domainStore = useDomainStore();
    const filterStore = useFilterStore();
    const {domain} = storeToRefs(domainStore);
    const {filter, comparison, hourFilter, dayFilter} = storeToRefs(filterStore);
    const {hasField: hasFilterField} = filterStore;

    const loading = ref<Map<string, boolean>>(new Map);
    const more = ref<Map<string, boolean>>(new Map);

    const activeVisitorsRaw = ref<ActiveVisitorsData>();
    const visitorsByHourRaw = ref<VisitorHourStats[]>([]);
    const visitorsByMinuteRaw = ref<VisitorMinuteStats[]>([]);
    const totalVisitorsRaw = ref<TotalVisitorStats>();
    const visitorsRaw = ref<VisitorStats[]>([]);
    const growthRaw = ref<Growth>();
    const averageTimeSpentRaw = ref<TimeSpentStats[]>([]);

    const languagesRaw = ref<LanguageStats[]>([]);
    const referrerRaw = ref<ReferrerStats[]>([]);
    const browserRaw = ref<BrowserStats[]>([]);
    const browserVersionsRaw = ref<BrowserVersionStats[]>([]);
    const osRaw = ref<OSStats[]>([]);
    const osVersionsRaw = ref<OSVersionStats[]>([]);
    const pagesRaw = ref<PageStats[]>([]);
    const eventPagesRaw = ref<PageStats[]>([]);
    const entryPagesRaw = ref<EntryStats[]>([]);
    const exitPagesRaw = ref<ExitStats[]>([]);
    const platformRaw = ref<PlatformStats>();
    const countriesRaw = ref<CountryStats[]>([]);
    const regionsRaw = ref<RegionStats[]>([]);
    const citiesRaw = ref<CityStats[]>([]);
    const screensRaw = ref<ScreenClassStats[]>([]);
    const keywordsRaw = ref<Keyword[]>([]);
    const utmSourceRaw = ref<UTMSourceStats[]>([]);
    const utmMediumRaw = ref<UTMMediumStats[]>([]);
    const utmCampaignRaw = ref<UTMCampaignStats[]>([]);
    const utmContentRaw = ref<UTMContentStats[]>([]);
    const utmTermRaw = ref<UTMTermStats[]>([]);
    const conversionGoalsRaw = ref<ConversionGoalStats[]>([]);
    const eventsRaw = ref<EventStats[]>([]);
    const tagsRaw = ref<TagStats[]>([]);
    const sessionsRaw = ref<Session[]>([]);
    const sessionsLimit = ref(false);
    const funnelsRaw = ref<Funnel[]>([]);

    const topLanguagesRaw = ref<LanguageStats[]>([]);
    const topReferrerRaw = ref<ReferrerStats[]>([]);
    const topPagesRaw = ref<PageStats[]>([]);
    const topEventPagesRaw = ref<PageStats[]>([]);
    const topEntryPagesRaw = ref<EntryStats[]>([]);
    const topExitPagesRaw = ref<ExitStats[]>([]);
    const topCountriesRaw = ref<CountryStats[]>([]);
    const topRegionsRaw = ref<RegionStats[]>([]);
    const topCitiesRaw = ref<CityStats[]>([]);
    const topKeywordsRaw = ref<Keyword[]>([]);
    const topUTMSourceRaw = ref<UTMSourceStats[]>([]);
    const topUTMMediumRaw = ref<UTMMediumStats[]>([]);
    const topUTMCampaignRaw = ref<UTMCampaignStats[]>([]);
    const topUTMContentRaw = ref<UTMContentStats[]>([]);
    const topUTMTermRaw = ref<UTMTermStats[]>([]);
    const topConversionGoalsRaw = ref<ConversionGoalStats[]>([]);
    const topEventsRaw = ref<EventStats[]>([]);
    const topTagsRaw = ref<TagStats[]>([]);

    const previousVisitorsByHourRaw = ref<VisitorHourStats[]>([]);
    const previousVisitorsByMinuteRaw = ref<VisitorMinuteStats[]>([]);
    const previousTotalVisitorsRaw = ref<TotalVisitorStats>();
    const previousVisitorsRaw = ref<VisitorStats[]>([]);
    const previousAverageTimeSpentRaw = ref<TimeSpentStats[]>([]);

    let initialized = false;

    watch(() => router.currentRoute.value.meta, (meta, previousMeta) => {
        if (!initialized && meta.loadStatistics ||
            meta.loadPanels !== undefined && previousMeta.loadPanels !== undefined && meta.loadPanels !== previousMeta.loadPanels ||
            meta.loadSessions !== undefined && previousMeta.loadSessions !== undefined && meta.loadSessions !== previousMeta.loadSessions) {
            void loadAll();
        }
    });

    watch([domain, filter], ([domain], [previousDomain]) => {
        void loadAll(domain.id !== previousDomain.id);
    });

    watch(domain, () => {
        if (domain.value && domain.value.id && filter.value.client_id && domain.value.gsc_domain) {
            void loadKeywords(filter.value);
        }
    });

    watch(filter, (oldFilter, newFilter) => {
        if (filter.value.client_id && domain.value.gsc_domain &&
            (oldFilter.timeRangeIndex !== newFilter.timeRangeIndex ||
            oldFilter.timeRange != newFilter.timeRange ||
            oldFilter.timeRange?.from !== newFilter.timeRange?.from ||
            oldFilter.timeRange?.to !== newFilter.timeRange?.to)) {
            void loadKeywords(filter.value);
        }
    });

    watch(comparison, loadPrevious);

    async function loadAll(enforce = false) {
        if (enforce || filter.value.client_id && domain.value && domain.value.id && router.currentRoute.value.meta.loadStatistics) {
            if (filter.value.client_id !== domain.value.id) {
                filter.value.client_id = domain.value.id;
            }

            if (hourFilter.value) {
                void loadVisitorsByMinute("visitors_by_minute", visitorsByMinuteRaw, filter.value);
            } else if (dayFilter.value) {
                void loadVisitorsByHour("visitors_by_hour", visitorsByHourRaw, filter.value);
            } else {
                void loadVisitors("visitors", visitorsRaw, filter.value);
            }

            void loadAverageTimeSpent("average_time_spent", averageTimeSpentRaw, filter.value);
            void loadGrowth(filter.value);
            await loadTotalVisitors("total_visitors", totalVisitorsRaw, filter.value);

            if (enforce || router.currentRoute.value.meta.loadPanels) {
                void loadPages(filter.value);
                void loadEntryPages(filter.value);
                void loadExitPages(filter.value);
                void loadEventPages(filter.value);
                void loadReferrer(filter.value);
                void loadUTMSource(filter.value);
                void loadUTMMedium(filter.value);
                void loadUTMCampaign(filter.value);
                void loadUTMTerm(filter.value);
                void loadUTMContent(filter.value);
                void loadConversionGoals(filter.value);
                void loadEvents(filter.value);
                void loadTags(filter.value);
                void loadCountries(filter.value);
                void loadRegions(filter.value);
                void loadCities(filter.value);
                void loadLanguages(filter.value);
                void loadOS(filter.value);
                void loadBrowser(filter.value);
                void loadPlatform(filter.value);
                void loadScreens(filter.value);
            }

            if (enforce || router.currentRoute.value.meta.loadSessions &&
                totalVisitorsRaw.value &&
                totalVisitorsRaw.value.sessions <= 200) {
                void loadSessions(filter.value);
                sessionsLimit.value = false;
            } else {
                sessionsLimit.value = true;
            }

            loadPrevious();
            initialized = true;
        }
    }

    function loadPrevious() {
        if (filter.value.client_id && domain.value && domain.value.id &&
            (comparison.value.compare === "previous" ||
            comparison.value.compare === "year" ||
            (comparison.value.compare === "custom" && comparison.value.compareRange?.from && comparison.value.compareRange?.to))) {
            const f = filterStore.copy(filter.value);

            if (f.client_id !== domain.value.id) {
                f.client_id = domain.value.id;
            }

            if (comparison.value.compare === "custom" && comparison.value.compareRange) {
                f.timeRange = {
                    from: comparison.value.compareRange.from,
                    to: comparison.value.compareRange.to,
                    start: 0
                };
            } else if ((comparison.value.compare === "previous" || comparison.value.compare === "year") && f.timeRange) {
                const {previousStart, previousEnd} = getPreviousPeriod(f.timeRange.from, f.timeRange.to, comparison.value.compare, comparison.value.compareWeekday === true);
                f.timeRange = {
                    from: previousStart,
                    to: previousEnd,
                    start: 0
                };
            } else {
                return;
            }

            if (filter.value.timeRange) {
                f.timeRange.fromTime = filter.value.timeRange.fromTime;
                f.timeRange.toTime = filter.value.timeRange.toTime;
            }

            if (isHourlyFilter(f)) {
                void loadVisitorsByMinute("previous_visitors_by_minute", previousVisitorsByMinuteRaw, f);
            } else if (isDailyFilter(f)) {
                void loadVisitorsByHour("previous_visitors_by_hour", previousVisitorsByHourRaw, f);
            } else {
                void loadVisitors("previous_visitors", previousVisitorsRaw, f);
            }

            void loadAverageTimeSpent("previous_average_time_spent", previousAverageTimeSpentRaw, f);
            void loadTotalVisitors("previous_total_visitors", previousTotalVisitorsRaw, f);
        } else {
            previousVisitorsByMinuteRaw.value = [];
            previousVisitorsByHourRaw.value = [];
            previousVisitorsRaw.value = [];
            previousAverageTimeSpentRaw.value = [];
            previousTotalVisitorsRaw.value = undefined;
        }
    }

    async function loadActiveVisitors() {
        if (domainStore.domain && domainStore.domain.id) {
            const stats = await StatisticsRepo.activeVisitors(domainStore.domain.id);
            activeVisitorsRaw.value = stats as ActiveVisitorsData;
        }

        return Promise.resolve(null);
    }

    async function loadTotalVisitors(loadingKey: string, raw: Ref<TotalVisitorStats | undefined>, filter: Filter) {
        loading.value.set(loadingKey, true);
        const stats = await StatisticsRepo.totalVisitors(filter, loadingKey);
        raw.value = stats as TotalVisitorStats;
        loading.value.set(loadingKey, false);
        return Promise.resolve(null);
    }

    async function loadVisitors(loadingKey: string, raw: Ref<VisitorStats[]>, filter: Filter) {
        loading.value.set(loadingKey, true);
        const stats = await StatisticsRepo.visitors(filter, loadingKey);
        raw.value = stats as VisitorStats[];
        loading.value.set(loadingKey, false);
        return Promise.resolve(null);
    }

    async function loadVisitorsByHour(loadingKey: string, raw: Ref<VisitorHourStats[]>, filter: Filter) {
        loading.value.set(loadingKey, true);
        const stats = await StatisticsRepo.visitorsByHour(filter, loadingKey);
        raw.value = stats as VisitorHourStats[];
        loading.value.set(loadingKey, false);
        return Promise.resolve(null);
    }

    async function loadVisitorsByMinute(loadingKey: string, raw: Ref<VisitorMinuteStats[]>, filter: Filter) {
        loading.value.set(loadingKey, true);
        const stats = await StatisticsRepo.visitorsByMinute(filter, loadingKey);
        raw.value = stats as VisitorMinuteStats[];
        loading.value.set(loadingKey, false);
        return Promise.resolve(null);
    }

    async function loadGrowth(filter: Filter) {
        loading.value.set("growth", true);
        const stats = await StatisticsRepo.growth(filter);
        growthRaw.value = stats as Growth;
        loading.value.set("growth", false);
        return Promise.resolve(null);
    }

    async function loadAverageTimeSpent(loadingKey: string, raw: Ref<TimeSpentStats[]>, filter: Filter) {
        loading.value.set(loadingKey, true);
        let stats;

        if (hasField(filter, "path")) {
            stats = await StatisticsRepo.averageTimeOnPge(filter, loadingKey) as TimeSpentStats[];
        } else {
            stats = await StatisticsRepo.averageSessionDuration(filter, loadingKey) as TimeSpentStats[];
        }

        raw.value = stats;
        loading.value.set(loadingKey, false);
        return Promise.resolve(null);
    }

    async function loadLanguages(filter: Filter, top = true) {
        loading.value.set(top ? "top_languages" : "languages", true);
        const stats = await StatisticsRepo.languages(filter) as LanguageStats[];
        setRawData(filter, top ? "top_languages" : "languages", stats, top ? topLanguagesRaw : languagesRaw);
        loading.value.set(top ? "top_languages" : "languages", false);
        return Promise.resolve(null);
    }

    async function loadReferrer(filter: Filter, top = true) {
        loading.value.set(top ? "top_referrer" : "referrer", true);
        const stats = await StatisticsRepo.referrer(filter) as ReferrerStats[];
        setRawData(filter, top ? "top_referrer" : "referrer", stats, top ? topReferrerRaw : referrerRaw);
        loading.value.set(top ? "top_referrer" : "referrer", false);
        return Promise.resolve(null);
    }

    async function loadBrowser(filter: Filter) {
        loading.value.set("browser", true);
        const stats = await StatisticsRepo.browser(filter) as BrowserStats[];
        setRawData(filter, "browser", stats, browserRaw);
        loading.value.set("browser", false);
        return Promise.resolve(null);
    }

    async function loadBrowserVersions(filter: Filter) {
        loading.value.set("browser_versions", true);
        const stats = await StatisticsRepo.browserVersions(filter) as BrowserVersionStats[];
        setRawData(filter, "browser_versions", stats, browserVersionsRaw);
        loading.value.set("browser_versions", false);
        return Promise.resolve(null);
    }

    async function loadOS(filter: Filter) {
        loading.value.set("os", true);
        const stats = await StatisticsRepo.os(filter) as OSStats[];
        setRawData(filter, "os", stats, osRaw);
        loading.value.set("os", false);
        return Promise.resolve(null);
    }

    async function loadOSVersions(filter: Filter) {
        loading.value.set("os_versions", true);
        const stats = await StatisticsRepo.osVersions(filter) as OSVersionStats[];
        setRawData(filter, "os_versions", stats, osVersionsRaw);
        loading.value.set("os_versions", false);
        return Promise.resolve(null);
    }

    async function loadPages(filter: Filter, top = true) {
        loading.value.set(top ? "top_pages" : "pages", true);
        const stats = await StatisticsRepo.pages(filter) as PageStats[];
        setRawData(filter, top ? "top_pages" : "pages", stats, top ? topPagesRaw : pagesRaw);
        loading.value.set(top ? "top_pages" : "pages", false);
        return Promise.resolve(null);
    }

    async function loadEventPages(filter: Filter, top = true) {
        loading.value.set(top ? "top_event_pages" : "event_pages", true);
        const stats = await StatisticsRepo.eventPages(filter) as PageStats[];
        setRawData(filter, top ? "top_event_pages" : "event_pages", stats, top ? topEventPagesRaw : eventPagesRaw);
        loading.value.set(top ? "top_event_pages" : "event_pages", false);
        return Promise.resolve(null);
    }

    async function loadEntryPages(filter: Filter, top = true) {
        loading.value.set(top ? "top_entry_pages" : "entry_pages", true);
        const stats = await StatisticsRepo.entryPages(filter) as EntryStats[];
        setRawData(filter, top ? "top_entry_pages" : "entry_pages", stats, top ? topEntryPagesRaw : entryPagesRaw);
        loading.value.set(top ? "top_entry_pages" : "entry_pages", false);
        return Promise.resolve(null);
    }

    async function loadExitPages(filter: Filter, top = true) {
        loading.value.set(top ? "top_exit_pages" : "exit_pages", true);
        const stats = await StatisticsRepo.exitPages(filter) as ExitStats[];
        setRawData(filter, top ? "top_exit_pages" : "exit_pages", stats, top ? topExitPagesRaw : exitPagesRaw);
        loading.value.set(top ? "top_exit_pages" : "exit_pages", false);
        return Promise.resolve(null);
    }

    async function loadPlatform(filter: Filter) {
        loading.value.set("platform", true);
        const stats = await StatisticsRepo.platform(filter);
        platformRaw.value = stats as PlatformStats;
        loading.value.set("platform", false);
        return Promise.resolve(null);
    }

    async function loadCountries(filter: Filter, top = true) {
        loading.value.set(top ? "top_countries" : "countries", true);
        const stats = await StatisticsRepo.country(filter) as CountryStats[];
        setRawData(filter, top ? "top_countries" : "countries", stats, top ? topCountriesRaw : countriesRaw);
        loading.value.set(top ? "top_countries" : "countries", false);
        return Promise.resolve(null);
    }

    async function loadRegions(filter: Filter, top = true) {
        loading.value.set(top ? "top_regions" : "regions", true);
        const stats = await StatisticsRepo.region(filter) as RegionStats[];
        setRawData(filter, top ? "top_regions" : "regions", stats, top ? topRegionsRaw : regionsRaw);
        loading.value.set(top ? "top_regions" : "regions", false);
        return Promise.resolve(null);
    }

    async function loadCities(filter: Filter, top = true) {
        loading.value.set(top ? "top_cities" : "cities", true);
        const stats = await StatisticsRepo.city(filter) as CityStats[];
        setRawData(filter, top ? "top_cities" : "cities", stats, top ? topCitiesRaw : citiesRaw);
        loading.value.set(top ? "top_cities" : "cities", false);
        return Promise.resolve(null);
    }

    async function loadScreens(filter: Filter) {
        loading.value.set("screens", true);
        const stats = await StatisticsRepo.screen(filter);
        screensRaw.value = stats as ScreenClassStats[];
        loading.value.set("screens", false);
        return Promise.resolve(null);
    }

    async function loadKeywords(filter: Filter, top = true) {
        try {
            loading.value.set(top ? "top_keywords" : "keywords", true);
            const keywords = await StatisticsRepo.keywords(filter) as Keyword[];
            setRawData(filter, top ? "top_keywords" : "keywords", keywords, top ? topKeywordsRaw : keywordsRaw);
            loading.value.set(top ? "top_keywords" : "keywords", false);
        } catch (e) {
            keywordsRaw.value = [];
        }

        return Promise.resolve(null);
    }

    async function loadUTMSource(filter: Filter, top = true) {
        loading.value.set(top ? "top_utm_source" : "utm_source", true);
        const stats = await StatisticsRepo.utmSource(filter) as UTMSourceStats[];
        setRawData(filter, top ? "top_utm_source" : "utm_source", stats, top ? topUTMSourceRaw : utmSourceRaw);
        loading.value.set(top ? "top_utm_source" : "utm_source", false);
        return Promise.resolve(null);
    }

    async function loadUTMMedium(filter: Filter, top = true) {
        loading.value.set(top ? "top_utm_medium" : "utm_medium", true);
        const stats = await StatisticsRepo.utmMedium(filter) as UTMMediumStats[];
        setRawData(filter, top ? "top_utm_medium" : "utm_medium", stats, top ? topUTMMediumRaw : utmMediumRaw);
        loading.value.set(top ? "top_utm_medium" : "utm_medium", false);
        return Promise.resolve(null);
    }

    async function loadUTMCampaign(filter: Filter, top = true) {
        loading.value.set(top ? "top_utm_campaign" : "utm_campaign", true);
        const stats = await StatisticsRepo.utmCampaign(filter) as UTMCampaignStats[];
        setRawData(filter, top ? "top_utm_campaign" : "utm_campaign", stats, top ? topUTMCampaignRaw : utmCampaignRaw)
        loading.value.set(top ? "top_utm_campaign" : "utm_campaign", false);
        return Promise.resolve(null);
    }

    async function loadUTMContent(filter: Filter, top = true) {
        loading.value.set(top ? "top_utm_content" : "utm_content", true);
        const stats = await StatisticsRepo.utmContent(filter) as UTMContentStats[];
        setRawData(filter, top ? "top_utm_content" : "utm_content", stats, top ? topUTMContentRaw : utmContentRaw);
        loading.value.set(top ? "top_utm_content" : "utm_content", false);
        return Promise.resolve(null);
    }

    async function loadUTMTerm(filter: Filter, top = true) {
        loading.value.set(top ? "top_utm_term" : "utm_term", true);
        const stats = await StatisticsRepo.utmTerm(filter) as UTMTermStats[];
        setRawData(filter, top ? "top_utm_term" : "utm_term", stats, top ? topUTMTermRaw : utmTermRaw);
        loading.value.set(top ? "top_utm_term" : "utm_term", false);
        return Promise.resolve(null);
    }

    async function loadConversionGoals(filter: Filter, top = true) {
        loading.value.set(top ? "top_goals" : "goals", true);
        const stats = await StatisticsRepo.conversionGoals(filter) as ConversionGoalStats[];
        setRawData(filter, top ? "top_goals" : "goals", stats, top ? topConversionGoalsRaw : conversionGoalsRaw);
        loading.value.set(top ? "top_goals" : "goals", false);
        return Promise.resolve(null);
    }

    async function loadEvents(filter: Filter, top = true) {
        loading.value.set(top ? "top_events" : "events", true);
        const stats = await StatisticsRepo.events(filter) as EventStats[];
        setRawData(filter, top ? "top_events" : "events", stats, top ? topEventsRaw : eventsRaw);
        loading.value.set(top ? "top_events" : "events", false);
        return Promise.resolve(null);
    }

    async function loadTags(filter: Filter, top = true) {
        loading.value.set(top ? "top_tags" : "tags", true);
        const stats = await StatisticsRepo.tagKeys(filter) as TagStats[];
        setRawData(filter, top ? "top_tags" : "tags", stats, top ? topTagsRaw : tagsRaw);
        loading.value.set(top ? "top_tags" : "tags", false);
        return Promise.resolve(null);
    }

    async function loadSessions(filter: Filter) {
        loading.value.set("sessions", true);
        const stats = await StatisticsRepo.sessions(filter) as Session[];
        setRawData(filter, "sessions", stats, sessionsRaw);
        loading.value.set("sessions", false);
        return Promise.resolve(null);
    }

    async function loadFunnels(filter: Filter) {
        if (filter.client_id) {
            loading.value.set("funnels", true);
            const stats = await FunnelRepo.list(filter.client_id) as Funnel[];
            setRawData(filter, "funnels", stats, funnelsRaw);
            loading.value.set("funnels", false);
        }

        return Promise.resolve(null);
    }

    function setRawData<T>(filter: Filter, key: string, stats: Array<T>, data: Ref<Array<T>>) {
        if (filter.offset) {
            more.value.set(key, stats.length >= entries_per_page);
            data.value = data.value.concat(stats);
        } else {
            more.value.set(key, filter.offset !== undefined && stats.length >= entries_per_page);
            data.value = stats;
        }
    }

    const isLoading = computed(() => (key: string) => !!loading.value.get(key));
    const loadMore = computed(() => (key: string) => !!more.value.get(key));

    const activeVisitors = computed(() => activeVisitorsRaw.value || {stats: [], countries: [], visitors: 0});

    const activePages = computed(() => {
        const hostname = domainStore.domain.hostname;
        const raw = activeVisitorsRaw.value;
        const data = [];

        if (raw && raw.stats) {
            for (let i = 0; i < raw.stats.length; i++) {
                let label = raw.stats[i].path;

                if (domainStore.domain.group_by_title) {
                    label = `${raw.stats[i].title} - ${raw.stats[i].path}`;
                }

                data.push({
                    label,
                    value: raw.stats[i].visitors,
                    url: `https://${hostname}${raw.stats[i].path}`
                });
            }
        }

        return data;
    });

    const activeCountries = computed(() => {
        const raw = activeVisitorsRaw.value?.countries ?? [];
        const data = [];

        for (let i = 0; i < raw.length; i++) {
            raw[i].country_code = getCountryCode(raw[i].country_code);
            const country = getCountry(raw[i].country_code);
            data.push({
                label: country ? country.country : "",
                value: raw[i].visitors
            });
        }

        return data;
    });

    const visitorsByHour = computed(() => visitorsByHourRaw.value || []);

    const visitorsByMinute = computed(() => visitorsByMinuteRaw.value || []);

    const totalVisitors = computed(() => totalVisitorsRaw.value || {
        visitors: 0,
        views: 0,
        sessions: 0,
        bounces: 0,
        bounce_rate: 0,
        cr: 0,
        custom_metric_avg: 0,
        custom_metric_total: 0
    });

    const visitors = computed(() => visitorsRaw.value || []);

    const growth = computed(() => growthRaw.value);

    const averageTimeSpent = computed(() => averageTimeSpentRaw.value || []);

    const languages = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? languagesRaw.value : topLanguagesRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                data.push({
                    filter: rawData[i].language,
                    label: ISO6391.getName(rawData[i].language) || "",
                    value: rawData[i].visitors,
                    relative_value: rawData[i].relative_visitors
                });
            }

            return data;
        };
    });

    const referrer = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? referrerRaw.value : topReferrerRaw.value;
            const data = [];
            const referrerName = hasFilterField("referrer_name");

            for (let i = 0; i < rawData.length; i++) {
                const label = referrerName ? trimProtocol(rawData[i].referrer) : rawData[i].referrer_name;
                data.push({
                    key: `${rawData[i].referrer_name}-${rawData[i].referrer}`,
                    filter: hasFilterField("referrer_name") ? rawData[i].referrer : label,
                    icon: rawData[i].referrer_icon ? rawData[i].referrer_icon : getFavicon(rawData[i].referrer),
                    label,
                    label_raw: referrerName ? rawData[i].referrer : rawData[i].referrer_name,
                    value: rawData[i].visitors,
                    relative_value: rawData[i].relative_visitors,
                    bounce_rate: rawData[i].bounce_rate,
                    url: isURL(rawData[i].referrer) ? rawData[i].referrer : ""
                });
            }

            return data;
        };
    });

    const browser = computed(() => {
        return () => {
            const data = [];

            for (let i = 0; i < browserRaw.value.length && i < 10; i++) {
                data.push({
                    icon: selectBrowserIcon(browserRaw.value[i].browser),
                    label: browserRaw.value[i].browser,
                    value: browserRaw.value[i].visitors,
                    relative_value: browserRaw.value[i].relative_visitors
                });
            }

            return data;
        };
    });

    const browserVersions = computed(() => {
        return () => {
            const data = [];

            for (let i = 0; i < browserVersionsRaw.value.length; i++) {
                data.push({
                    icon: selectBrowserIcon(browserVersionsRaw.value[i].browser),
                    key: browserVersionsRaw.value[i].browser+browserVersionsRaw.value[i].browser_version,
                    label: browserVersionsRaw.value[i].browser,
                    version: browserVersionsRaw.value[i].browser_version,
                    value: browserVersionsRaw.value[i].visitors,
                    relative_value: browserVersionsRaw.value[i].relative_visitors
                });
            }

            return data;
        };
    });

    const os = computed(() => {
        return () => {
            const data = [];

            for (let i = 0; i < osRaw.value.length; i++) {
                data.push({
                    icon: selectOSIcon(osRaw.value[i].os),
                    label: osRaw.value[i].os,
                    value: osRaw.value[i].visitors,
                    relative_value: osRaw.value[i].relative_visitors
                });
            }

            return data;
        };
    });

    const osVersions = computed(() => {
        return () => {
            const data = [];

            for (let i = 0; i < osVersionsRaw.value.length; i++) {
                data.push({
                    icon: selectOSIcon(osVersionsRaw.value[i].os),
                    key: osVersionsRaw.value[i].os + osVersionsRaw.value[i].os_version,
                    label: osVersionsRaw.value[i].os,
                    version: osVersionsRaw.value[i].os_version,
                    value: osVersionsRaw.value[i].visitors,
                    relative_value: osVersionsRaw.value[i].relative_visitors
                });
            }

            return data;
        };
    });

    const pages = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? pagesRaw.value : topPagesRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                let label = rawData[i].path;

                if (domainStore.domain.group_by_title && limitless) {
                    label = `${rawData[i].title} - ${rawData[i].path}`;
                }

                data.push({
                    label,
                    label_2: rawData[i].title,
                    value: rawData[i].visitors,
                    views: rawData[i].views,
                    relative_value: rawData[i].relative_visitors,
                    bounce_rate: rawData[i].bounce_rate,
                    relative_views: rawData[i].relative_views,
                    average_time_on_page_seconds: rawData[i].average_time_spent_seconds,
                    url: `https://${domainStore.domain.hostname}${rawData[i].path}`
                });
            }

            return data;
        };
    });

    const eventPages = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? eventPagesRaw.value : topEventPagesRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                let label = rawData[i].path;

                if (domainStore.domain.group_by_title && limitless) {
                    label = `${rawData[i].title} - ${rawData[i].path}`;
                }

                data.push({
                    label,
                    label_2: rawData[i].title,
                    value: rawData[i].visitors,
                    views: rawData[i].views,
                    relative_value: rawData[i].relative_visitors,
                    bounce_rate: rawData[i].bounce_rate,
                    relative_views: rawData[i].relative_views,
                    average_time_on_page_seconds: rawData[i].average_time_spent_seconds,
                    url: `https://${domainStore.domain.hostname}${rawData[i].path}`
                });
            }

            return data;
        };
    });

    const entryPages = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? entryPagesRaw.value : topEntryPagesRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                let label = rawData[i].path;

                if (domainStore.domain.group_by_title && limitless) {
                    label = `${rawData[i].title} - ${rawData[i].path}`;
                }

                data.push({
                    label,
                    label_2: rawData[i].title,
                    value: rawData[i].entries,
                    visitors: rawData[i].visitors,
                    relative_value: rawData[i].entry_rate,
                    average_time_on_page_seconds: rawData[i].average_time_spent_seconds,
                    url: `https://${domainStore.domain.hostname}${rawData[i].path}`
                });
            }

            return data;
        };
    });

    const exitPages = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? exitPagesRaw.value : topExitPagesRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                let label = rawData[i].path;

                if (domainStore.domain.group_by_title && limitless) {
                    label = `${rawData[i].title} - ${rawData[i].path}`;
                }

                data.push({
                    label,
                    label_2: rawData[i].title,
                    value: rawData[i].exits,
                    visitors: rawData[i].visitors,
                    relative_value: rawData[i].exit_rate,
                    url: `https://${domainStore.domain.hostname}${rawData[i].path}`
                });
            }

            return data;
        };
    });

    const platform = computed(() => {
        return () => {
            const data = [];

            if (platformRaw.value) {
                if (platformRaw.value.platform_desktop > 0) {
                    data.push({
                        filter: "desktop",
                        icon: "/img/platform/desktop.svg",
                        label: "Desktop",
                        value: platformRaw.value.platform_desktop,
                        relative_value: platformRaw.value.relative_platform_desktop
                    });
                }

                if (platformRaw.value.platform_mobile > 0) {
                    data.push({
                        filter: "mobile",
                        icon: "/img/platform/mobile.svg",
                        label: "Mobile",
                        value: platformRaw.value.platform_mobile,
                        relative_value: platformRaw.value.relative_platform_mobile
                    });
                }

                if (platformRaw.value.platform_unknown > 0) {
                    data.push({
                        filter: "unknown",
                        label: "", // unknown
                        value: platformRaw.value.platform_unknown,
                        relative_value: platformRaw.value.relative_platform_unknown
                    });
                }
            }

            data.sort((a, b) => {
                if (a.value < b.value) {
                    return 1;
                } else if (a.value > b.value) {
                    return -1;
                }

                return 0;
            });

            return data;
        };
    });

    const countries = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? countriesRaw.value : topCountriesRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                rawData[i].country_code = getCountryCode(rawData[i].country_code);
                const country = getCountry(rawData[i].country_code);
                data.push({
                    icon_class: country && hasFlag(country.alpha2) ? `flag:${country.alpha2}` : "",
                    filter: rawData[i].country_code,
                    label: country ? country.country : "",
                    value: rawData[i].visitors,
                    relative_value: rawData[i].relative_visitors
                });
            }

            return data;
        };
    });

    const regions = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? regionsRaw.value : topRegionsRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                rawData[i].country_code = rawData[i].country_code.replace(/\0/g, ""); // remove null bytes for "empty" strings
                const country = whereAlpha2(rawData[i].country_code.toUpperCase());
                data.push({
                    icon_class: country && hasFlag(country.alpha2) ? `flag:${country.alpha2}` : "",
                    label: rawData[i].region,
                    value: rawData[i].visitors,
                    relative_value: rawData[i].relative_visitors
                });
            }

            return data;
        };
    });

    const cities = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? citiesRaw.value : topCitiesRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                rawData[i].country_code = rawData[i].country_code.replace(/\0/g, ""); // remove null bytes for "empty" strings
                const country = whereAlpha2(rawData[i].country_code.toUpperCase());
                data.push({
                    icon_class: country && hasFlag(country.alpha2) ? `flag:${country.alpha2}` : "",
                    label: rawData[i].city,
                    value: rawData[i].visitors,
                    relative_value: rawData[i].relative_visitors
                });
            }

            return data;
        };
    });

    const screens = computed(() => {
        return () => {
            const data = [];

            for (let i = 0; i < screensRaw.value.length; i++) {
                data.push({
                    filter: screensRaw.value[i].screen_class,
                    label: screenSizes.get(screensRaw.value[i].screen_class) || "Unknown",
                    value: screensRaw.value[i].visitors,
                    relative_value: screensRaw.value[i].relative_visitors
                });
            }

            return data;
        };
    });

    const keywords = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? keywordsRaw.value : topKeywordsRaw.value;
            const limit = limitless ? keywordsRaw.value.length : 10;
            const data = [];

            for (let i = 0; i < rawData.length && i < limit; i++) {
                data.push({
                    label: rawData[i].keys.join(" "),
                    value: rawData[i].clicks,
                    relative_value: rawData[i].ctr,
                    impressions: rawData[i].impressions,
                    position: rawData[i].position
                });
            }

            return data;
        };
    });

    const utmSource = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? utmSourceRaw.value : topUTMSourceRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                data.push({
                    label: rawData[i].utm_source,
                    value: rawData[i].visitors,
                    relative_value: rawData[i].relative_visitors
                });
            }

            return data;
        };
    });

    const utmMedium = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? utmMediumRaw.value : topUTMMediumRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                data.push({
                    label: rawData[i].utm_medium,
                    value: rawData[i].visitors,
                    relative_value: rawData[i].relative_visitors
                });
            }

            return data;
        };
    });

    const utmCampaign = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? utmCampaignRaw.value : topUTMCampaignRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                data.push({
                    label: rawData[i].utm_campaign,
                    value: rawData[i].visitors,
                    relative_value: rawData[i].relative_visitors
                });
            }

            return data;
        };
    });

    const utmContent = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? utmContentRaw.value : topUTMContentRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                data.push({
                    label: rawData[i].utm_content,
                    value: rawData[i].visitors,
                    relative_value: rawData[i].relative_visitors
                });
            }

            return data;
        };
    });

    const utmTerm = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? utmTermRaw.value : topUTMTermRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                data.push({
                    label: rawData[i].utm_term,
                    value: rawData[i].visitors,
                    relative_value: rawData[i].relative_visitors
                });
            }

            return data;
        };
    });

    const conversionGoals = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? conversionGoalsRaw.value : topConversionGoalsRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                data.push({
                    label: rawData[i].page_goal.name,
                    path_pattern: rawData[i].page_goal.path_pattern,
                    pattern: rawData[i].page_goal.pattern,
                    event_name: rawData[i].page_goal.event_name,
                    event_meta_key: rawData[i].page_goal.event_meta_key,
                    event_meta_value: rawData[i].page_goal.event_meta_value,
                    custom_metric_key: rawData[i].page_goal.custom_metric_key,
                    custom_metric_type: rawData[i].page_goal.custom_metric_type,
                    visitor_target: rawData[i].page_goal.visitor_goal || 0,
                    value: rawData[i].stats.visitors,
                    views: rawData[i].stats.views,
                    cr_target: (rawData[i].page_goal.cr_goal || 0)/100,
                    relative_value: rawData[i].stats.cr,
                    custom_metric_total: rawData[i].stats.custom_metric_total,
                    custom_metric_avg: rawData[i].stats.custom_metric_avg
                });
            }

            return data;
        };
    });

    const events = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? eventsRaw.value : topEventsRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                data.push({
                    label: rawData[i].name,
                    count: rawData[i].count,
                    value: rawData[i].visitors,
                    views: rawData[i].views,
                    relative_value: rawData[i].cr,
                    average_duration_seconds: rawData[i].average_duration_seconds,
                    meta_keys: rawData[i].meta_keys
                });
            }

            return data;
        };
    });

    const tags = computed(() => {
        return (limitless: boolean) => {
            const rawData = limitless ? tagsRaw.value : topTagsRaw.value;
            const data = [];

            for (let i = 0; i < rawData.length; i++) {
                data.push({
                    label: rawData[i].key,
                    tag_value: rawData[i].value,
                    value: rawData[i].visitors,
                    views: rawData[i].views,
                    relative_value: rawData[i].relative_visitors,
                    relative_views: rawData[i].relative_views
                });
            }

            return data;
        };
    });

    const sessions = computed(() => sessionsRaw.value || []);

    const funnels = computed(() => funnelsRaw.value || []);

    const previousVisitorsByHour = computed(() => previousVisitorsByHourRaw.value || []);

    const previousVisitorsByMinute = computed(() => previousVisitorsByMinuteRaw.value || []);

    const previousTotalVisitors = computed(() => previousTotalVisitorsRaw.value);

    const previousVisitors = computed(() => previousVisitorsRaw.value || []);

    const previousAverageTimeSpent = computed(() => previousAverageTimeSpentRaw.value || []);

    return {
        loadAll,
        loadActiveVisitors,
        loadLanguages,
        loadReferrer,
        loadBrowserVersions,
        loadOSVersions,
        loadPages,
        loadEventPages,
        loadEntryPages,
        loadExitPages,
        loadCountries,
        loadRegions,
        loadCities,
        loadKeywords,
        loadUTMSource,
        loadUTMMedium,
        loadUTMCampaign,
        loadUTMContent,
        loadUTMTerm,
        loadConversionGoals,
        loadEvents,
        loadTags,
        loadSessions,
        loadFunnels,
        isLoading,
        loadMore,
        activeVisitors,
        activePages,
        activeCountries,
        visitorsByHour,
        visitorsByMinute,
        totalVisitors,
        visitors,
        growth,
        languages,
        referrer,
        browser,
        browserVersions,
        os,
        osVersions,
        pages,
        eventPages,
        entryPages,
        exitPages,
        platform,
        countries,
        regions,
        cities,
        screens,
        averageTimeSpent,
        keywords,
        utmSource,
        utmMedium,
        utmCampaign,
        utmContent,
        utmTerm,
        conversionGoals,
        events,
        tags,
        sessions,
        sessionsLimit,
        funnels,
        previousVisitorsByHour,
        previousVisitorsByMinute,
        previousTotalVisitors,
        previousVisitors,
        previousAverageTimeSpent
    };
});

export function getCountryCode(code: string) {
    // remove null bytes for "empty" strings
    return code.replace(/\0/g, "");
}

export function getCountry(code: string) {
    const country = whereAlpha2(code.toUpperCase());

    if (country && country.country === "United Kingdom of Great Britain and Northern Ireland") {
        country.country = "United Kingdom";
    }

    return country;
}

export function trimProtocol(referrer: string) {
    if (referrer.startsWith("http://")) {
        return referrer.substring(7);
    } else if (referrer.startsWith("https://")) {
        return referrer.substring(8);
    }

    return referrer;
}

export function isURL(referrer: string) {
    return referrer.startsWith("http://") || referrer.startsWith("https://");
}

export function selectOSIcon(os: string) {
    return osIcons.get(os) || "";
}

export function selectBrowserIcon(browser: string) {
    return browserIcons.get(browser) || "";
}
