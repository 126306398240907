import {dateFromParam, dateParam, timeFromParam, timeParam} from "@/util/format";
import {Filter, FilterField} from "@/model/Filter";
import {TimeRange} from "@/model/TimeRange";
import {timeRangeOptions} from "@/components/panels/time_range_options";
import {Comparison} from "@/model/Comparison";
import {CompareRange} from "@/model/CompareRange";
import {comparisonDatesIndex, comparisonOptions} from "@/components/panels/comparison_options";
import {Domain} from "@/model/Domain";
import {User} from "@/model/User";

export interface QueryParam {
    key: string
    value: string | number | undefined
}

export function getQueryParam(name: string): string {
    const url = new URLSearchParams(location.search);
    return url.get(name) || "";
}

export function getAccessCode(): string {
    return getQueryParam("access");
}

export function getFilterParams(): Filter {
    const url = new URLSearchParams(location.search);
    const fromParam = url.get("from");
    const toParam = url.get("to");
    const fromTimeParam = url.get("from_time");
    const toTimeParam = url.get("to_time");
    const startParam = url.get("start");
    const from = fromParam ? dateFromParam(fromParam) : null;
    const to = toParam ? dateFromParam(toParam) : null;
    const start = startParam ? parseInt(startParam) || 0 : 0;
    let timeRange: TimeRange | undefined;

    if (fromParam !== null && toParam !== null || startParam !== null) {
        timeRange = {from: from as Date, to: to as Date, start: start as number};

        if (fromTimeParam !== null && toTimeParam !== null) {
            const ft = timeFromParam(fromTimeParam);
            const tt = timeFromParam(toTimeParam);
            timeRange.fromTime = ft ?? undefined;
            timeRange.toTime = tt ?? undefined;
        }
    }

    const intervalParam = url.get("interval");
    let interval = -1;

    if (intervalParam !== null) {
        for (let i = 0; i < timeRangeOptions.length; i++) {
            if (intervalParam === timeRangeOptions[i].value) {
                interval = i;
                break;
            }
        }
    }

    return {
        timeRange,
        timeRangeIndex: interval,
        scale: url.get("scale") || undefined,
        fields: getFilterFieldParams(url)
    };
}

export function getComparisonParams(): Comparison {
    const url = new URLSearchParams(location.search);
    const compareFromParam = url.get("compare_from");
    const compareToParam = url.get("compare_to");
    const compareParam = url.get("compare");
    let compare = undefined;

    if (compareParam !== null) {
        for (let i = 0; i < comparisonOptions.length; i++) {
            if (compareParam === comparisonOptions[i].value) {
                compare = compareParam;
                break;
            }
        }
    }

    const compareFrom = compareFromParam ? dateFromParam(compareFromParam) : null;
    const compareTo = compareToParam ? dateFromParam(compareToParam) : null;
    let compareRange: CompareRange | undefined;

    if (compareFrom !== null && compareTo !== null) {
        compareRange = {
            from: compareFrom,
            to: compareTo
        };
    }

    const compareWeekdayParam = url.get("compare_weekday");
    return {
        compare,
        compareRange,
        compareWeekday: compareWeekdayParam === null || compareWeekdayParam === "true"
    };
}

export function updateQueryParams(user: User, domain: Domain, filter: Filter, comparison: Comparison) {
    const queryParams: QueryParam[] = [];
    queryParams.push({key: "domain", value: domain.hostname});

    if (filter.timeRangeIndex === undefined || filter.timeRangeIndex < 0) {
        if (filter.timeRange && filter.timeRange.start > 0) {
            queryParams.push({key: "from", value: undefined});
            queryParams.push({key: "to", value: undefined});
            queryParams.push({key: "from_time", value: undefined});
            queryParams.push({key: "to_time", value: undefined});
            queryParams.push({key: "start", value: filter.timeRange.start});
            queryParams.push({key: "interval", value: undefined});
        } else if (filter.timeRange && filter.timeRange.from && filter.timeRange.to) {
            queryParams.push({key: "from", value: dateParam(filter.timeRange.from)});
            queryParams.push({key: "to", value: dateParam(filter.timeRange.to)});
            queryParams.push({key: "from_time", value: filter.timeRange.fromTime ? timeParam(filter.timeRange.fromTime) : undefined});
            queryParams.push({key: "to_time", value: filter.timeRange.toTime ? timeParam(filter.timeRange.toTime) : undefined});
            queryParams.push({key: "start", value: undefined});
            queryParams.push({key: "interval", value: undefined});
        } else {
            queryParams.push({key: "from", value: undefined});
            queryParams.push({key: "to", value: undefined});
            queryParams.push({key: "from_time", value: undefined});
            queryParams.push({key: "to_time", value: undefined});
            queryParams.push({key: "start", value: undefined});
            queryParams.push({key: "interval", value: user ? user.default_time_range : -1});
        }
    } else {
        queryParams.push({key: "from", value: undefined});
        queryParams.push({key: "to", value: undefined});
        const start = timeRangeOptions[filter.timeRangeIndex].start(domain);
        queryParams.push({
            key: "start",
            value: start === 0 ? undefined : start
        });
        queryParams.push({key: "interval", value: timeRangeOptions[filter.timeRangeIndex].value});

        if (filter.timeRange) {
            queryParams.push({key: "from_time", value: filter.timeRange.fromTime ? timeParam(filter.timeRange.fromTime) : undefined});
            queryParams.push({key: "to_time", value: filter.timeRange.toTime ? timeParam(filter.timeRange.toTime) : undefined});
        }
    }

    queryParams.push({key: "scale", value: filter.scale});

    if (filter.fields) {
        for (let i = 0; i < filter.fields.length; i++) {
            queryParams.push({key: filter.fields[i].field, value: filter.fields[i].value})
        }
    }

    queryParams.push({key: "access", value: getQueryParam("access")});
    const ui = getQueryParam("ui");
    const mode = getQueryParam("mode");
    const lang = getQueryParam("lang");

    if (ui) {
        queryParams.push({key: "ui", value: ui});
    }

    if (mode) {
        queryParams.push({key: "mode", value: mode});
    }

    if (lang) {
        queryParams.push({key: "lang", value: lang});
    }

    if (comparison.compare === comparisonOptions[comparisonDatesIndex].value && comparison.compareRange) {
        queryParams.push({key: "compare_from", value: dateParam(comparison.compareRange.from)});
        queryParams.push({key: "compare_to", value: dateParam(comparison.compareRange.to)});
    }

    queryParams.push({key: "compare", value: comparison.compare});
    queryParams.push({key: "compare_weekday", value: comparison.compareWeekday ? undefined : "false"});
    setQueryParams(queryParams);
}

export function setQueryParam(key: string, value?: string | number): void {
    const url = new URLSearchParams(location.search);

    if (value !== undefined && value !== "") {
        url.set(key, value.toString());
    } else {
        url.delete(key);
    }

    history.replaceState(history.state, "", location.origin+location.pathname+"?"+url.toString());
}

export function setQueryParams(params: QueryParam[]): void {
    const kv = [];

    for (let i = 0; i < params.length; i++) {
        const key = params[i].key;
        const value = params[i].value;

        if (value !== undefined && value !== "") {
            kv.push([key, value.toString()]);
        }
    }

    const url = new URLSearchParams(kv);
    history.replaceState(history.state, "", location.origin + location.pathname + "?" + url.toString());
}

function getFilterFieldParams(url: URLSearchParams): FilterField[] {
    const fields: FilterField[] = [];
    fields.push(...getQueryParams(url, "path"));
    fields.push(...getQueryParams(url, "entry_path"));
    fields.push(...getQueryParams(url, "exit_path"));
    fields.push(...getQueryParams(url, "pattern"));
    fields.push(...getQueryParams(url, "event"));
    fields.push(...getQueryParams(url, "referrer"));
    fields.push(...getQueryParams(url, "referrer_name"));
    fields.push(...getQueryParams(url, "utm_source"));
    fields.push(...getQueryParams(url, "utm_medium"));
    fields.push(...getQueryParams(url, "utm_campaign"));
    fields.push(...getQueryParams(url, "utm_content"));
    fields.push(...getQueryParams(url, "utm_term"));
    fields.push(...getQueryParams(url, "language"));
    fields.push(...getQueryParams(url, "country"));
    fields.push(...getQueryParams(url, "region"));
    fields.push(...getQueryParams(url, "city"));
    fields.push(...getQueryParams(url, "os"));
    fields.push(...getQueryParams(url, "browser"));
    fields.push(...getQueryParams(url, "platform"));
    fields.push(...getQueryParams(url, "screen_class"));
    fields.push(...getQueryParams(url, "custom_metric_type"));
    fields.push(...getQueryParams(url, "custom_metric_key"));
    fields.push(...getQueryParams(url, "tag"));
    fields.push(...getMapParams("meta_", url));
    fields.push(...getMapParams("tag_", url));
    return fields;
}

function getQueryParams(url: URLSearchParams, field: string): FilterField[] {
    const params = url.getAll(field);
    const fields: FilterField[] = [];

    for (let i = 0; i < params.length; i++) {
        if (params[i] !== "") {
            fields.push({
                field,
                value: params[i]
            });
        }
    }

    return fields;
}

function getMapParams(prefix: string, url: URLSearchParams): FilterField[] {
    const fields: FilterField[] = [];
    url.forEach((value, key) => {
        if (key.startsWith(prefix)) {
            fields.push({
                field: key,
                value
            });
        }
    });
    return fields;
}
