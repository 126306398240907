<template>
    <div style="display: flex;justify-content: center;align-items: center;min-height: 20vh;">
        <canvas ref="canvas" v-show="!loading || loaded"></canvas>
        <Loading size="l" :loading="loading && !loaded" />
    </div>
</template>

<script lang="ts">
    import {defineComponent, onMounted, onUnmounted, ref, watch} from "vue";
    import {Chart} from "chart.js";
    import {topojson} from "chartjs-chart-geo";
    import Loading from "@/components/bits/Loading.vue";
    import {useFilterStore} from "@/store/FilterStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {storeToRefs} from "pinia";
    import {ThemeSettings, useThemeStore} from "@/store/ThemeStore";
    import {selectColor} from "@/components/panels/chart";
    import {all} from "iso-3166-1";

    export default defineComponent({
        components: {
            Loading
        },
        props: {
            load: {type: Boolean, required: true},
            live: {type: Boolean, default: false}
        },
        emits: ["select", "close"],
        setup(props, {emit}) {
            const filterStore = useFilterStore();
            const statisticsStore = useStatisticsStore();
            const {filter} = storeToRefs(filterStore);
            const {loadCountries, countries} = statisticsStore;
            const {activeCountries} = storeToRefs(statisticsStore);
            const {theme, darkMode} = storeToRefs(useThemeStore());
            const canvas = ref(document.createElement("canvas"));
            const loading = ref(true);
            const loaded = ref(false);
            let chart: Chart | undefined;
            let list: any[];

            onMounted(createOrUpdateMap);
            onUnmounted(() => {
                chart?.destroy();
                chart = undefined;
            });
            watch(filter, () => loading.value = true);
            watch([() => props.load, loading], async () => await createOrUpdateMap());
            watch([activeCountries], async () => {
                if (props.live) {
                    await createOrUpdateMap()
                }
            });

            async function createOrUpdateMap() {
                if (!props.load) {
                    chart?.destroy();
                    chart = undefined;
                    loading.value = true;
                    loaded.value = false;
                } else if (props.load && loading.value) {
                    if (!props.live) {
                        const f = filterStore.copy(filter.value);
                        f.limit = 0;
                        await loadCountries(f, false);
                    }

                    loading.value = false;
                } else if (props.load && !loading.value) {
                    const countryMap = await loadCountryMap();

                    if (props.live) {
                        list = activeCountries.value;
                    } else {
                        list = countries(true);
                    }

                    const data = countryMap.map(feature => ({feature, value: list.find(entry => entry.label === feature.properties.name)?.value ?? 0}));

                    if (chart) {
                        chart.data.datasets[0].data = data;
                        chart.update();
                    } else {
                        const t = theme.value ?? {};
                        const fullColor = selectColor(t[ThemeSettings.lightColorGreen], "#6ECE9D", t[ThemeSettings.darkColorGreen], "#6ECE9D", darkMode.value);
                        const emptyColor = selectColor(t[ThemeSettings.lightColorSecondary], "#707070", t[ThemeSettings.darkColorSecondary], "#ADADAD", darkMode.value);
                        const borderColor = selectColor(t[ThemeSettings.lightColorBackgroundSecondary], "#ffffff", t[ThemeSettings.darkColorBackgroundSecondary], "#141414", darkMode.value);
                        chart = new Chart(canvas.value, {
                            type: "choropleth",
                            data: {
                                labels: countryMap.map(feature => feature.properties.name),
                                datasets: [{
                                    label: "Visitors",
                                    data,
                                    borderColor
                                }]
                            },
                            options: {
                                onClick: (_, elements) => {
                                    if (chart && elements.length) {
                                        const name = elements[0].element.feature.properties.name.toLowerCase();
                                        const country = all().find(c => c.country.toLowerCase().includes(name));

                                        if (country) {
                                            emit("select", country.alpha2.toLowerCase());
                                        }
                                    }
                                },
                                showOutline: false,
                                showGraticule: false,
                                scales: {
                                    projection: {
                                        axis: "x",
                                        projection: "naturalEarth1"
                                    },
                                    color: {
                                        axis: "x",
                                        display: false,
                                        interpolate: v => {
                                            const color = list.length !== 0 && v > 0 ? fullColor : emptyColor;
                                            const opacity = Math.floor((v + 0.1 > 1 ? 1 : v + 0.1) * 255);
                                            return `${color}${opacity <= 64 ? 40 : opacity.toString(16)}`;
                                        }
                                    }
                                },
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                    tooltip: {
                                        titleFont: {family: "DM Sans, sans-serif", size: 14, weight: "normal"},
                                        titleColor: "#adadad",
                                        bodyFont: {family: "DM Sans, sans-serif", size: 14, weight: "bold"},
                                        bodyColor: "#fff",
                                        displayColors: false,
                                        padding: 8,
                                        cornerRadius: 6
                                    }
                                }
                            }
                        });
                        loaded.value = true;
                    }
                }
            }

            async function loadCountryMap() {
                const cached = localStorage.getItem("map_countries");

                if (cached) {
                    return JSON.parse(cached);
                }

                const data = await fetch("/map/countries-110m.json").then(r => r.json());
                const countries = topojson.feature(data, data.objects.countries).features;
                localStorage.setItem("map_countries", JSON.stringify(countries));
                return countries;
            }

            return {
                canvas,
                loading,
                loaded
            };
        }
    });
</script>
