<template>
    <CreateEditAccessLink :open="editAccessLinkModal"
        v-on:close="editAccessLinkModal = false"
        v-on:action="updateAccessLink"
        :link="link" />
    <ConfirmDeleteAccessLink :open="deleteAccessLinkModal"
        v-on:close="deleteAccessLinkModal = false"
        v-on:action="removeAccessLink"
        :link="link" />
    <div class="card">
        <div class="text">
            <p>
                <strong>{{link.description}}</strong>
            </p>
            <small><a :href="url" target="_blank">{{url}}</a></small>
        </div>
        <div class="row">
            <Lock :lock="`access_link_${link.id}`">
                <i class="icon icon-copy clickable" :title="t('copy_to_clipboard')" v-on:click="copyToClipboard" />
                <i class="icon icon-edit clickable" :title="t('edit')" v-on:click="editAccessLinkModal = true" />
                <i class="icon icon-trash clickable" :title="t('delete')" v-on:click="deleteAccessLinkModal = true" />
            </Lock>
        </div>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, ref} from "vue";
    import CreateEditAccessLink from "@/components/modal/CreateEditAccessLink.vue";
    import ConfirmDeleteAccessLink from "@/components/modal/ConfirmDeleteAccessLink.vue";
    import Lock from "@/components/settings/Lock.vue";
    import {AccessLink} from "@/model/AccessLink";
    import copy from "copy-to-clipboard";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            ConfirmDeleteAccessLink,
            CreateEditAccessLink,
            Lock
        },
        props: {
            link: {type: Object as PropType<AccessLink>, required: true}
        },
        emits: ["update", "remove"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {success} = useToastStore();
            const editAccessLinkModal = ref(false);
            const deleteAccessLinkModal = ref(false);
            const {domain} = storeToRefs(useDomainStore());
            const url = computed(() => {
                if (domain.value.custom_domain) {
                    return `https://${domain.value.custom_domain}/?access=${props.link.code}`;
                }

                return `https://${domain.value.subdomain}.${import.meta.env.VITE_APP_DASHBOARD_DOMAIN}/?access=${props.link.code}`;
            });

            function updateAccessLink(link: AccessLink) {
                editAccessLinkModal.value = false;
                emit("update", link);
            }

            function removeAccessLink() {
                deleteAccessLinkModal.value = false;
                emit("remove", props.link);
            }

            function copyToClipboard() {
                copy(url.value);
                success(t("toast.clipboard"));
            }

            return {
                t,
                editAccessLinkModal,
                deleteAccessLinkModal,
                url,
                updateAccessLink,
                removeAccessLink,
                copyToClipboard
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "copy_to_clipboard": "Copy to Clipboard",
            "edit": "Edit",
            "delete": "Delete",
            "toast.clipboard": "Copied to clipboard."
        },
        "de": {
            "copy_to_clipboard": "In die Zwischenablage kopieren",
            "edit": "Bearbeiten",
            "delete": "Löschen",
            "toast.clipboard": "In die Zwischenablage kopiert."
        },
        "es": {
            "copy_to_clipboard": "Copiar al portapapeles",
            "edit": "Editar",
            "delete": "Eliminar",
            "toast.clipboard": "Copiado al portapapeles."
        },
        "fr": {
            "copy_to_clipboard": "Copier dans le presse-papiers",
            "edit": "Éditer",
            "delete": "Supprimer",
            "toast.clipboard": "Copié dans le presse-papiers."
        },
        "nl": {
            "copy_to_clipboard": "Kopiëren naar klembord",
            "edit": "Bewerken",
            "delete": "Verwijderen",
            "toast.clipboard": "Gekopieerd naar klembord."
        },
        "it": {
            "copy_to_clipboard": "Copia negli appunti",
            "edit": "Modifica",
            "delete": "Elimina",
            "toast.clipboard": "Copiato negli appunti."
        },
        "pt": {
            "copy_to_clipboard": "Copiar para a área de transferência",
            "edit": "Editar",
            "delete": "Excluir",
            "toast.clipboard": "Copiado para a área de transferência."
        },
        "ja": {
            "copy_to_clipboard": "クリップボードにコピー",
            "edit": "編集",
            "delete": "削除",
            "toast.clipboard": "クリップボードにコピーされました。"
        }
    }
</i18n>
