<template>
    <Modal :title="funnel ? t('title.edit') : t('title.add')" size="l" v-on:close="$emit('close')">
        <FormError :err="err" :validationErrors="true" />
        <form v-on:submit.prevent="save">
            <FormInput :label="t('form.name')" name="name" :autofocus="true" v-model="name" />
            <div class="funnel-step" v-for="(step, index) in steps" :key="step.step">
                <div class="row">
                    <FormInput :label="t('form.step.name')" :name="`step_name_${step.step}`" v-model="step.name" />
                    <div class="step-actions">
                        <i class="icon icon-chevron icon-rotate-180 clickable"
                           :title="t('step.up')"
                           v-on:click="moveStep(index, -1)"
                           v-if="index > 0"></i>
                        <i class="icon icon-chevron clickable"
                           :title="t('step.down')"
                           v-on:click="moveStep(index, 1)"
                           v-if="index < steps.length-1"></i>
                        <i class="icon icon-trash clickable"
                            :title="t('step.remove')"
                            v-on:click="removeStep(index)"
                            v-if="steps.length > 2"></i>
                    </div>
                </div>
                <div class="row" v-for="(filter, i) in step.filter">
                    <FormSelect :label="t('form.step.type')" name="type" :options="filterTypeOptions" v-model="filter.type" />
                    <FormInput :label="t('form.step.key')" name="key" v-model="filter.key" v-if="filter.type === 'tags' || filter.type === 'event_meta'" />
                    <FormSelect :label="t('form.step.operator')" name="operator" :options="getOperatorOptions(filter.type)" v-model="filter.operator" />
                    <FormSelect :label="t('form.step.value')" name="value" :options="platformOptions" v-model="filter.value" v-if="filter.type === 'platform'" />
                    <FormSelect :label="t('form.step.value')" name="value" :options="languageOptions" v-model="filter.value" v-else-if="filter.type === 'language'" />
                    <FormSelect :label="t('form.step.value')" name="value" :options="countryOptions" v-model="filter.value" v-else-if="filter.type === 'country'" />
                    <FormInput :label="t('form.step.value')" name="value" v-model="filter.value" v-else />
                    <div class="step-actions" v-if="step.filter.length > 1">
                        <i class="icon icon-trash clickable"
                           :title="t('filter.remove')"
                           v-on:click="removeFilter(index, i)"></i>
                    </div>
                </div>
                <div class="action" :title="t('filter.add')" v-on:click="addFilter(index)">
                    <i class="icon icon-add"></i>
                    <span>{{t("filter.add")}}</span>
                </div>
            </div>
            <div class="action" :title="t('step.add')" style="align-self: center;" v-on:click="addStep" v-if="steps.length < 8">
                <i class="icon icon-add"></i>
                <span>{{t("step.add")}}</span>
            </div>
            <FormSubmit :value="t('form.submit')" :loading="loading" />
        </form>
    </Modal>
</template>

<script lang="ts">
    import {computed, defineComponent, PropType, ref, watch} from "vue";
    import Modal from "@/components/modal/Modal.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";
    import {FunnelRepo, FunnelStepData} from "@/repositories/FunnelRepo";
    import FormSelect from "@/components/form/FormSelect.vue";
    import {filterSkipContains} from "@/components/panels/filter_fields";
    import {FunnelFilter} from "@/model/FunnelFilter";
    import {KeyValue} from "@/model/KeyValue";
    import {Funnel} from "@/model/Funnel";
    import {FunnelStep} from "@/model/FunnelStep";
    import ISO6391 from "iso-639-1";
    import {all} from "iso-3166-1";
    import FormError from "@/components/form/FormError.vue";

    type Operator = "" | "!" | "~" | "^";

    interface StepFilter {
        type: string
        operator: Operator
        key?: string
        value: string
    }

    interface Step {
        step: number
        name: string
        filter: StepFilter[]
    }

    export interface KV {
        [key: string]: string | string[] | KeyValue
    }

    export default defineComponent({
        components: {
            FormError,
            FormSelect,
            Modal,
            FormSubmit,
            FormInput
        },
        props: {
            funnel: {type: Object as PropType<Funnel>}
        },
        emits: ["action", "close"],
        setup(props, {emit}) {
            const {t} = useI18n();
            const {resetError, setError, err} = useError();
            const {success, error} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const loading = ref(false);
            const name = ref("");
            const steps = ref<Array<Step>>(defaultSteps());
            const languageOptions = computed(() => ISO6391.getAllCodes().map(code => ({
                label: ISO6391.getName(code),
                value: code
            })));
            const countryOptions = computed(() => all().map(country => ({
                label: country.country,
                value: country.alpha2.toLowerCase()
            })));
            let stepCounter = 2;

            watch(() => props.funnel, funnel => {
                if (funnel) {
                    name.value = funnel.name;
                    steps.value = buildSteps(funnel.steps);
                } else {
                    name.value = "";
                    steps.value = defaultSteps();
                }
            });

            function buildSteps(steps: FunnelStep[]): Step[] {
                const list: Step[] = [];
                stepCounter = 0;
                steps.forEach(s => {
                    list.push({
                        step: stepCounter,
                        name: s.name,
                        filter: buildFilter(s.filter)
                    });
                    stepCounter++;
                });
                return list;
            }

            function buildFilter(filter: FunnelFilter): StepFilter[] {
                const list: StepFilter[] = [];

                for (const [key, value] of Object.entries(filter)) {
                    switch (key) {
                        case "platform":
                            const {value: v, operator} = getValue(value);
                            list.push({type: key, operator, value: v});
                            break;
                        case "tags":
                        case "event_meta":
                            for (const [k, val] of Object.entries(value as KeyValue)) {
                                const {value: v, operator} = getValue(val);
                                list.push({type: key, operator, key: k, value: v});
                            }

                            break;
                        default:
                            (value as string[]).forEach(val => {
                                const {value: v, operator} = getValue(val);
                                list.push({type: key, operator, value: v});
                            });
                    }
                }

                return list;
            }

            function getValue(value: string): {value: string, operator: Operator} {
                if (value.startsWith("!") || value.startsWith("~") || value.startsWith("^")) {
                    return {value: value.substring(1), operator: value.substring(0, 1) as Operator};
                }

                return {value, operator: ""};
            }

            async function save() {
                try {
                    loading.value = true;
                    resetError();
                    const funnel = await FunnelRepo.save({
                        domain_id: domain.value.id,
                        id: props.funnel?.id,
                        name: name.value,
                        steps: getSteps()
                    }) as Funnel;
                    success(t("toast.saved"));
                    name.value = "";
                    steps.value = defaultSteps();
                    loading.value = false;
                    emit("action", funnel);
                } catch (e) {
                    loading.value = false;
                    setError(e as APIError);
                }
            }

            function getSteps(): FunnelStepData[] {
                const list: FunnelStepData[] = [];
                steps.value.forEach(step => {
                    list.push({
                        name: step.name,
                        filter: getFilter(step)
                    });
                });
                return list;
            }

            function getFilter(step: Step): FunnelFilter {
                const filter: FunnelFilter = {};
                step.filter.forEach(f => {
                    switch (f.type) {
                        case "platform":
                            filter[f.type] = `${f.operator}${f.value}`;
                            break;
                        case "tags":
                        case "event_meta":
                            if (filter[f.type] === undefined) {
                                filter[f.type] = {};
                            }

                            (filter[f.type] as KeyValue)[f.key ?? ""] = `${f.operator}${f.value}`;
                            break;
                        default:
                            if ((filter as KV)[f.type] === undefined) {
                                (filter as KV)[f.type] = [];
                            }

                            ((filter as KV)[f.type] as string[]).push(`${f.operator}${f.value}`);
                    }
                });
                return filter;
            }

            function addStep() {
                if (steps.value.length < 8) {
                    steps.value.push({
                        step: stepCounter,
                        name: "",
                        filter: [
                            {type: "path", operator: "", value: ""}
                        ]
                    });
                    stepCounter++;
                } else {
                    error(t("toast.steps.max"));
                }
            }

            function removeStep(index: number) {
                if (steps.value.length > 2) {
                    steps.value.splice(index, 1);
                } else {
                    error(t("toast.steps.min"));
                }
            }

            function moveStep(index: number, direction: number) {
                [steps.value[index], steps.value[index+direction]] = [steps.value[index+direction], steps.value[index]];
            }

            function addFilter(stepIndex: number) {
                steps.value[stepIndex].filter.push({type: "path", operator: "", value: ""});
            }

            function removeFilter(stepIndex: number, index: number) {
                steps.value[stepIndex].filter.splice(index, 1);
            }

            function getOperatorOptions(type: string) {
                const options = [
                    {label: t("operator.equal"), value: ""},
                    {label: t("operator.unequal"), value: "!"}
                ];

                if (!filterSkipContains.includes(type)) {
                    options.push(
                        {label: t("operator.includes"), value: "~"},
                        {label: t("operator.excludes"), value: "^"}
                    );
                }

                return options;
            }

            function defaultSteps(): Step[] {
                return [
                    {
                        step: 0,
                        name: t("step.1"),
                        filter: [
                            {type: "path", operator: "", value: ""}
                        ]
                    },
                    {
                        step: 1,
                        name: t("step.2"),
                        filter: [
                            {type: "path", operator: "", value: ""}
                        ]
                    }
                ];
            }

            return {
                filterTypeOptions: [
                    {label: t("filter.path"), value: "path"},
                    {label: t("filter.entry_path"), value: "entry_path"},
                    {label: t("filter.exit_path"), value: "exit_path"},
                    {label: t("filter.path_pattern"), value: "path_pattern"},
                    {label: t("filter.path_regex"), value: "path_regex"},
                    {label: t("filter.language"), value: "language"},
                    {label: t("filter.country"), value: "country"},
                    {label: t("filter.region"), value: "region"},
                    {label: t("filter.city"), value: "city"},
                    {label: t("filter.referrer"), value: "referrer"},
                    {label: t("filter.referrer_name"), value: "referrer_name"},
                    {label: t("filter.os"), value: "os"},
                    {label: t("filter.os_version"), value: "os_version"},
                    {label: t("filter.browser"), value: "browser"},
                    {label: t("filter.browser_version"), value: "browser_version"},
                    {label: t("filter.platform"), value: "platform"},
                    {label: t("filter.screen_class"), value: "screen_class"},
                    {label: t("filter.utm_source"), value: "utm_source"},
                    {label: t("filter.utm_medium"), value: "utm_medium"},
                    {label: t("filter.utm_campaign"), value: "utm_campaign"},
                    {label: t("filter.utm_content"), value: "utm_content"},
                    {label: t("filter.utm_term"), value: "utm_term"},
                    {label: t("filter.tags"), value: "tags"},
                    {label: t("filter.tag"), value: "tag"},
                    {label: t("filter.event_name"), value: "event_name"},
                    {label: t("filter.event_meta_key"), value: "event_meta_key"},
                    {label: t("filter.event_meta"), value: "event_meta"}
                ],
                platformOptions: [
                    {label: t("filter.platform.desktop"), value: "desktop"},
                    {label: t("filter.platform.mobile"), value: "mobile"},
                    {label: t("filter.platform.unknown"), value: ""}
                ],
                languageOptions,
                countryOptions,
                t,
                err,
                loading,
                name,
                steps,
                save,
                addStep,
                removeStep,
                moveStep,
                addFilter,
                removeFilter,
                getOperatorOptions
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title.add": "Add Funnel",
            "title.edit": "Edit Funnel",
            "form.name": "Name",
            "form.step.name": "Step Name",
            "form.step.type": "Filter Type",
            "form.step.operator": "Operator",
            "form.step.key": "Key",
            "form.step.value": "Value",
            "form.submit": "Save Funnel",
            "toast.saved": "The funnel has been saved.",
            "step.1": "Step 1",
            "step.2": "Step 2",
            "step.add": "Add Step",
            "step.up": "Move Step Up",
            "step.down": "Move Step Down",
            "step.remove": "Remove step",
            "toast.steps.max": "A funnel can not have more than 8 steps.",
            "toast.steps.min": "A funnel must have a minimum of 2 steps.",
            "filter.add": "Add Filter",
            "filter.remove": "Remove Filter",
            "filter.path": "Path",
            "filter.entry_path": "Entry Path",
            "filter.exit_path": "Exit Path",
            "filter.path_pattern": "Path Pattern",
            "filter.path_regex": "Path Regex (RE2 Syntax)",
            "filter.language": "Language",
            "filter.country": "Country",
            "filter.region": "Region",
            "filter.city": "City",
            "filter.referrer": "Referrer",
            "filter.referrer_name": "Referrer Name",
            "filter.os": "OS",
            "filter.os_version": "OS Version",
            "filter.browser": "Browser",
            "filter.browser_version": "Browser Version",
            "filter.platform": "Platform",
            "filter.platform.desktop": "Desktop",
            "filter.platform.mobile": "Mobile",
            "filter.platform.unknown": "Unknown",
            "filter.screen_class": "Screen Class",
            "filter.utm_source": "UTM Source",
            "filter.utm_medium": "UTM Medium",
            "filter.utm_campaign": "UTM Campaign",
            "filter.utm_content": "UTM Content",
            "filter.utm_term": "UTM Term",
            "filter.tags": "Tags",
            "filter.tag": "Tag",
            "filter.event_name": "Event",
            "filter.event_meta_key": "Event Meta Key",
            "filter.event_meta": "Event Meta",
            "operator.equal": "is",
            "operator.unequal": "is not",
            "operator.includes": "includes",
            "operator.excludes": "excludes"
        },
        "de": {
            "title.add": "Funnel hinzufügen",
            "title.edit": "Funnel bearbeiten",
            "form.name": "Name",
            "form.step.name": "Schrittname",
            "form.step.type": "Filtertyp",
            "form.step.operator": "Operator",
            "form.step.key": "Schlüssel",
            "form.step.value": "Wert",
            "form.submit": "Funnel speichern",
            "toast.saved": "Der Funnel wurde gespeichert.",
            "step.1": "Schritt 1",
            "step.2": "Schritt 2",
            "step.add": "Schritt hinzufügen",
            "step.up": "Schritt nach oben verschieben",
            "step.down": "Schritt nach unten verschieben",
            "step.remove": "Schritt entfernen",
            "toast.steps.max": "Ein Funnel kann nicht mehr als 8 Schritte haben.",
            "toast.steps.min": "Ein Funnel muss mindestens 2 Schritte haben.",
            "filter.add": "Filter hinzufügen",
            "filter.remove": "Filter entfernen",
            "filter.path": "Pfad",
            "filter.entry_path": "Eingangspfad",
            "filter.exit_path": "Ausgangspfad",
            "filter.path_pattern": "Pfadmuster",
            "filter.path_regex": "Pfad Regex (RE2-Syntax)",
            "filter.language": "Sprache",
            "filter.country": "Land",
            "filter.region": "Region",
            "filter.city": "Stadt",
            "filter.referrer": "Referrer",
            "filter.referrer_name": "Referrer-Name",
            "filter.os": "Betriebssystem",
            "filter.os_version": "Betriebssystemversion",
            "filter.browser": "Browser",
            "filter.browser_version": "Browserversion",
            "filter.platform": "Plattform",
            "filter.platform.desktop": "Desktop",
            "filter.platform.mobile": "Mobil",
            "filter.platform.unknown": "Unbekannt",
            "filter.screen_class": "Bildschirmklasse",
            "filter.utm_source": "UTM-Quelle",
            "filter.utm_medium": "UTM-Medium",
            "filter.utm_campaign": "UTM-Kampagne",
            "filter.utm_content": "UTM-Inhalt",
            "filter.utm_term": "UTM-Begriff",
            "filter.tags": "Tags",
            "filter.tag": "Tag",
            "filter.event_name": "Event",
            "filter.event_meta_key": "Event-Meta-Schlüssel",
            "filter.event_meta": "Event-Meta",
            "operator.equal": "ist",
            "operator.unequal": "ist nicht",
            "operator.includes": "enthält",
            "operator.excludes": "enthält nicht"
        },
        "es": {
            "title.add": "Agregar embudo",
            "title.edit": "Editar embudo",
            "form.name": "Nombre",
            "form.step.name": "Nombre del paso",
            "form.step.type": "Tipo de filtro",
            "form.step.operator": "Operador",
            "form.step.key": "Clave",
            "form.step.value": "Valor",
            "form.submit": "Guardar embudo",
            "toast.saved": "El embudo ha sido guardado.",
            "step.1": "Paso 1",
            "step.2": "Paso 2",
            "step.add": "Agregar paso",
            "step.up": "Mover paso arriba",
            "step.down": "Mover paso abajo",
            "step.remove": "Eliminar paso",
            "toast.steps.max": "Un embudo no puede tener más de 8 pasos.",
            "toast.steps.min": "Un embudo debe tener al menos 2 pasos.",
            "filter.add": "Agregar filtro",
            "filter.remove": "Eliminar filtro",
            "filter.path": "Ruta",
            "filter.entry_path": "Ruta de entrada",
            "filter.exit_path": "Ruta de salida",
            "filter.path_pattern": "Patrón de ruta",
            "filter.path_regex": "Regex de ruta (sintaxis RE2)",
            "filter.language": "Idioma",
            "filter.country": "País",
            "filter.region": "Región",
            "filter.city": "Ciudad",
            "filter.referrer": "Referente",
            "filter.referrer_name": "Nombre del referente",
            "filter.os": "Sistema operativo",
            "filter.os_version": "Versión del SO",
            "filter.browser": "Navegador",
            "filter.browser_version": "Versión del navegador",
            "filter.platform": "Plataforma",
            "filter.platform.desktop": "Escritorio",
            "filter.platform.mobile": "Móvil",
            "filter.platform.unknown": "Desconocido",
            "filter.screen_class": "Clase de pantalla",
            "filter.utm_source": "Fuente UTM",
            "filter.utm_medium": "Medio UTM",
            "filter.utm_campaign": "Campaña UTM",
            "filter.utm_content": "Contenido UTM",
            "filter.utm_term": "Término UTM",
            "filter.tags": "Etiquetas",
            "filter.tag": "Etiqueta",
            "filter.event_name": "Evento",
            "filter.event_meta_key": "Clave de meta del evento",
            "filter.event_meta": "Meta del evento",
            "operator.equal": "es",
            "operator.unequal": "no es",
            "operator.includes": "incluye",
            "operator.excludes": "excluye"
        },
        "fr": {
            "title.add": "Ajouter un entonnoir",
            "title.edit": "Modifier l'entonnoir",
            "form.name": "Nom",
            "form.step.name": "Nom de l'étape",
            "form.step.type": "Type de filtre",
            "form.step.operator": "Opérateur",
            "form.step.key": "Clé",
            "form.step.value": "Valeur",
            "form.submit": "Enregistrer l'entonnoir",
            "toast.saved": "L'entonnoir a été enregistré.",
            "step.1": "Étape 1",
            "step.2": "Étape 2",
            "step.add": "Ajouter une étape",
            "step.up": "Déplacer l'étape vers le haut",
            "step.down": "Déplacer l'étape vers le bas",
            "step.remove": "Supprimer l'étape",
            "toast.steps.max": "Un entonnoir ne peut pas avoir plus de 8 étapes.",
            "toast.steps.min": "Un entonnoir doit avoir au moins 2 étapes.",
            "filter.add": "Ajouter un filtre",
            "filter.remove": "Supprimer le filtre",
            "filter.path": "Chemin",
            "filter.entry_path": "Chemin d'entrée",
            "filter.exit_path": "Chemin de sortie",
            "filter.path_pattern": "Modèle de chemin",
            "filter.path_regex": "Regex de chemin (syntaxe RE2)",
            "filter.language": "Langue",
            "filter.country": "Pays",
            "filter.region": "Région",
            "filter.city": "Ville",
            "filter.referrer": "Référent",
            "filter.referrer_name": "Nom du référent",
            "filter.os": "OS",
            "filter.os_version": "Version de l'OS",
            "filter.browser": "Navigateur",
            "filter.browser_version": "Version du navigateur",
            "filter.platform": "Plateforme",
            "filter.platform.desktop": "Bureau",
            "filter.platform.mobile": "Mobile",
            "filter.platform.unknown": "Inconnu",
            "filter.screen_class": "Classe d'écran",
            "filter.utm_source": "Source UTM",
            "filter.utm_medium": "Moyen UTM",
            "filter.utm_campaign": "Campagne UTM",
            "filter.utm_content": "Contenu UTM",
            "filter.utm_term": "Terme UTM",
            "filter.tags": "Tags",
            "filter.tag": "Tag",
            "filter.event_name": "Événement",
            "filter.event_meta_key": "Clé Meta de l'événement",
            "filter.event_meta": "Meta de l'événement",
            "operator.equal": "est",
            "operator.unequal": "n'est pas",
            "operator.includes": "inclut",
            "operator.excludes": "exclut"
        },
        "nl": {
            "title.add": "Trechter toevoegen",
            "title.edit": "Trechter bewerken",
            "form.name": "Naam",
            "form.step.name": "Stapnaam",
            "form.step.type": "Filtertype",
            "form.step.operator": "Operator",
            "form.step.key": "Sleutel",
            "form.step.value": "Waarde",
            "form.submit": "Trechter opslaan",
            "toast.saved": "De trechter is opgeslagen.",
            "step.1": "Stap 1",
            "step.2": "Stap 2",
            "step.add": "Stap toevoegen",
            "step.up": "Stap omhoog verplaatsen",
            "step.down": "Stap omlaag verplaatsen",
            "step.remove": "Stap verwijderen",
            "toast.steps.max": "Een trechter mag niet meer dan 8 stappen hebben.",
            "toast.steps.min": "Een trechter moet minimaal 2 stappen hebben.",
            "filter.add": "Filter toevoegen",
            "filter.remove": "Filter verwijderen",
            "filter.path": "Pad",
            "filter.entry_path": "Ingangspad",
            "filter.exit_path": "Uitgangspad",
            "filter.path_pattern": "Padpatroon",
            "filter.path_regex": "Pad Regex (RE2-syntaxis)",
            "filter.language": "Taal",
            "filter.country": "Land",
            "filter.region": "Regio",
            "filter.city": "Stad",
            "filter.referrer": "Referrer",
            "filter.referrer_name": "Referrer-naam",
            "filter.os": "OS",
            "filter.os_version": "OS-versie",
            "filter.browser": "Browser",
            "filter.browser_version": "Browserversie",
            "filter.platform": "Platform",
            "filter.platform.desktop": "Desktop",
            "filter.platform.mobile": "Mobiel",
            "filter.platform.unknown": "Onbekend",
            "filter.screen_class": "Schermklasse",
            "filter.utm_source": "UTM-bron",
            "filter.utm_medium": "UTM-medium",
            "filter.utm_campaign": "UTM-campagne",
            "filter.utm_content": "UTM-inhoud",
            "filter.utm_term": "UTM-term",
            "filter.tags": "Tags",
            "filter.tag": "Tag",
            "filter.event_name": "Gebeurtenis",
            "filter.event_meta_key": "Gebeurtenis Meta-sleutel",
            "filter.event_meta": "Gebeurtenis Meta",
            "operator.equal": "is",
            "operator.unequal": "is niet",
            "operator.includes": "omvat",
            "operator.excludes": "sluit uit"
        },
        "it": {
            "title.add": "Aggiungi imbuto",
            "title.edit": "Modifica imbuto",
            "form.name": "Nome",
            "form.step.name": "Nome del passaggio",
            "form.step.type": "Tipo di filtro",
            "form.step.operator": "Operatore",
            "form.step.key": "Chiave",
            "form.step.value": "Valore",
            "form.submit": "Salva imbuto",
            "toast.saved": "L'imbuto è stato salvato.",
            "step.1": "Passaggio 1",
            "step.2": "Passaggio 2",
            "step.add": "Aggiungi passaggio",
            "step.up": "Sposta passaggio in alto",
            "step.down": "Sposta passaggio in basso",
            "step.remove": "Rimuovi passaggio",
            "toast.steps.max": "Un imbuto non può avere più di 8 passaggi.",
            "toast.steps.min": "Un imbuto deve avere almeno 2 passaggi.",
            "filter.add": "Aggiungi filtro",
            "filter.remove": "Rimuovi filtro",
            "filter.path": "Percorso",
            "filter.entry_path": "Percorso di ingresso",
            "filter.exit_path": "Percorso di uscita",
            "filter.path_pattern": "Schema del percorso",
            "filter.path_regex": "Percorso Regex (sintassi RE2)",
            "filter.language": "Lingua",
            "filter.country": "Paese",
            "filter.region": "Regione",
            "filter.city": "Città",
            "filter.referrer": "Referrer",
            "filter.referrer_name": "Nome del referrer",
            "filter.os": "Sistema operativo",
            "filter.os_version": "Versione del sistema operativo",
            "filter.browser": "Browser",
            "filter.browser_version": "Versione del browser",
            "filter.platform": "Piattaforma",
            "filter.platform.desktop": "Desktop",
            "filter.platform.mobile": "Mobile",
            "filter.platform.unknown": "Sconosciuto",
            "filter.screen_class": "Classe dello schermo",
            "filter.utm_source": "Sorgente UTM",
            "filter.utm_medium": "Mezzo UTM",
            "filter.utm_campaign": "Campagna UTM",
            "filter.utm_content": "Contenuto UTM",
            "filter.utm_term": "Termine UTM",
            "filter.tags": "Tag",
            "filter.tag": "Tag",
            "filter.event_name": "Evento",
            "filter.event_meta_key": "Chiave Meta dell'evento",
            "filter.event_meta": "Meta dell'evento",
            "operator.equal": "è",
            "operator.unequal": "non è",
            "operator.includes": "include",
            "operator.excludes": "esclude"
        },
        "pt": {
            "title.add": "Adicionar funil",
            "title.edit": "Editar funil",
            "form.name": "Nome",
            "form.step.name": "Nome do passo",
            "form.step.type": "Tipo de filtro",
            "form.step.operator": "Operador",
            "form.step.key": "Chave",
            "form.step.value": "Valor",
            "form.submit": "Salvar funil",
            "toast.saved": "O funil foi salvo.",
            "step.1": "Passo 1",
            "step.2": "Passo 2",
            "step.add": "Adicionar passo",
            "step.up": "Mover passo para cima",
            "step.down": "Mover passo para baixo",
            "step.remove": "Remover passo",
            "toast.steps.max": "Um funil não pode ter mais de 8 passos.",
            "toast.steps.min": "Um funil deve ter no mínimo 2 passos.",
            "filter.add": "Adicionar filtro",
            "filter.remove": "Remover filtro",
            "filter.path": "Caminho",
            "filter.entry_path": "Caminho de entrada",
            "filter.exit_path": "Caminho de saída",
            "filter.path_pattern": "Padrão de caminho",
            "filter.path_regex": "Regex de caminho (sintaxe RE2)",
            "filter.language": "Idioma",
            "filter.country": "País",
            "filter.region": "Região",
            "filter.city": "Cidade",
            "filter.referrer": "Referenciador",
            "filter.referrer_name": "Nome do referenciador",
            "filter.os": "Sistema operacional",
            "filter.os_version": "Versão do sistema operacional",
            "filter.browser": "Navegador",
            "filter.browser_version": "Versão do navegador",
            "filter.platform": "Plataforma",
            "filter.platform.desktop": "Desktop",
            "filter.platform.mobile": "Móvel",
            "filter.platform.unknown": "Desconhecido",
            "filter.screen_class": "Classe de tela",
            "filter.utm_source": "Fonte UTM",
            "filter.utm_medium": "Meio UTM",
            "filter.utm_campaign": "Campanha UTM",
            "filter.utm_content": "Conteúdo UTM",
            "filter.utm_term": "Termo UTM",
            "filter.tags": "Tags",
            "filter.tag": "Tag",
            "filter.event_name": "Evento",
            "filter.event_meta_key": "Chave Meta do evento",
            "filter.event_meta": "Meta do evento",
            "operator.equal": "é",
            "operator.unequal": "não é",
            "operator.includes": "inclui",
            "operator.excludes": "exclui"
        },
        "ja": {
            "title.add": "ファネルを追加",
            "title.edit": "ファネルを編集",
            "form.name": "名前",
            "form.step.name": "ステップ名",
            "form.step.type": "フィルタータイプ",
            "form.step.operator": "演算子",
            "form.step.key": "キー",
            "form.step.value": "値",
            "form.submit": "ファネルを保存",
            "toast.saved": "ファネルが保存されました。",
            "step.1": "ステップ 1",
            "step.2": "ステップ 2",
            "step.add": "ステップを追加",
            "step.up": "ステップを上に移動",
            "step.down": "ステップを下に移動",
            "step.remove": "ステップを削除",
            "toast.steps.max": "ファネルは8ステップを超えることはできません。",
            "toast.steps.min": "ファネルは最低2ステップ必要です。",
            "filter.add": "フィルターを追加",
            "filter.remove": "フィルターを削除",
            "filter.path": "パス",
            "filter.entry_path": "エントリパス",
            "filter.exit_path": "エグジットパス",
            "filter.path_pattern": "パスパターン",
            "filter.path_regex": "パス正規表現 (RE2 構文)",
            "filter.language": "言語",
            "filter.country": "国",
            "filter.region": "地域",
            "filter.city": "都市",
            "filter.referrer": "リファラー",
            "filter.referrer_name": "リファラー名",
            "filter.os": "OS",
            "filter.os_version": "OSバージョン",
            "filter.browser": "ブラウザ",
            "filter.browser_version": "ブラウザバージョン",
            "filter.platform": "プラットフォーム",
            "filter.platform.desktop": "デスクトップ",
            "filter.platform.mobile": "モバイル",
            "filter.platform.unknown": "不明",
            "filter.screen_class": "スクリーンクラス",
            "filter.utm_source": "UTMソース",
            "filter.utm_medium": "UTMメディア",
            "filter.utm_campaign": "UTMキャンペーン",
            "filter.utm_content": "UTMコンテンツ",
            "filter.utm_term": "UTM用語",
            "filter.tags": "タグ",
            "filter.tag": "タグ",
            "filter.event_name": "イベント",
            "filter.event_meta_key": "イベントメタキー",
            "filter.event_meta": "イベントメタ",
            "operator.equal": "は",
            "operator.unequal": "ではない",
            "operator.includes": "含む",
            "operator.excludes": "除外する"
        }
    }
</i18n>
