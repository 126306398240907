<template>
    <section class="row">
        <div>
            <h3>{{t("title")}}</h3>
            <small>
                {{t("text_0")}}
                <br />
                <a href="https://docs.pirsch.io/get-started/ga-import" target="_blank" style="display: inline-block; margin-top: 12px;">{{t("link")}}</a>
            </small>
        </div>
        <div>
            <button class="white" v-on:click="signIn" v-if="!importJob">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" fill-rule="evenodd"><path d="M17.64 9.205c0-.638-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.258h2.909c1.702-1.567 2.684-3.874 2.684-6.615z" fill="#4285f4" /><path d="M9 18c2.43 0 4.467-.806 5.956-2.181l-2.909-2.258c-.806.54-1.837.859-3.048.859-2.344 0-4.328-1.583-5.036-3.71H.957v2.332C2.438 15.983 5.482 18 9 18z" fill="#34a853" /><path d="M3.964 10.71A5.41 5.41 0 0 1 3.682 9a5.41 5.41 0 0 1 .282-1.71V4.958H.957C.348 6.173 0 7.548 0 9s.348 2.827.957 4.042l3.007-2.332z" fill="#fbbc05" /><path d="M9 3.58c1.321 0 2.508.454 3.441 1.346l2.581-2.581C13.463.892 11.426 0 9 0 5.482 0 2.438 2.017.957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z" fill="#ea4335" /></svg>
                <span>{{t("google")}}</span>
            </button>
            <template v-else-if="importJob && importJob.domain">
                <p>{{t("text_1")}}</p>
                <button class="red" style="margin-top: 12px;" v-on:click="cancel">{{t("cancel")}}</button>
            </template>
            <template v-else>
                <h5>{{t("website.title")}}</h5>
                <form v-on:submit.prevent="startImport" style="margin-top: 16px;">
                    <FormSelect :label="t('form.property')" name="property" v-model="property" :options="properties" :err="validationError('property')" />
                    <FormInput :label="t('form.start')" name="from" v-model="from" type="date" />
                    <FormInput :label="t('form.end')" name="to" v-model="to" type="date" />
                    <FormSubmit :value="t('form.submit')" />
                </form>
                <h5 style="margin-top: 16px;">{{t("google.title")}}</h5>
                <p style="margin-top: 4px;">
                    <strong>{{importJob.google_user_email}}</strong>
                </p>
                <button class="red" style="margin-top: 12px;" v-on:click="cancel">{{t("cancel")}}</button>
            </template>
        </div>
    </section>
</template>

<script lang="ts">
    import {defineComponent, onMounted, ref} from "vue";
    import {getQueryParam, setQueryParam} from "@/util/url";
    import Cookies from "js-cookie";
    import {ImportJob} from "@/model/ImportJob";
    import {ImportRepo} from "@/repositories/ImportRepo";
    import {useError} from "@/components/error";
    import {APIError} from "@/repositories/APIError";
    import {SelectOption} from "@/components/form/SelectOption";
    import {GAProfile} from "@/model/GAProfile";
    import FormSelect from "@/components/form/FormSelect.vue";
    import FormInput from "@/components/form/FormInput.vue";
    import FormSubmit from "@/components/form/FormSubmit.vue";
    import {dateParam} from "@/util/format";
    import {useToastStore} from "@/store/ToastStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FormSelect,
            FormInput,
            FormSubmit
        },
        setup() {
            const {t} = useI18n();
            const {success, error} = useToastStore();
            const {domain} = storeToRefs(useDomainStore());
            const {setError, resetError, validationError} = useError();
            const importJob = ref<ImportJob | undefined>();
            const properties = ref<SelectOption[]>([]);
            const property = ref("");
            const from = ref("2015-08-14");
            const to = ref(dateParam(domain.value.def_time));

            onMounted(async () => {
                showConnectResult();
                importJob.value = await ImportRepo.get(domain.value.id) as ImportJob;

                if (importJob.value) {
                    await loadProfiles();
                }
            });

            async function loadProfiles() {
                try {
                    const profiles = await ImportRepo.profiles(domain.value.id) as GAProfile[];

                    for (let i = 0; i < profiles.length; i++) {
                        properties.value.push({
                            label: profiles[i].name,
                            value: profiles[i].id
                        });
                    }
                } catch (e) {
                    setError(e as APIError);
                }
            }

            function showConnectResult() {
                const showSuccess = getQueryParam("ga_success");
                const err = getQueryParam("ga_error");
                setQueryParam("ga_success");
                setQueryParam("ga_error");

                if (showSuccess) {
                    success(t("toast.connected"));
                } else if (err) {
                    error({validation: {}, error: [err]});
                }
            }

            function signIn() {
                const accessToken = Cookies.get("access_token");
                location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}&redirect_uri=${import.meta.env.VITE_APP_GOOGLE_IMPORT_REDIRECT_URI}&response_type=code&access_type=offline&prompt=consent&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/analytics.readonly&state=${accessToken},${domain.value.id}`;
            }

            async function cancel() {
                try {
                    resetError();
                    await ImportRepo.cancelImport(domain.value.id);
                    importJob.value = undefined;
                    success(t("toast.cancelled"));
                } catch (e) {
                    setError(e as APIError);
                }
            }

            async function startImport() {
                try {
                    resetError();
                    importJob.value = await ImportRepo.import(domain.value.id, property.value, from.value, to.value) as ImportJob;
                    success(t("toast.started"));
                } catch (e) {
                    setError(e as APIError);
                }
            }

            return {
                t,
                validationError,
                importJob,
                properties,
                property,
                from,
                to,
                signIn,
                cancel,
                startImport
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Google Analytics Import",
            "text_0": "Import your statistics from Google Universal Analytics.",
            "link": "Read the Docs",
            "google": "Continue with Google",
            "text_1": "An import job is running. We will notify you once it has finished.",
            "cancel": "Cancel Import",
            "website.title": "Select the website to import",
            "form.property": "Property",
            "form.start": "Start Date",
            "form.end": "End Date",
            "form.submit": "Start Import",
            "google.title": "Linked Google account",
            "toast.connected": "Your account has been connected.",
            "toast.cancelled": "The import has been cancelled.",
            "toast.started": "The import has been started."
        },
        "de": {
            "title": "Google Analytics Import",
            "text_0": "Importiere deine Statistiken von Google Universal Analytics.",
            "link": "Dokumentation lesen",
            "google": "Mit Google fortfahren",
            "text_1": "Ein Importauftrag wird ausgeführt. Wir benachrichtigen dich, sobald er abgeschlossen ist.",
            "cancel": "Import abbrechen",
            "website.title": "Wähle die Webseite zum Importieren",
            "form.property": "Eigenschaft",
            "form.start": "Startdatum",
            "form.end": "Enddatum",
            "form.submit": "Import starten",
            "google.title": "Verknüpftes Google-Konto",
            "toast.connected": "Dein Konto wurde verbunden.",
            "toast.cancelled": "Der Import wurde abgebrochen.",
            "toast.started": "Der Import wurde gestartet."
        },
        "es": {
            "title": "Importación de Google Analytics",
            "text_0": "Importa tus estadísticas de Google Universal Analytics.",
            "link": "Leer la documentación",
            "google": "Continuar con Google",
            "text_1": "Un trabajo de importación está en ejecución. Te notificaremos una vez que haya terminado.",
            "cancel": "Cancelar importación",
            "website.title": "Selecciona el sitio web para importar",
            "form.property": "Propiedad",
            "form.start": "Fecha de inicio",
            "form.end": "Fecha de finalización",
            "form.submit": "Iniciar importación",
            "google.title": "Cuenta de Google vinculada",
            "toast.connected": "Tu cuenta ha sido conectada.",
            "toast.cancelled": "La importación ha sido cancelada.",
            "toast.started": "La importación ha sido iniciada."
        },
        "fr": {
            "title": "Importation Google Analytics",
            "text_0": "Importez vos statistiques depuis Google Universal Analytics.",
            "link": "Lire la documentation",
            "google": "Continuer avec Google",
            "text_1": "Un travail d'importation est en cours. Nous vous informerons une fois qu'il sera terminé.",
            "cancel": "Annuler l'importation",
            "website.title": "Sélectionnez le site Web à importer",
            "form.property": "Propriété",
            "form.start": "Date de début",
            "form.end": "Date de fin",
            "form.submit": "Démarrer l'importation",
            "google.title": "Compte Google lié",
            "toast.connected": "Votre compte a été connecté.",
            "toast.cancelled": "L'importation a été annulée.",
            "toast.started": "L'importation a été lancée."
        },
        "nl": {
            "title": "Google Analytics importeren",
            "text_0": "Importeer je statistieken van Google Universal Analytics.",
            "link": "Lees de documentatie",
            "google": "Doorgaan met Google",
            "text_1": "Een importtaak wordt uitgevoerd. We zullen je op de hoogte stellen zodra het is voltooid.",
            "cancel": "Import annuleren",
            "website.title": "Selecteer de website om te importeren",
            "form.property": "Eigenschap",
            "form.start": "Startdatum",
            "form.end": "Einddatum",
            "form.submit": "Import starten",
            "google.title": "Gekoppeld Google-account",
            "toast.connected": "Je account is verbonden.",
            "toast.cancelled": "De import is geannuleerd.",
            "toast.started": "De import is gestart."
        },
        "it": {
            "title": "Importazione Google Analytics",
            "text_0": "Importa le tue statistiche da Google Universal Analytics.",
            "link": "Leggi la documentazione",
            "google": "Continua con Google",
            "text_1": "Un'attività di importazione è in esecuzione. Ti avviseremo una volta completata.",
            "cancel": "Annulla importazione",
            "website.title": "Seleziona il sito web da importare",
            "form.property": "Proprietà",
            "form.start": "Data di inizio",
            "form.end": "Data di fine",
            "form.submit": "Avvia importazione",
            "google.title": "Account Google collegato",
            "toast.connected": "Il tuo account è stato collegato.",
            "toast.cancelled": "L'importazione è stata annullata.",
            "toast.started": "L'importazione è iniziata."
        },
        "pt": {
            "title": "Importação do Google Analytics",
            "text_0": "Importe suas estatísticas do Google Universal Analytics.",
            "link": "Leia a documentação",
            "google": "Continuar com Google",
            "text_1": "Um trabalho de importação está em execução. Vamos notificá-lo assim que terminar.",
            "cancel": "Cancelar importação",
            "website.title": "Selecione o site para importar",
            "form.property": "Propriedade",
            "form.start": "Data de início",
            "form.end": "Data de término",
            "form.submit": "Iniciar importação",
            "google.title": "Conta Google vinculada",
            "toast.connected": "Sua conta foi conectada.",
            "toast.cancelled": "A importação foi cancelada.",
            "toast.started": "A importação foi iniciada."
        },
        "ja": {
            "title": "Google アナリティクス インポート",
            "text_0": "Google Universal Analytics から統計情報をインポートします。",
            "link": "ドキュメントを読む",
            "google": "Googleで続行",
            "text_1": "インポートジョブが実行中です。完了次第、通知いたします。",
            "cancel": "インポートをキャンセル",
            "website.title": "インポートするウェブサイトを選択",
            "form.property": "プロパティ",
            "form.start": "開始日",
            "form.end": "終了日",
            "form.submit": "インポートを開始",
            "google.title": "リンクされた Google アカウント",
            "toast.connected": "アカウントが接続されました。",
            "toast.cancelled": "インポートがキャンセルされました。",
            "toast.started": "インポートが開始されました。"
        }
    }
</i18n>
