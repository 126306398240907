<template>
    <ConversionGoalsDetails :open="tableDetails === 0" v-on:close="tableDetails = -1" />
    <KeywordsDetails :open="tableDetails === 2" v-on:close="tableDetails = -1" v-if="domain.gsc_domain" />
    <template v-if="domain.id && loaded">
        <Charts v-if="getSettings(isPrivate ? 'graphs' : 'graphs_public')" />
        <div class="dashboard">
            <Pages v-if="getSettings(isPrivate ? 'pages' : 'pages_public')" />
            <Sources v-if="getSettings(isPrivate ? 'sources' : 'sources_public')" />
            <ConversionGoals v-on:enlarge="tableDetails = 0" v-if="getSettings(isPrivate ? 'goals' : 'goals_public')" />
            <EventsTags v-if="getSettings(isPrivate ? 'events' : 'events_public')" />
            <Visitors v-if="getSettings(isPrivate ? 'location' : 'location_public')" />
            <System v-if="getSettings(isPrivate ? 'devices' : 'devices_public')" />
            <Keywords :visible="!!(!path && domain.gsc_domain)"
                v-on:enlarge="tableDetails = 2"
                v-show="!path && domain.gsc_domain"
                v-if="getSettings(isPrivate ? 'keywords' : 'keywords_public')" />
        </div>
    </template>
    <DomainNotFound v-else-if="loaded" />
    <LoadingPage :loading="!loaded" />
</template>

<script lang="ts">
    import {defineComponent, ref, computed} from "vue";
    import {isDashboard} from "@/util/domain";
    import {storeToRefs} from "pinia";
    import {useUserStore} from "@/store/UserStore";
    import {useDomainStore} from "@/store/DomainStore";
    import {useFilterStore} from "@/store/FilterStore";
    import Sources from "@/components/statistics/Sources.vue";
    import Pages from "@/components/statistics/Pages.vue";
    import Keywords from "@/components/statistics/Keywords.vue";
    import KeywordsDetails from "@/components/statistics/KeywordsDetails.vue";
    import ConversionGoals from "@/components/statistics/ConversionGoals.vue";
    import ConversionGoalsDetails from "@/components/statistics/ConversionGoalsDetails.vue";
    import Visitors from "@/components/statistics/Visitors.vue";
    import System from "@/components/statistics/System.vue";
    import Charts from "@/components/statistics/Charts.vue";
    import EventsTags from "@/components/statistics/EventsTags.vue";
    import DomainNotFound from "@/components/domain/DomainNotFound.vue";
    import LoadingPage from "@/components/bits/LoadingPage.vue";

    export default defineComponent({
        components: {
            LoadingPage,
            DomainNotFound,
            EventsTags,
            Pages,
            Sources,
            ConversionGoals,
            ConversionGoalsDetails,
            Visitors,
            Keywords,
            KeywordsDetails,
            System,
            Charts
        },
        setup() {
            const domainStore = useDomainStore();
            const {user} = storeToRefs(useUserStore());
            const {domain, loaded} = storeToRefs(domainStore);
            const {hasField} = useFilterStore();
            const path = computed(() => hasField("path"));
            const tableDetails = ref(-1);

            return {
                isPrivate: isDashboard(),
                user,
                domain,
                loaded,
                getSettings: domainStore.getSettings,
                path,
                tableDetails
            };
        }
    });
</script>
