<template>
    <component :is="component"
        panel="exit_pages"
        :columns="columns"
        :entries="data"
        :loading="isLoading(limitless ? 'exit_pages' : 'top_exit_pages')"
        :loadMore="loadMore(limitless ? 'exit_pages' : 'top_exit_pages')"
        :newTab="true"
        :showTitle="false"
        :showEnlarge="false"
        :showBar="true"
        :selectable="true"
        :showDetails="showDetails"
        :valueTitle="t('title')"
        v-on:filter="filterByExitPage"
        v-on:open="openPage"
        v-on:search="searchAndSort" />
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {formatAndShortenNumber, formatPercent} from "@/util/format";
    import {openPage} from "@/components/statistics/page";
    import {ListEntry} from "@/model/ListEntry";
    import {useFilterStore} from "@/store/FilterStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {useTable} from "@/components/panels/table";
    import {Row} from "@/components/panels/Row";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        props: {
            component: {type: String, default: "StatisticsList"},
            limitless: {type: Boolean, default: false},
            showDetails: {type: Boolean, default: false},
            visible: {type: Boolean, required: true}
        },
        setup(props) {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const {isLoading, loadMore, loadExitPages, exitPages} = useStatisticsStore();
            const {setField} = filterStore;
            const {searchAndSort} = useTable(loadExitPages);
            const rawData = computed(() => exitPages(props.limitless));
            const data = ref<Row[]>([]);
            const columns = computed(() => [
                {key: "label", label: t("columns.path"), order_by: "exit_path"},
                {key: "value", label: t("columns.exits"), transform: (v: number) => formatAndShortenNumber(v), order_by: "exits"},
                {key: "visitors", label: t("columns.visitors"), transform: (v: number) => formatAndShortenNumber(v), order_by: "visitors"},
                {key: "relative_value", label: t("columns.exit_rate"), transform: (v: number) => formatPercent(v)+"%", order_by: "-"}
            ]);

            onMounted(() => {
                if (!props.limitless) {
                    localStorage.removeItem("exit_pages_table");
                }
            });

            watch([rawData, () => props.visible], () => {
                if (props.visible) {
                    data.value = rawData.value;
                }
            });

            function filterByExitPage(entry: ListEntry) {
                setField("exit_path", entry.label || "null");
            }

            return {
                t,
                columns,
                isLoading,
                loadMore,
                data,
                filterByExitPage,
                openPage,
                searchAndSort
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "title": "Exits",
            "columns.path": "Path",
            "columns.exits": "Exits",
            "columns.visitors": "Visitors",
            "columns.exit_rate": "Exit Rate"
        },
        "de": {
            "title": "Ausstiege",
            "columns.path": "Pfad",
            "columns.exits": "Ausstiege",
            "columns.visitors": "Besucher",
            "columns.exit_rate": "Ausstiegsrate"
        },
        "es": {
            "title": "Salidas",
            "columns.path": "Ruta",
            "columns.exits": "Salidas",
            "columns.visitors": "Visitantes",
            "columns.exit_rate": "Tasa de Salida"
        },
        "fr": {
            "title": "Sorties",
            "columns.path": "Chemin",
            "columns.exits": "Sorties",
            "columns.visitors": "Visiteurs",
            "columns.exit_rate": "Taux de Sortie"
        },
        "nl": {
            "title": "Uitgangen",
            "columns.path": "Pad",
            "columns.exits": "Uitgangen",
            "columns.visitors": "Bezoekers",
            "columns.exit_rate": "Uitstappercentage"
        },
        "it": {
            "title": "Uscite",
            "columns.path": "Percorso",
            "columns.exits": "Uscite",
            "columns.visitors": "Visitatori",
            "columns.exit_rate": "Tasso di Uscita"
        },
        "pt": {
            "title": "Saídas",
            "columns.path": "Caminho",
            "columns.exits": "Saídas",
            "columns.visitors": "Visitantes",
            "columns.exit_rate": "Taxa de Saída"
        },
        "ja": {
            "title": "退出",
            "columns.path": "パス",
            "columns.exits": "退出",
            "columns.visitors": "訪問者",
            "columns.exit_rate": "退出率"
        }
    }
</i18n>
