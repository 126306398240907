import {ref, Ref} from "vue";
import {APIError} from "@/repositories/APIError";
import {useI18n} from "vue-i18n";
import {errors} from "@/store/errors";

interface Error {
    err: Ref<APIError>
    resetError(): void
    setError(errors: APIError): void
    validationError(name: string): string
}

function noError(): APIError {
    return {
        validation: {},
        error: []
    };
}

export function useError(): Error {
    const {locale} = useI18n();
    const err = ref<APIError>(noError());

    function resetError() {
        err.value = noError();
    }

    function setError(error: APIError) {
        err.value = error;
    }

    function validationError(name: string): string {
        const message = err.value.validation[name];
        return errors[locale.value][message];
    }

    return {
        err,
        resetError,
        setError,
        validationError
    };
}
