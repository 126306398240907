<template>
    <TotalVisitorsDetails :open="chartModal === 0" v-on:close="chartModal = -1" />
    <TotalViewsDetails :open="chartModal === 1" v-on:close="chartModal = -1" />
    <TotalSessionsDetails :open="chartModal === 2" v-on:close="chartModal = -1" />
    <TotalBouncesDetails :open="chartModal === 3" v-on:close="chartModal = -1" />
    <AverageTimeSpentDetails :open="chartModal === 4" v-on:close="chartModal = -1" />
    <div :class="{'charts no-select': true, 'hidden': activeChart === -1}">
        <div class="headline">
            <div class="metrics">
                <ChartSelection v-for="(chart, index) in charts"
                    :key="chart.title"
                    :active="activeChart === index && !mobile"
                    :clickable="index !== 4 || eventFilter || (index === 4 && !dayFilter)"
                    :title="chart.title"
                    :tooltip="chart.tooltip"
                    :sum="chart.sum"
                    :previousSum="chart.previousSum"
                    :growth="chart.growth"
                    :arrowUpGreen="chart.arrowUpGreen === undefined ? true : chart.arrowUpGreen"
                    :formatValue="chart.formatValue"
                    v-on:click="openChart(index)" />
            </div>
            <Refresh v-if="mobile" />
            <transition name="fade">
                <ChartType v-on:switch="setChartType" v-if="!mobile && activeChart > -1" />
            </transition>
        </div>
        <Expand :disabled="loading">
            <div v-if="!mobile && activeChart > -1">
                <Chart :labels="labels"
                    :previousLabels="previousLabels"
                    :data="data"
                    :previousData="previousData"
                    :type="chartType"
                    :growth="charts[activeChart].growth"
                    :arrowUpGreen="charts[activeChart].arrowUpGreen === undefined ? true : charts[activeChart].arrowUpGreen"
                    :formatYAxis="charts[activeChart].formatYAxis"
                    :formatValue="charts[activeChart].formatValue"
                    :loading="loading"
                    v-on:select="select" />
            </div>
        </Expand>
        <transition name="fade">
            <Loading :loading="loading" type="loader" />
        </transition>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, onMounted, ref, watch} from "vue";
    import {useData} from "@/components/panels/data";
    import {formatPercent, formatSeconds} from "@/util/format";
    import ChartSelection from "@/components/panels/ChartSelection.vue";
    import ChartType from "@/components/panels/ChartType.vue";
    import Chart from "@/components/panels/Chart.vue";
    import TotalBouncesDetails from "@/components/statistics/TotalBouncesDetails.vue";
    import TotalSessionsDetails from "@/components/statistics/TotalSessionsDetails.vue";
    import TotalViewsDetails from "@/components/statistics/TotalViewsDetails.vue";
    import TotalVisitorsDetails from "@/components/statistics/TotalVisitorsDetails.vue";
    import AverageTimeSpentDetails from "@/components/statistics/AverageTimeSpentDetails.vue";
    import Expand from "@/components/bits/Expand.vue";
    import Loading from "@/components/bits/Loading.vue";
    import Refresh from "@/components/bits/Refresh.vue";
    import dayjs from "dayjs";
    import {useDomainStore} from "@/store/DomainStore";
    import {storeToRefs} from "pinia";
    import {getField, useFilterStore} from "@/store/FilterStore";
    import {useChartStore} from "@/store/ChartStore";
    import {useStatisticsStore} from "@/store/StatisticsStore";
    import {GraphStats} from "@/model/GraphStats";
    import {useMobile} from "@/components/mobile";
    import {useI18n} from "vue-i18n";
    import {Time} from "@/model/TimeRange";

    interface ChartEntry {
        source: string
        title: string
        sum: number
        previousSum?: number
        growth: number
        arrowUpGreen?: boolean
        formatYAxis?: typeof formatPercent | typeof formatSeconds
        formatValue?: typeof formatPercent | typeof formatSeconds
        tooltip?: string
    }

    export default defineComponent({
        components: {
            ChartSelection,
            ChartType,
            Chart,
            TotalBouncesDetails,
            TotalSessionsDetails,
            TotalViewsDetails,
            TotalVisitorsDetails,
            AverageTimeSpentDetails,
            Expand,
            Loading,
            Refresh
        },
        setup() {
            const {t} = useI18n();
            const filterStore = useFilterStore();
            const statisticsStore = useStatisticsStore();
            const chartStore = useChartStore();
            const {domain} = storeToRefs(useDomainStore());
            const {set, hasField} = filterStore;
            const {filter, dayFilter, hourFilter, disableFilter} = storeToRefs(filterStore);
            const {set: setChartType} = chartStore;
            const {type: chartType} = storeToRefs(chartStore);
            const {mobile} = useMobile();
            const pathFilter = computed(() => hasField("path"));
            const eventFilter = computed(() => hasField("event"));
            const customMetricFilter = computed(() => hasField("custom_metric_key") && hasField("custom_metric_type"));
            const loading = computed(() => {
                if (hourFilter.value) {
                    return statisticsStore.isLoading("visitors_by_minute");
                }

                if (dayFilter.value) {
                    return statisticsStore.isLoading("visitors_by_hour");
                }

                if (activeChart.value === 4 && !eventFilter.value && !customMetricFilter.value) {
                    return statisticsStore.isLoading("average_time_spent");
                }

                return statisticsStore.isLoading("visitors");
            });
            const dataRaw = computed(() => {
                if (hourFilter.value) {
                    return statisticsStore.visitorsByMinute;
                }

                if (dayFilter.value) {
                    return statisticsStore.visitorsByHour;
                }

                if (activeChart.value === 4 && !eventFilter.value && !customMetricFilter.value) {
                    return statisticsStore.averageTimeSpent;
                }

                return statisticsStore.visitors;
            });
            const previousDataRaw = computed(() => {
                if (hourFilter.value) {
                    return statisticsStore.previousVisitorsByMinute;
                }

                if (dayFilter.value) {
                    return statisticsStore.previousVisitorsByHour;
                }

                if (activeChart.value === 4 && !eventFilter.value && !customMetricFilter.value) {
                    return statisticsStore.previousAverageTimeSpent;
                }

                return statisticsStore.previousVisitors;
            });
            const avgTimeSpent = computed(() => statisticsStore.averageTimeSpent);
            const previousAvgTimeSpent = computed(() => statisticsStore.previousAverageTimeSpent);
            const charts = computed(() => {
                let timeSpent = 0;

                if (avgTimeSpent.value && avgTimeSpent.value.length) {
                    let sum = 0;
                    let days = 0;

                    for (let i = 0; i < avgTimeSpent.value.length; i++) {
                        if (avgTimeSpent.value[i].average_time_spent_seconds > 0) {
                            sum += avgTimeSpent.value[i].average_time_spent_seconds;
                            days++;
                        }
                    }

                    if (days) {
                        timeSpent = sum/days;
                    }
                }

                let previousTimeSpent: number | undefined = undefined;

                if (previousAvgTimeSpent.value && previousAvgTimeSpent.value.length) {
                    previousTimeSpent = 0;
                    let sum = 0;
                    let days = 0;

                    for (let i = 0; i < previousAvgTimeSpent.value.length; i++) {
                        if (previousAvgTimeSpent.value[i].average_time_spent_seconds > 0) {
                            sum += previousAvgTimeSpent.value[i].average_time_spent_seconds;
                            days++;
                        }
                    }

                    if (days) {
                        previousTimeSpent = sum/days;
                    }
                }

                const charts: Array<ChartEntry> = [
                    {
                        source: "visitors",
                        title: t("tabs.visitors"),
                        sum: statisticsStore.totalVisitors ? statisticsStore.totalVisitors.visitors : 0,
                        previousSum: statisticsStore.previousTotalVisitors ? statisticsStore.previousTotalVisitors.visitors : undefined,
                        growth: calculateGrowth(statisticsStore.previousTotalVisitors?.visitors, statisticsStore.totalVisitors?.visitors, statisticsStore.growth?.visitors_growth)
                    },
                    {
                        source: "views",
                        title: t("tabs.page_views"),
                        sum: statisticsStore.totalVisitors ? statisticsStore.totalVisitors.views : 0,
                        previousSum: statisticsStore.previousTotalVisitors ? statisticsStore.previousTotalVisitors.views : undefined,
                        growth: calculateGrowth(statisticsStore.previousTotalVisitors?.views, statisticsStore.totalVisitors?.views, statisticsStore.growth?.views_growth)
                    },
                    {
                        source: "sessions",
                        title: t("tabs.sessions"),
                        sum: statisticsStore.totalVisitors ? statisticsStore.totalVisitors.sessions : 0,
                        previousSum: statisticsStore.previousTotalVisitors ? statisticsStore.previousTotalVisitors.sessions : undefined,
                        growth: calculateGrowth(statisticsStore.previousTotalVisitors?.sessions, statisticsStore.totalVisitors?.sessions, statisticsStore.growth?.sessions_growth)
                    },
                    {
                        source: "bounce_rate",
                        title: t("tabs.bounce_rate"),
                        sum: statisticsStore.totalVisitors ? statisticsStore.totalVisitors.bounce_rate : 0,
                        previousSum: statisticsStore.previousTotalVisitors ? statisticsStore.previousTotalVisitors.bounce_rate : undefined,
                        growth: calculateGrowth(statisticsStore.previousTotalVisitors?.bounce_rate, statisticsStore.totalVisitors.bounce_rate, statisticsStore.growth?.bounces_growth),
                        arrowUpGreen: false,
                        formatYAxis: (value: number) => formatPercent(value) + "%",
                        formatValue: (value: number) => formatPercent(value) + "%"
                    }
                ];

                if (!eventFilter.value) {
                    charts.push(
                        {
                            source: "average_time_spent_seconds",
                            title: pathFilter.value ? t("tabs.time") : t("tabs.duration"),
                            sum: timeSpent,
                            previousSum: previousTimeSpent,
                            growth: calculateGrowth(previousTimeSpent, timeSpent, statisticsStore.growth?.time_spent_growth),
                            formatYAxis: formatSeconds,
                            formatValue: formatSeconds,
                            tooltip: pathFilter.value ? t("tabs.time.tooltip") : t("tabs.duration.tooltip")
                        }
                    );
                }

                if (pathFilter.value || eventFilter.value) {
                    charts.push({
                        source: "cr",
                        title: t("tabs.cr"),
                        sum: statisticsStore.totalVisitors ? statisticsStore.totalVisitors.cr : 0,
                        previousSum: statisticsStore.previousTotalVisitors ? statisticsStore.previousTotalVisitors.cr : undefined,
                        growth: calculateGrowth(statisticsStore.previousTotalVisitors?.cr, statisticsStore.totalVisitors?.cr, statisticsStore.growth?.cr_growth),
                        formatValue: (n: number) => formatPercent(n)+"%",
                        formatYAxis: (n: number) => formatPercent(n)+"%"
                    });
                }

                if (customMetricFilter.value) {
                    charts.push({
                        source: "custom_metric_avg",
                        title: t("tabs.avg", {key: getField(filter.value, "custom_metric_key")}),
                        sum: statisticsStore.totalVisitors ? statisticsStore.totalVisitors.custom_metric_avg : 0,
                        previousSum: statisticsStore.previousTotalVisitors ? statisticsStore.previousTotalVisitors.custom_metric_avg : undefined,
                        growth: calculateGrowth(statisticsStore.previousTotalVisitors?.custom_metric_avg, statisticsStore.totalVisitors?.custom_metric_avg, statisticsStore.growth?.custom_metric_avg_growth)
                    }, {
                        source: "custom_metric_total",
                        title: t("tabs.total", {key: getField(filter.value, "custom_metric_key")}),
                        sum: statisticsStore.totalVisitors ? statisticsStore.totalVisitors.custom_metric_total : 0,
                        previousSum: statisticsStore.previousTotalVisitors ? statisticsStore.previousTotalVisitors.custom_metric_total : undefined,
                        growth: calculateGrowth(statisticsStore.previousTotalVisitors?.custom_metric_total, statisticsStore.totalVisitors?.custom_metric_total, statisticsStore.growth?.custom_metric_total_growth)
                    });
                }

                return charts;
            });
            const sourceField = computed(() => activeChart.value > -1 && activeChart.value < charts.value.length ? charts.value[activeChart.value].source : "");
            const activeChart = ref(0);
            const chartModal = ref(-1);
            const {data: previousData, labels: previousLabels} = useData(previousDataRaw, sourceField, dayFilter);

            onMounted(() => {
                let chart = parseInt(localStorage.getItem("chart") ?? "") ?? 0;

                if (chart < charts.value.length) {
                    if (!eventFilter.value && dayFilter.value && chart >= 4) {
                        chart = 0;
                    }

                    activeChart.value = chart;
                }
            });

            watch([domain, filter], () => {
                if (!eventFilter.value && dayFilter.value && activeChart.value >= 4) {
                    activeChart.value = 0;
                }
            });

            watch(customMetricFilter, (a, b) => {
                if (a !== b) {
                    activeChart.value = 0;
                }
            });

            function openChart(index: number) {
                if (index === 4 && dayFilter.value && !eventFilter.value) {
                    return;
                }

                if (mobile.value) {
                    activeChart.value = index;
                    chartModal.value = index;
                    localStorage.setItem("chart", index.toString());
                } else if (index === activeChart.value) {
                    activeChart.value = -1;
                    chartModal.value = -1;
                    localStorage.setItem("chart", "-1");
                } else {
                    activeChart.value = index;
                    chartModal.value = -1;
                    localStorage.setItem("chart", index.toString());
                }
            }

            function select(index: number) {
                if (disableFilter.value && dayFilter.value) {
                    return;
                }

                if (!dayFilter.value) {
                    const value = dataRaw.value[index] as GraphStats;
                    const date = value.day || value.week || value.month || value.year;
                    const from = dayjs(date).utc();
                    let to = dayjs(date).utc();

                    if (value.week) {
                        to = dayjs(to).utc().endOf("week");
                    } else if (value.month) {
                        to = dayjs(to).utc().endOf("month");
                    } else if (value.year) {
                        to = dayjs(to).utc().endOf("year");
                    }

                    set({
                        timeRange: {
                            from: from.toDate(),
                            to: to.toDate(),
                            start: 0
                        },
                        timeRangeIndex: -1,
                        scale: filter.value.scale === "year" ? "month" : "day"
                    });
                } else if (filter.value.timeRange) {
                    const value = dataRaw.value[index] as GraphStats;

                    if (value.hour) {
                        let to = dayjs(filter.value.timeRange.to).utc();
                        let toHour = value.hour+1;

                        if (toHour > 23) {
                            to = to.add(1, 'day').utc();
                            toHour = 0;
                        }

                        set({
                            timeRange: {
                                from: filter.value.timeRange.from,
                                to: to.toDate(),
                                start: 0,
                                fromTime: new Time(value.hour, 0),
                                toTime: new Time(toHour, 0),
                            },
                            timeRangeIndex: -1,
                            scale: "day"
                        });
                    }
                }
            }

            function calculateGrowth(previous: number | undefined, current: number | undefined, growth: number | undefined) {
                if (current === undefined) {
                    current = 0;
                }

                if (previous !== undefined) {
                    if (current == 0 && previous == 0) {
                        return 0
                    } else if (previous == 0) {
                        return 1
                    }

                    return (current - previous) / previous
                }

                return growth ?? 0;
            }

            return {
                ...useData(dataRaw, sourceField, dayFilter),
                previousData,
                previousLabels,
                setChartType,
                mobile,
                chartType,
                charts,
                loading,
                dayFilter,
                eventFilter,
                activeChart,
                chartModal,
                openChart,
                select
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "tabs.visitors": "Unique Visitors",
            "tabs.page_views": "Page Views",
            "tabs.sessions": "Sessions",
            "tabs.bounce_rate": "Bounce Rate",
            "tabs.time": "Time on Page",
            "tabs.time.tooltip": "Average time spent on this page, excluding bounces",
            "tabs.duration": "Session Duration",
            "tabs.duration.tooltip": "Average time across all pages, excluding bounces",
            "tabs.cr": "Conversion Rate",
            "tabs.avg": "\"{key}\" Average",
            "tabs.total": "\"{key}\" Total"
        },
        "de": {
            "tabs.visitors": "Eindeutige Besucher",
            "tabs.page_views": "Seitenaufrufe",
            "tabs.sessions": "Sitzungen",
            "tabs.bounce_rate": "Absprungrate",
            "tabs.time": "Zeit auf der Seite",
            "tabs.time.tooltip": "Durchschnittliche Zeit, die auf dieser Seite verbracht wurde, ohne Absprünge",
            "tabs.duration": "Sitzungsdauer",
            "tabs.duration.tooltip": "Durchschnittliche Zeit auf allen Seiten, ohne Absprünge",
            "tabs.cr": "Konversionsrate",
            "tabs.avg": "\"{key}\" Durchschnitt",
            "tabs.total": "\"{key}\" Gesamt"
        },
        "es": {
            "tabs.visitors": "Visitantes Únicos",
            "tabs.page_views": "Vistas de Página",
            "tabs.sessions": "Sesiones",
            "tabs.bounce_rate": "Tasa de Rebote",
            "tabs.time": "Tiempo en la Página",
            "tabs.time.tooltip": "Tiempo promedio pasado en esta página, excluyendo rebotes",
            "tabs.duration": "Duración de la Sesión",
            "tabs.duration.tooltip": "Tiempo promedio en todas las páginas, excluyendo rebotes",
            "tabs.cr": "Tasa de Conversión",
            "tabs.avg": "Promedio de \"{key}\"",
            "tabs.total": "Total de \"{key}\""
        },
        "fr": {
            "tabs.visitors": "Visiteurs Uniques",
            "tabs.page_views": "Pages Vues",
            "tabs.sessions": "Sessions",
            "tabs.bounce_rate": "Taux de Rebond",
            "tabs.time": "Temps sur la Page",
            "tabs.time.tooltip": "Temps moyen passé sur cette page, sans compter les rebonds",
            "tabs.duration": "Durée de la Session",
            "tabs.duration.tooltip": "Temps moyen sur toutes les pages, sans compter les rebonds",
            "tabs.cr": "Taux de Conversion",
            "tabs.avg": "Moyenne de \"{key}\"",
            "tabs.total": "Total de \"{key}\""
        },
        "nl": {
            "tabs.visitors": "Unieke Bezoekers",
            "tabs.page_views": "Paginaweergaven",
            "tabs.sessions": "Sessies",
            "tabs.bounce_rate": "Bouncepercentage",
            "tabs.time": "Tijd op Pagina",
            "tabs.time.tooltip": "Gemiddelde tijd besteed op deze pagina, exclusief bounces",
            "tabs.duration": "Sessieduur",
            "tabs.duration.tooltip": "Gemiddelde tijd op alle pagina's, exclusief bounces",
            "tabs.cr": "Conversieratio",
            "tabs.avg": "\"{key}\" Gemiddelde",
            "tabs.total": "\"{key}\" Totaal"
        },
        "it": {
            "tabs.visitors": "Visitatori Unici",
            "tabs.page_views": "Visualizzazioni di Pagina",
            "tabs.sessions": "Sessioni",
            "tabs.bounce_rate": "Frequenza di Rimbalzo",
            "tabs.time": "Tempo sulla Pagina",
            "tabs.time.tooltip": "Tempo medio trascorso su questa pagina, escluse le uscite rapide",
            "tabs.duration": "Durata della Sessione",
            "tabs.duration.tooltip": "Tempo medio su tutte le pagine, escluse le uscite rapide",
            "tabs.cr": "Tasso di Conversione",
            "tabs.avg": "Media di \"{key}\"",
            "tabs.total": "Totale di \"{key}\""
        },
        "pt": {
            "tabs.visitors": "Visitantes Únicos",
            "tabs.page_views": "Visualizações de Página",
            "tabs.sessions": "Sessões",
            "tabs.bounce_rate": "Taxa de Rejeição",
            "tabs.time": "Tempo na Página",
            "tabs.time.tooltip": "Tempo médio gasto nesta página, excluindo rejeições",
            "tabs.duration": "Duração da Sessão",
            "tabs.duration.tooltip": "Tempo médio em todas as páginas, excluindo rejeições",
            "tabs.cr": "Taxa de Conversão",
            "tabs.avg": "Média de \"{key}\"",
            "tabs.total": "Total de \"{key}\""
        },
        "ja": {
            "tabs.visitors": "ユニーク訪問者",
            "tabs.page_views": "ページビュー",
            "tabs.sessions": "セッション",
            "tabs.bounce_rate": "直帰率",
            "tabs.time": "ページの滞在時間",
            "tabs.time.tooltip": "直帰を除く、このページに費やされた平均時間",
            "tabs.duration": "セッションの期間",
            "tabs.duration.tooltip": "直帰を除く、全ページの平均時間",
            "tabs.cr": "コンバージョン率",
            "tabs.avg": "\"{key}\" の平均",
            "tabs.total": "\"{key}\" の合計"
        }
    }
</i18n>
