<template>
    <div class="param no-select" ref="param">
        <span class="no-shrink">{{label}}</span>
        <span :class="{'is-not': inverted, 'includes': contains, 'excludes': notContains, 'is': !inverted && !contains}" v-on:click="toggleComparator">
            <template v-if="inverted">{{t("is_not")}}</template>
            <template v-else-if="contains">{{t("contains")}}</template>
            <template v-else-if="notContains">{{t("not_contains")}}</template>
            <template v-else>{{t("is")}}</template>
        </span>
        <div class="edit">
            <input type="text"
                name="filter"
                autocomplete="off"
                v-model="filterValue"
                v-on:keydown.up.stop.prevent="active--"
                v-on:keydown.down.stop.prevent="active++"
                v-on:keydown.esc.stop.prevent="closeDropdown"
                v-on:focus="dropdown = true"
                v-on:blur="updateValue"
                v-show="focus || filterValue == ''"
                placeholder=" "
                ref="input" />
            <span v-on:click.stop="focusInput">{{filterValue}}</span>
        </div>
        <transition name="fade-down">
            <FilterOptions :field="field"
                :value="filterValue"
                :active="active"
                :focus="focus"
                v-on:select="selectValue"
                v-on:active="setActive"
                v-show="dropdown" />
        </transition>
        <i class="icon icon-close" :title="t('remove')" v-on:click="remove"></i>
    </div>
</template>

<script lang="ts">
    import {computed, defineComponent, nextTick, onMounted, onUnmounted, ref} from "vue";
    import ISO6391 from "iso-639-1";
    import {whereAlpha2, whereCountry} from "iso-3166-1";
    import {debounce} from "@/util/debounce";
    import FilterOptions from "@/components/panels/FilterOptions.vue";
    import {filterFields, filterSkipContains} from "@/components/panels/filter_fields";
    import {useFilterParamsStore} from "@/store/FilterParamsStore";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        components: {
            FilterOptions
        },
        props: {
            field: {type: String, required: true},
            value: {type: String, required: true}
        },
        setup(props) {
            const {t} = useI18n();
            const {update, remove: removeField} = useFilterParamsStore();
            const param = ref(document.createElement("div"));
            const input = ref(document.createElement("input"));
            const inverted = computed(() => props.value && props.value.startsWith("!"));
            const contains = computed(() => props.value && props.value.startsWith("~"));
            const notContains = computed(() => props.value && props.value.startsWith("^"));
            const label = computed(() => {
                if (props.field.startsWith("meta_")) {
                    return `${t("filter.Event")} "${props.field.substring("meta_".length)}"`;
                } else if (props.field.startsWith("tag_")) {
                    return `${t("filter.Tag")} "${props.field.substring("tag_".length)}"`;
                }

                return t(`filter.${filterFields.get(props.field)}`);
            });
            const filterValue = ref("");
            const focus = ref(false);
            const dropdown = ref(false);
            const active = ref(0);
            let previousValue = "";
            let preventBlur = false;

            onMounted(() => {
                document.addEventListener("click", mouseEvents);

                if (props.value) {
                    let value = props.value ?? "";
                    previousValue = value;

                    if (props.field === "pattern") {
                        value = getPatternDisplayValue();
                    } else if (props.field === "custom_metric_type") {
                        value = value === "integer" ? "Integer" : "Decimal";
                    }

                    filterValue.value = mapLabel(value.startsWith("!") || value.startsWith("~") || value.startsWith("^") ? value.substring(1) : value);
                }

                if (props.value === "") {
                    focusInput();
                }
            });

            onUnmounted(() => {
                document.removeEventListener("click", mouseEvents);
            });

            function toggleComparator() {
                if (props.field === "custom_metric_type" || filterValue.value === "") {
                    return;
                }

                preventBlur = true;
                let newValue: string;

                if (inverted.value) {
                    if (filterSkipContains.includes(props.field)) {
                        newValue = mapValue(filterValue.value);
                    } else {
                        newValue = "~" + mapValue(filterValue.value);
                    }

                    if (props.field === "pattern") {
                        newValue = `${newValue}|${getPatternValue().slice(1)}`;
                    }
                } else if (contains.value) {
                    newValue = "^" + mapValue(filterValue.value);
                } else if (notContains.value) {
                    newValue = mapValue(filterValue.value);
                } else {
                    newValue = "!" + mapValue(filterValue.value);

                    if (props.field === "pattern") {
                        newValue = `${newValue}|!${getPatternValue()}`;
                    }
                }

                update(props.field, previousValue, newValue);
                previousValue = newValue;
            }

            const updateValue = debounce(() => setFilterField(), 300);

            function setFilterField(v?: string) {
                if (preventBlur) {
                    preventBlur = false;
                    return;
                }

                let value = mapValue(v || filterValue.value);

                if (value === previousValue || value === "") {
                    return;
                }

                if (inverted.value) {
                    value = "!" + value;
                } else if (contains.value) {
                    value = "~" + value;
                } else if (notContains.value) {
                    value = "^" + value;
                }

                update(props.field, previousValue, value);
                previousValue = value;
            }

            function mapLabel(value: string) {
                if (props.field === "language") {
                    value = ISO6391.getName(value) || value;
                } else if (props.field === "country") {
                    const country = whereAlpha2(value);
                    value = country ? country.country : value;
                } else if (props.field === "platform" && value) {
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                }

                return value;
            }

            function mapValue(value: string) {
                if (props.field === "language") {
                    return ISO6391.getCode(value) || value;
                } else if (props.field === "country") {
                    const country = whereCountry(value);
                    return country ? country.alpha2.toLowerCase() : value;
                } else if (props.field === "platform" && value) {
                    return value.charAt(0).toUpperCase() + value.slice(1);
                }

                return value;
            }

            function remove() {
                if (props.field === "custom_metric_key" || props.field === "custom_metric_type") {
                    removeField("custom_metric_key");
                    removeField("custom_metric_type");
                } else {
                    removeField(props.field, previousValue);
                }
            }

            function selectValue(label: string, value?: string) {
                filterValue.value = label;

                if (value) {
                    setFilterField(value);
                } else {
                    updateValue();
                }

                closeDropdown();
            }

            function focusInput() {
                if (props.field !== "pattern" && props.field !== "custom_metric_type") {
                    focus.value = true;
                    nextTick(() => {
                        input.value.focus();
                    });
                }
            }

            function mouseEvents(e: MouseEvent) {
                if (focus.value && !param.value.contains(e.target as Node)) {
                    closeDropdown();
                }
            }

            function closeDropdown() {
                focus.value = false;
                dropdown.value = false;
                active.value = 0;

                if (filterValue.value === "") {
                    if (previousValue === "") {
                        remove();
                    } else {
                        filterValue.value = mapLabel(previousValue.startsWith("!") || previousValue.startsWith("~") || previousValue.startsWith("^") ? previousValue.substring(1) : previousValue);
                    }
                }
            }

            function setActive(index: number) {
                active.value = index;
            }

            function getPatternValue() {
                const parts = props.value.split("|");

                if (parts.length > 1) {
                    return parts[1];
                }

                return parts[0];
            }

            function getPatternDisplayValue() {
                return props.value.split("|")[0];
            }

            return {
                t,
                inverted,
                contains,
                notContains,
                label,
                filterValue,
                param,
                input,
                focus,
                dropdown,
                active,
                toggleComparator,
                updateValue,
                remove,
                selectValue,
                focusInput,
                closeDropdown,
                setActive
            };
        }
    });
</script>

<i18n>
    {
        "en": {
            "is_not": "is not",
            "contains": "includes",
            "not_contains": "excludes",
            "is": "is",
            "remove": "Remove filter",
            "filter.Path": "Page",
            "filter.Entry Page": "Entry Page",
            "filter.Exit Page": "Exit Page",
            "filter.Pattern": "Pattern",
            "filter.Referrer": "Referrer",
            "filter.UTM Source": "UTM Source",
            "filter.UTM Medium": "UTM Medium",
            "filter.UTM Campaign": "UTM Campaign",
            "filter.UTM Content": "UTM Content",
            "filter.UTM Term": "UTM Term",
            "filter.Event": "Event",
            "filter.Custom Metric Type": "Custom Metric Type",
            "filter.Custom Metric Key": "Custom Metric Key",
            "filter.Country": "Country",
            "filter.Region": "Region",
            "filter.City": "City",
            "filter.Language": "Language",
            "filter.OS": "OS",
            "filter.Browser": "Browser",
            "filter.Platform": "Platform",
            "filter.Screen Class": "Screen Class",
            "filter.Tag": "Tag"
        },
        "de": {
            "is_not": "ist nicht",
            "contains": "enthält",
            "not_contains": "enthält nicht",
            "is": "ist",
            "remove": "Filter entfernen",
            "filter.Path": "Seite",
            "filter.Entry Page": "Eingangsseite",
            "filter.Exit Page": "Ausgangsseite",
            "filter.Pattern": "Muster",
            "filter.Referrer": "Referrer",
            "filter.UTM Source": "UTM-Quelle",
            "filter.UTM Medium": "UTM-Medium",
            "filter.UTM Campaign": "UTM-Kampagne",
            "filter.UTM Content": "UTM-Inhalt",
            "filter.UTM Term": "UTM-Begriff",
            "filter.Event": "Event",
            "filter.Custom Metric Type": "Benutzerdefinierter Metriktyp",
            "filter.Custom Metric Key": "Benutzerdefinierter Metrikschlüssel",
            "filter.Country": "Land",
            "filter.Region": "Region",
            "filter.City": "Stadt",
            "filter.Language": "Sprache",
            "filter.OS": "Betriebssystem",
            "filter.Browser": "Browser",
            "filter.Platform": "Plattform",
            "filter.Screen Class": "Bildschirmklasse",
            "filter.Tag": "Tag"
        },
        "es": {
            "is_not": "no es",
            "contains": "incluye",
            "not_contains": "excluye",
            "is": "es",
            "remove": "Eliminar filtro",
            "filter.Path": "Página",
            "filter.Entry Page": "Página de entrada",
            "filter.Exit Page": "Página de salida",
            "filter.Pattern": "Patrón",
            "filter.Referrer": "Referente",
            "filter.UTM Source": "Fuente UTM",
            "filter.UTM Medium": "Medio UTM",
            "filter.UTM Campaign": "Campaña UTM",
            "filter.UTM Content": "Contenido UTM",
            "filter.UTM Term": "Término UTM",
            "filter.Event": "Evento",
            "filter.Custom Metric Type": "Tipo de métrica personalizada",
            "filter.Custom Metric Key": "Clave de métrica personalizada",
            "filter.Country": "País",
            "filter.Region": "Región",
            "filter.City": "Ciudad",
            "filter.Language": "Idioma",
            "filter.OS": "SO",
            "filter.Browser": "Navegador",
            "filter.Platform": "Plataforma",
            "filter.Screen Class": "Clase de pantalla",
            "filter.Tag": "Etiqueta"
        },
        "fr": {
            "is_not": "n'est pas",
            "contains": "inclut",
            "not_contains": "exclut",
            "is": "est",
            "remove": "Supprimer le filtre",
            "filter.Path": "Page",
            "filter.Entry Page": "Page d'entrée",
            "filter.Exit Page": "Page de sortie",
            "filter.Pattern": "Modèle",
            "filter.Referrer": "Référent",
            "filter.UTM Source": "Source UTM",
            "filter.UTM Medium": "Moyen UTM",
            "filter.UTM Campaign": "Campagne UTM",
            "filter.UTM Content": "Contenu UTM",
            "filter.UTM Term": "Terme UTM",
            "filter.Event": "Événement",
            "filter.Custom Metric Type": "Type de métrique personnalisée",
            "filter.Custom Metric Key": "Clé de métrique personnalisée",
            "filter.Country": "Pays",
            "filter.Region": "Région",
            "filter.City": "Ville",
            "filter.Language": "Langue",
            "filter.OS": "OS",
            "filter.Browser": "Navigateur",
            "filter.Platform": "Plateforme",
            "filter.Screen Class": "Classe d'écran",
            "filter.Tag": "Tag"
        },
        "nl": {
            "is_not": "is niet",
            "contains": "omvat",
            "not_contains": "sluit uit",
            "is": "is",
            "remove": "Filter verwijderen",
            "filter.Path": "Pagina",
            "filter.Entry Page": "Ingangspagina",
            "filter.Exit Page": "Uitgangspagina",
            "filter.Pattern": "Patroon",
            "filter.Referrer": "Referrer",
            "filter.UTM Source": "UTM-bron",
            "filter.UTM Medium": "UTM-medium",
            "filter.UTM Campaign": "UTM-campagne",
            "filter.UTM Content": "UTM-inhoud",
            "filter.UTM Term": "UTM-term",
            "filter.Event": "Gebeurtenis",
            "filter.Custom Metric Type": "Aangepast metrisch type",
            "filter.Custom Metric Key": "Aangepaste metrische sleutel",
            "filter.Country": "Land",
            "filter.Region": "Regio",
            "filter.City": "Stad",
            "filter.Language": "Taal",
            "filter.OS": "OS",
            "filter.Browser": "Browser",
            "filter.Platform": "Platform",
            "filter.Screen Class": "Schermklasse",
            "filter.Tag": "Tag"
        },
        "it": {
            "is_not": "non è",
            "contains": "include",
            "not_contains": "esclude",
            "is": "è",
            "remove": "Rimuovi filtro",
            "filter.Path": "Pagina",
            "filter.Entry Page": "Pagina di ingresso",
            "filter.Exit Page": "Pagina di uscita",
            "filter.Pattern": "Modello",
            "filter.Referrer": "Referente",
            "filter.UTM Source": "Fonte UTM",
            "filter.UTM Medium": "Mezzo UTM",
            "filter.UTM Campaign": "Campagna UTM",
            "filter.UTM Content": "Contenuto UTM",
            "filter.UTM Term": "Termine UTM",
            "filter.Event": "Evento",
            "filter.Custom Metric Type": "Tipo di metrica personalizzata",
            "filter.Custom Metric Key": "Chiave di metrica personalizzata",
            "filter.Country": "Paese",
            "filter.Region": "Regione",
            "filter.City": "Città",
            "filter.Language": "Lingua",
            "filter.OS": "OS",
            "filter.Browser": "Browser",
            "filter.Platform": "Piattaforma",
            "filter.Screen Class": "Classe dello schermo",
            "filter.Tag": "Tag"
        },
        "pt": {
            "is_not": "não é",
            "contains": "inclui",
            "not_contains": "exclui",
            "is": "é",
            "remove": "Remover filtro",
            "filter.Path": "Página",
            "filter.Entry Page": "Página de entrada",
            "filter.Exit Page": "Página de saída",
            "filter.Pattern": "Padrão",
            "filter.Referrer": "Referenciador",
            "filter.UTM Source": "Fonte UTM",
            "filter.UTM Medium": "Meio UTM",
            "filter.UTM Campaign": "Campanha UTM",
            "filter.UTM Content": "Conteúdo UTM",
            "filter.UTM Term": "Termo UTM",
            "filter.Event": "Evento",
            "filter.Custom Metric Type": "Tipo de métrica personalizada",
            "filter.Custom Metric Key": "Chave de métrica personalizada",
            "filter.Country": "País",
            "filter.Region": "Região",
            "filter.City": "Cidade",
            "filter.Language": "Idioma",
            "filter.OS": "SO",
            "filter.Browser": "Navegador",
            "filter.Platform": "Plataforma",
            "filter.Screen Class": "Classe de tela",
            "filter.Tag": "Tag"
        },
        "ja": {
            "is_not": "ではない",
            "contains": "含む",
            "not_contains": "除外する",
            "is": "は",
            "remove": "フィルターを削除",
            "filter.Path": "ページ",
            "filter.Entry Page": "エントリページ",
            "filter.Exit Page": "エグジットページ",
            "filter.Pattern": "パターン",
            "filter.Referrer": "リファラー",
            "filter.UTM Source": "UTMソース",
            "filter.UTM Medium": "UTMメディア",
            "filter.UTM Campaign": "UTMキャンペーン",
            "filter.UTM Content": "UTMコンテンツ",
            "filter.UTM Term": "UTM用語",
            "filter.Event": "イベント",
            "filter.Custom Metric Type": "カスタムメトリックタイプ",
            "filter.Custom Metric Key": "カスタムメトリックキー",
            "filter.Country": "国",
            "filter.Region": "地域",
            "filter.City": "都市",
            "filter.Language": "言語",
            "filter.OS": "OS",
            "filter.Browser": "ブラウザ",
            "filter.Platform": "プラットフォーム",
            "filter.Screen Class": "スクリーンクラス",
            "filter.Tag": "タグ"
        }
    }
</i18n>
